import { FormControlLabel, Grid, MenuItem, TextField, Checkbox, Button, InputAdornment, Tooltip, Modal } from "@material-ui/core";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Edit2, Eye, Plus, RefreshCw, Save, Trash, X } from "react-feather";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { RootState } from "../../../app/store";
import ModalTemplate from "../../../shared/ModalTemplate";
import FormHOA from "./FormHOA";
import { useSelector, useDispatch } from "react-redux";
import {
  handleEditPoleInfo,
  handleModalAnchor,
  handleModalHOA,
  handleModalRiserVgr,
  handleModalTransformer,
  setListPoleFieldingRequest,
  deleteHoaData,
  deleteTransformersData,
  handleModalSpanAndDirection,
  setTransformersData,
  setHoaData,
  handleSpanMapLines,
  handleAnchorCanvasDotes,
  handleAnchorCanvasStreets,
  handleAnchorCanvasFences,
  handleRiserCanvasDotes,
  handleRiserCanvasFences,
  handleModalHistory,
  setListPoleLoadingRequest,
  setListPolePplx,
  setListPoleInventory
} from "../poleInformation.reducer";
import FormTransformer from "./FormTransformer";
import useForm from "../useForm/useForm";
import { handleRightButton } from "../../dashboard/dashboard.reducer";
import { editPoleFieldingRequest, getPoleDetails, getPoleFieldingRequest, deletePoleFieldingRequest, getPolePplx, getPoleInventory } from "../poleInformation.api";
import AnchorCanvasSmall from "./canvas/AnchorCanvasSmall";
import SpanMapSmall from "./spanMap/SpanMapSmall";
import RiserCanvasSmall from "./canvas/RiserCanvasSmall";
import FormPoleImage from "./FormPoleImage";
import FormPoleAttachment from "./FormPoleAttachment";
import { setActiveMultiplePole } from "../../dashboard/dashboard.api";
import { getJobNumberDetails, removePolesToLoadingRequest } from "../../sideTab/sideTab.api";
import { getFieldingRequestDetail } from "../../assignFielding/assignFielding.api";

function FormPole(props: any) {
  const { errors } = useForm();
  const dispatch = useDispatch<any>();
  const { modalFormHOA, modalFormTransformer, listPoleFieldingRequest, poleDetails, isEditPoleInfo, hoaData, transformersData, spanMapLines, anchorCanvasDotes, anchorCanvasStreets, anchorCanvasFences, riserCanvasDotes, riserCanvasFences, poleEnums, poleType, poleOcalcId, listPoleLoadingRequest } = useSelector((state: RootState) => state.poleInformation);
  const { projectIdActive, statusJobNumber, inventoryActive } = useSelector((state: RootState) => state.sideTab);
  const { rightButton } = useSelector((state: RootState) => state.dashboard);
  const variantInput = isEditPoleInfo ? "outlined" : "standard";

  const [color, setColor] = useState(0);
  const [type, setType] = useState(0);
  const [street, setStreet] = useState("#");
  const [isRadioAntenna, setIsRadioAntenna] = useState<any>(undefined);
  const [poleStamp, setPoleStamp] = useState<any>(undefined);
  const [poleClass, setPoleClass] = useState(0);
  const [poleHeight, setPoleHeight] = useState(0);
  const [poleSpecies, setPoleSpecies] = useState(0);
  const [notes, setNotes] = useState("");
  const [poleSequence, setPoleSequence] = useState("");
  const [poleNumber, setPoleNumber] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [fapTerminal, setFapTerminal] = useState("");
  const [osmose, setOsmose] = useState("");
  const [otherNumber, setOtherNumber] = useState("");
  const [circumference, setCircumference] = useState("");
  const [year, setYear] = useState("");
  const [poleCondition, setPoleCondition] = useState(0);

  const [images, setImages] = useState<any>([]);

  const [files, setFiles] = useState<any>([]);

  const [fieldingType, setFieldingType] = useState(2);

  const [isPoleNumberUnknown, setIsPoleNumberUnknown] = useState(false);
  const [isPoleLengthUnknown, setIsPoleLengthUnknown] = useState(false);
  const [isPoleLengthEstimated, setIsPoleLengthEstimated] = useState(false);
  const [isPoleClassUnknown, setIsPoleClassUnknown] = useState(false);
  const [isPoleClassEstimated, setIsPoleClassEstimated] = useState(false);
  const [isGroundLineUnknown, setIsGroundLineUnknown] = useState(false);
  const [isGroundLineEstimated, setIsGroundLineEstimated] = useState(false);
  const [isYearUnknown, setIsYearUnknown] = useState(false);
  const [isYearEstimated, setIsYearEstimated] = useState(false);
  const [isSpeciesUnknown, setIsSpeciesUnknown] = useState(false);
  const [isSpeciesEstimated, setIsSpeciesEstimated] = useState(false);

  const [poleOwner, setPoleOwner] = useState("");
  const [tnTDate, setTnTDate] = useState("");

  const [statusExtraDetails, setStatusExtraDetails] = useState(false)

  const [poleSetSurfaces, setPoleSetSurfaces] = useState(0)
  const [cTruss, setCTruss] = useState<any>(undefined)
  const [treeTrim, setTreeTrim] = useState<any>(undefined)
  const [pL_VIS_Strips, setPL_VIS_Strips] = useState<any>(undefined)
  const [pL_VGR, setPL_VGR] = useState<any>(undefined)
  const [inS_DG, setInS_DG] = useState<any>(undefined)
  const [rmV_ABN_DWS, setRmV_ABN_DWS] = useState<any>(undefined)
  const [relocDWS, setRelocDWS] = useState<any>(undefined)
  const [pL_GG_OnDG, setPL_GG_OnDG] = useState<any>(undefined)
  const [mreRequirements, setMreRequirements] = useState<any>(0)

  const handleEditPole = (street: string = "") => {
    let newSpanMapLines: any = []
    spanMapLines.map((spanMapLine: any) => {
      let newSpanMapLine = JSON.parse(JSON.stringify(spanMapLine))
      newSpanMapLine.lineData = JSON.stringify(spanMapLine.lineData)
      newSpanMapLines.push(newSpanMapLine)
    })
    let newAnchorCanvasStreets: any = []
    anchorCanvasStreets.map((value: any) => {
      let newValue = JSON.parse(JSON.stringify(value))
      newValue.points = JSON.stringify(newValue.points)
      newAnchorCanvasStreets.push(newValue)
    })
    let newAnchorCanvasFences: any = []
    anchorCanvasFences.map((value: any) => {
      let newValue = JSON.parse(JSON.stringify(value))
      newValue.points = JSON.stringify(newValue.points)
      newAnchorCanvasFences.push(newValue)
    })
    let newRiserCanvasFences: any = []
    riserCanvasFences.map((value: any) => {
      let newValue = JSON.parse(JSON.stringify(value))
      newValue.points = JSON.stringify(newValue.points)
      newRiserCanvasFences.push(newValue)
    })
    const data = {
      id: poleDetails?.id,
      poleNumber: poleNumber,
      poleYear: year,
      poleCondition: poleCondition,
      poleHeight: poleHeight,
      poleClass: poleClass,
      poleSpecies: poleSpecies,
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      clearance: "",
      groundCircumference: circumference,
      treeHeight: "",
      osmose: osmose,
      street: street,
      vapTerminal: fapTerminal,
      otherNumber: otherNumber,
      poleStamp: poleStamp,
      notes: notes,
      isRadioAntenna: isRadioAntenna,
      poleSequence: poleSequence,
      color: color,
      type: type,
      hoaList: hoaData,
      transformerList: transformersData,
      spanDirectionList: newSpanMapLines,
      anchorList: anchorCanvasDotes,
      riserAndVGRList: riserCanvasDotes,
      anchorFenceList: newAnchorCanvasFences,
      anchorStreetList: newAnchorCanvasStreets,
      riserFenceList: newRiserCanvasFences,
      fieldingType: fieldingType,
      isPoleLengthUnknown: isPoleLengthUnknown,
      isPoleLengthEstimated: isPoleLengthEstimated,
      isPoleClassUnknown: isPoleClassUnknown,
      isPoleClassEstimated: isPoleClassEstimated,
      isGroundLineUnknown: isGroundLineUnknown,
      isGroundLineEstimated: isGroundLineEstimated,
      isYearUnknown: isYearUnknown,
      isYearEstimated: isYearEstimated,
      isSpeciesUnknown: isSpeciesUnknown,
      isSpeciesEstimated: isSpeciesEstimated,
      isPoleNumberUnknown: isPoleNumberUnknown,
      poleOwner: poleOwner,
      tnTDate: tnTDate,
      poleSetSurfaces: poleSetSurfaces,
      cTruss: cTruss,
      treeTrim: treeTrim,
      pL_VIS_Strips: pL_VIS_Strips,
      pL_VGR: pL_VGR,
      inS_DG: inS_DG,
      rmV_ABN_DWS: rmV_ABN_DWS,
      relocDWS: relocDWS,
      pL_GG_OnDG: pL_GG_OnDG,
      mreRequirements: mreRequirements,
      userId: localStorage.getItem("id")
    };
    dispatch(editPoleFieldingRequest(data))
      .unwrap()
      .then((res: any) => {
        if (res.status === true) {
          dispatch(handleEditPoleInfo())
          Swal.fire({
            icon: "success",
            title: "Pole successfully edited",
            showConfirmButton: false,
            timer: 1500,
          });
          dispatch(getPoleDetails(poleDetails?.id))
          dispatch(getPoleFieldingRequest(poleDetails?.fieldingRequestId))
            .unwrap()
            .then((res: any) => {
              let listPoleFieldingRequestNew: any = listPoleFieldingRequest?.filter((item: any) => item?.fieldingRequestId != poleDetails?.fieldingRequestId)
              dispatch(setListPoleFieldingRequest(listPoleFieldingRequestNew.concat(res.data)));
            })
        }
        else {
          Swal.fire({
            icon: "error",
            title: "error edit pole",
            text: res.statusText,
            showConfirmButton: true,
          });
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          title: "error edit pole",
          text: err,
          showConfirmButton: true,
        });
        console.log("error in request", err);
      });
  };

  const fillStreet = async () => {
    const geocoder = new google.maps.Geocoder()
    let response = await geocoder.geocode({ location: { lat: parseFloat(poleDetails.latitude), lng: parseFloat(poleDetails.longitude) } })
    let streetName = response?.results?.length > 0 ? response?.results[0]?.formatted_address : "Not Found"
    setStreet(streetName)
  }

  useEffect(() => {
    setInit2()
  }, [poleDetails]);

  useEffect(() => {
    if (isEditPoleInfo) {
      if (!rightButton) {
        Swal.fire({
          text: 'Would you like to save changes made?',
          icon: 'warning',
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            handleEditPole()
          }
          else {
            handleCancel()
          }
        })
      }
    }
  }, [rightButton])

  const setInit2 = () => {
    if (!isEditPoleInfo && poleDetails.id) {
      fillStreet()
      setPoleSequence(poleDetails?.poleSequence);
      setPoleClass(poleDetails?.poleClass);
      setPoleCondition(poleDetails?.poleCondition);
      setPoleHeight(poleDetails?.poleHeight);
      setColor(poleDetails?.color);
      setType(poleDetails?.type);
      setPoleSpecies(poleDetails?.poleSpecies);
      setPoleNumber(poleDetails?.poleNumber);
      setIsRadioAntenna(poleDetails?.isRadioAntenna);
      setPoleStamp(poleDetails?.poleStamp);
      setNotes(poleDetails?.notes);
      setLatitude(parseFloat(poleDetails?.latitude));
      setLongitude(parseFloat(poleDetails?.longitude));
      setFapTerminal(poleDetails?.vapTerminal);
      setOsmose(poleDetails?.osmose);
      setOtherNumber(poleDetails?.otherNumber);
      setCircumference(poleDetails?.groundCircumference);
      setYear(poleDetails?.poleYear);
      setPoleNumber(poleDetails?.poleNumber);
      setImages(poleDetails?.imageList)
      setFiles(poleDetails?.documentList)
      setIsPoleNumberUnknown(poleDetails?.isPoleNumberUnknown)
      setIsPoleLengthUnknown(poleDetails?.isPoleLengthUnknown)
      setIsPoleLengthEstimated(poleDetails?.isPoleLengthEstimated)
      setIsPoleClassUnknown(poleDetails?.isPoleClassUnknown)
      setIsPoleClassEstimated(poleDetails?.isPoleClassEstimated)
      setIsGroundLineUnknown(poleDetails?.isGroundLineUnknown)
      setIsGroundLineEstimated(poleDetails?.isGroundLineEstimated)
      setIsYearUnknown(poleDetails?.isYearUnknown)
      setIsYearEstimated(poleDetails?.isYearEstimated)
      setIsSpeciesUnknown(poleDetails?.isSpeciesUnknown)
      setIsSpeciesEstimated(poleDetails?.isSpeciesEstimated)
      setFieldingType(poleDetails?.fieldingType)
      setPoleOwner(poleDetails?.poleOwner);
      setTnTDate(poleDetails?.tnTDate ? poleDetails?.tnTDate : "");
      setPoleSetSurfaces(poleDetails?.poleSetSurfaces)
      setCTruss(poleDetails?.cTruss)
      setTreeTrim(poleDetails?.treeTrim)
      setPL_VIS_Strips(poleDetails?.pL_VIS_Strips)
      setPL_VGR(poleDetails?.pL_VGR)
      setInS_DG(poleDetails?.inS_DG)
      setRmV_ABN_DWS(poleDetails?.rmV_ABN_DWS)
      setRelocDWS(poleDetails?.relocDWS)
      setPL_GG_OnDG(poleDetails?.pL_GG_OnDG)
      setMreRequirements(poleDetails?.mreRequirements)
      dispatch(setTransformersData(poleDetails?.transformerList))
      dispatch(setHoaData(poleDetails?.hoaList))
      let newSpanMapLines: any = []
      poleDetails?.spanDirectionList?.map((spanDirection: any) => {
        let newSpanDirection = JSON.parse(JSON.stringify(spanDirection))
        try {
          newSpanDirection.lineData = JSON.parse(spanDirection.lineData)
          newSpanMapLines.push(newSpanDirection)
        } catch (error) { }
      })
      dispatch(handleSpanMapLines(newSpanMapLines))
      let newAnchorList: any = []
      poleDetails?.anchorList?.map((anchor: any) => {
        if (anchor?.circleX && anchor?.circleY) {
          newAnchorList.push(anchor)
        }
      })
      dispatch(handleAnchorCanvasDotes(newAnchorList))
      let newAnchorStreetList: any = []
      poleDetails?.anchorStreetList?.map((value: any) => {
        let newValue: any = JSON.parse(JSON.stringify(value))
        newValue.points = value.points.includes(',') ? JSON.parse(value.points) : []
        newAnchorStreetList.push(newValue)
      })
      dispatch(handleAnchorCanvasStreets(newAnchorStreetList))
      let newAnchorFenceList: any = []
      poleDetails?.anchorFenceList?.map((value: any) => {
        let newValue: any = JSON.parse(JSON.stringify(value))
        newValue.points = value.points.includes(',') ? JSON.parse(value.points) : []
        newAnchorFenceList.push(newValue)
      })
      dispatch(handleAnchorCanvasFences(newAnchorFenceList))
      let newRiserAndVGRList: any = []
      poleDetails?.riserAndVGRList?.map((riser: any) => {
        newRiserAndVGRList.push(riser)
      })
      dispatch(handleRiserCanvasDotes(newRiserAndVGRList))
      let newRiserFenceList: any = []
      poleDetails?.riserFenceList?.map((value: any) => {
        let newValue: any = JSON.parse(JSON.stringify(value))
        newValue.points = value.points.includes(',') ? JSON.parse(value.points) : []
        newRiserFenceList.push(newValue)
      })
      dispatch(handleRiserCanvasFences(newRiserFenceList))
    }
    setImages(poleDetails?.imageList)
    setFiles(poleDetails?.documentList)
  }

  const handleReload = () => {
    dispatch(getPoleDetails(poleDetails?.id))
  };

  const handleDeletePole = (id: any) => {
    if (poleType === "POLE") {
      Swal.fire({
        title: 'Are you sure you want to delete this pole?',
        text: 'Deleted pole can be retrieved from Deleted Items.',
        icon: 'warning',
        showCancelButton: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(setActiveMultiplePole({
            poleIds: [id],
            isActive: false,
            userId: localStorage.getItem('id')
          }))
            .unwrap()
            .then((res: any) => {
              console.log(res);
              dispatch(handleRightButton());
              dispatch(setListPoleFieldingRequest(listPoleFieldingRequest?.filter((item: any) => item?.id != id)));
              dispatch(getFieldingRequestDetail(poleDetails.fieldingRequestId))
              dispatch(getJobNumberDetails(poleDetails.jobNumberId))
              Swal.fire({
                icon: "success",
                title: "Pole successfully deleted",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err: any) => {
              Swal.fire({
                icon: "error",
                title: "Failed delete pole",
                showConfirmButton: false,
                timer: 1500,
              });
              console.log("error in request", err);
            });
        }
      })
    }
    else if (poleType === "LOADING") {
      Swal.fire({
        title: 'Are you sure you want to delete this pole?',
        text: 'this action cannot be undone',
        icon: 'warning',
        showCancelButton: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(removePolesToLoadingRequest({
            "ocalcId": poleOcalcId,
            "poleIds": [id]
          }))
            .unwrap()
            .then((res: any) => {
              dispatch(handleRightButton());
              let poleLoadingRequest: any = listPoleLoadingRequest?.filter((item: any) => item?.id !== id);
              dispatch(setListPoleLoadingRequest(poleLoadingRequest));
              Swal.fire({
                icon: "success",
                title: "Pole successfully deleted",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err: any) => {
              Swal.fire({
                icon: "error",
                title: "Failed delete pole",
                showConfirmButton: false,
                timer: 1500,
              });
              console.log("error in request", err);
            });
        }
      })
    }
    else if (poleType === "PPLX" || poleType === "INVENTORY") {
      Swal.fire({
        title: 'Are you sure you want to delete this pole?',
        text: 'this action cannot be undone',
        icon: 'warning',
        showCancelButton: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          dispatch(deletePoleFieldingRequest(id))
            .unwrap()
            .then((res: any) => {
              if (poleType === "PPLX") {
                dispatch(getPolePplx())
                  .unwrap()
                  .then((response: any) => {
                    dispatch(setListPolePplx(response.data))
                  })
              }
              else if (poleType === "INVENTORY") {
                dispatch(getPoleInventory(inventoryActive.id))
                  .unwrap()
                  .then((response: any) => {
                    dispatch(setListPoleInventory(response.data))
                  })
              }
              dispatch(handleRightButton());
              Swal.fire({
                icon: "success",
                title: "Pole successfully deleted",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err: any) => {
              Swal.fire({
                icon: "error",
                title: "Failed delete pole",
                showConfirmButton: false,
                timer: 1500,
              });
              console.log("error in request", err);
            });
        }
      })
    }
  };

  const handleEditSwitch = () => {
    if (statusJobNumber > 0) {
      Swal.fire({
        title: 'This fielding is completed.',
        text: 'Do you wish to continue edit?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'are you sure you want to change the data?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(handleEditPoleInfo())
            }
          })
        }
      })
    }
    else {
      dispatch(handleEditPoleInfo())
    }
  }

  const handleCancel = () => {
    setInit2()
    dispatch(handleEditPoleInfo())
  }

  const booleanOption = [
    { value: null, label: "-" },
    { value: false, label: "No" },
    { value: true, label: "Yes" }
  ]

  if (poleDetails.fieldingRequestType === 2) {
    return (
      <div className="boxform">
        <div className="header">
          <div className="title">
            <h3>Information</h3>
            <TextField id="select" className="headinp" variant={variantInput} disabled={!isEditPoleInfo} size="small" select value={type} onChange={(e) => setType(parseInt(e.target.value))}>
              {poleEnums?.poleTypes?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
              ))}
            </TextField>
          </div>
          {!isEditPoleInfo ? (
            <div className="wrapbutton">
              {poleType === "PPLX"
                ? (
                  <div>
                    <span style={{ fontSize: "small", cursor: "pointer" }}>Last updated file:</span> <br />
                    <span style={{ fontSize: "small", cursor: "pointer" }}>{poleDetails.createdDate}</span>
                  </div>
                )
                : (
                  <div>
                    <span style={{ fontSize: "small", cursor: "pointer" }} onClick={() => dispatch(handleModalHistory())}>Last updated by:</span> <br />
                    <span style={{ fontSize: "small", cursor: "pointer" }} onClick={() => dispatch(handleModalHistory())}>{poleDetails.lastModifiedUsername} {poleDetails.lastModifiedDate}</span>
                  </div>
                )
              }
              <Tooltip title="Reload pole info">
                <Button className="reload" variant="outlined" onClick={handleReload}>
                  <RefreshCw /> Reload
                </Button>
              </Tooltip>
              {" "}
              <Button className="delete" variant="outlined" onClick={() => handleDeletePole(poleDetails?.id)}>
                <Trash /> Delete
              </Button>
              {" "}
              <Button className="edit" variant="outlined" onClick={handleEditSwitch}>
                <Edit2 /> Edit
              </Button>
            </div>
          ) : <div className="wrapbutton">
            <Button type="button" className="cancel" variant="outlined" onClick={handleCancel}>
              <X /> Cancel
            </Button>
            <Button type="submit" className="save" style={{ marginLeft: 10 }} variant="outlined" onClick={() => handleEditPole()}>
              <Save /> Save
            </Button>
          </div>}
        </div>
        <form>
          <br />
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField className="inp" variant={variantInput} onChange={(e) => setLatitude(parseFloat(e.target.value))} value={latitude.toFixed(6)} disabled={!isEditPoleInfo} label="Latitude" size="small" />
            </Grid>
            <Grid item sm={6}>
              <TextField className="inp" variant={variantInput} onChange={(e) => setLongitude(parseFloat(e.target.value))} value={longitude.toFixed(6)} disabled={!isEditPoleInfo} label="Longitude" size="small" />
            </Grid>
            <Grid item sm={12}>
              <TextField className="inp" variant={variantInput} value={street} disabled={true} label="Street Name" size="small" />
            </Grid>
            <Grid item sm={12}>
              <TextField
                className="inp"
                name=""
                label="Pole Sequence"
                size="small"
                onChange={(e) => setPoleSequence(e.target.value)}
                value={poleSequence}
                disabled={!isEditPoleInfo}
              />
            </Grid>
            <Grid item sm={12}>
              <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="MRE Estimation" variant='outlined' size='small' select value={mreRequirements === null ? -1 : mreRequirements} onChange={(e) => setMreRequirements(parseInt(e.target.value) === -1 ? null : parseInt(e.target.value))}>
                <MenuItem value={-1}>Select</MenuItem>
                {["None", "Minor", "Major"].map((val, index) => {
                  return (
                    <MenuItem key={index} value={index}>{val}</MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item sm={12}>
              <TextField className="inp" name="note" variant={variantInput} onChange={(e) => setPoleNumber(e.target.value)} value={poleNumber} disabled={!isEditPoleInfo || isPoleNumberUnknown} label="Pole Tag / Number" size="small" />
              <div className={"litle-checkbox"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isPoleNumberUnknown}
                      onChange={(e) => setIsPoleNumberUnknown(e.target.checked)}
                      name="antoine"
                      color="primary"
                      disabled={!isEditPoleInfo}
                    />
                  }
                  label="Unknown"
                />
              </div>
            </Grid>
            <Grid item sm={12}>
              <TextField multiline rows={2} className="inp" name="year" variant={variantInput} onChange={(e) => setNotes(e.target.value)} value={notes} disabled={!isEditPoleInfo} label="Note" size="small" />
            </Grid>
            <Grid item sm={12}>
              <FormPoleImage images={images} setImages={setImages} />
            </Grid>

            <Grid item sm={12}>
              <FormPoleAttachment files={files} setFiles={setFiles} />
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
  return (
    <div className="boxform">
      <div className="header">
        <div className="title">
          <h3>Information</h3>
          <TextField id="select" className="headinp" variant={variantInput} disabled={!isEditPoleInfo} size="small" select value={type} onChange={(e) => setType(parseInt(e.target.value))}>
            {poleEnums?.poleTypes?.map((item: any) => (
              <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
            ))}
          </TextField>
        </div>
        {!isEditPoleInfo ? (
          <div className="wrapbutton">
            {poleType === "PPLX"
              ? (
                <div>
                  <span style={{ fontSize: "small", cursor: "pointer" }}>Last updated file:</span> <br />
                  <span style={{ fontSize: "small", cursor: "pointer" }}>{poleDetails.createdDate}</span>
                </div>
              )
              : (
                <div>
                  <span style={{ fontSize: "small", cursor: "pointer" }} onClick={() => dispatch(handleModalHistory())}>Last updated by:</span> <br />
                  <span style={{ fontSize: "small", cursor: "pointer" }} onClick={() => dispatch(handleModalHistory())}>{poleDetails.lastModifiedUsername} {poleDetails.lastModifiedDate}</span>
                </div>
              )
            }
            <Tooltip title="Reload pole info">
              <Button className="reload" variant="outlined" onClick={handleReload}>
                <RefreshCw /> Reload
              </Button>
            </Tooltip>
            {" "}
            <Button className="delete" variant="outlined" onClick={() => handleDeletePole(poleDetails?.id)}>
              <Trash /> Delete
            </Button>
            {" "}
            <Button className="edit" variant="outlined" onClick={handleEditSwitch}>
              <Edit2 /> Edit
            </Button>
          </div>
        ) : <div className="wrapbutton">
          <Button type="button" className="cancel" variant="outlined" onClick={handleCancel}>
            <X /> Cancel
          </Button>
          <Button type="submit" className="save" style={{ marginLeft: 10 }} variant="outlined" onClick={() => handleEditPole()}>
            <Save /> Save
          </Button>
        </div>}
      </div>
      <form>
        <br />
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <TextField className="inp" variant={variantInput} disabled value={poleDetails?.fieldingCompletedDateString} label="Fielding Date" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item sm={4}>
            <TextField className="inp" variant={variantInput} disabled value={projectIdActive?.projectNumber} label="Project" size="small" />
          </Grid>
          <Grid item sm={4}>
            <TextField className="inp" variant={variantInput} disabled value={(poleDetails?.fieldingRequest || "")?.replace("Fielding Request ", "")} label="Fielding Request" size="small" />
          </Grid>

          <Grid item sm={6}>
            <TextField
              className="inp"
              name=""
              label="Pole Sequence"
              size="small"
              onChange={(e) => setPoleSequence(e.target.value)}
              value={poleSequence}
              disabled={!isEditPoleInfo}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField id="select" label="Pole Color" className="inp" disabled={!isEditPoleInfo} size="small" select value={color} onChange={(e) => setColor(parseInt(e.target.value))}>
              {poleEnums?.poleColors?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={6}>
            <TextField className="inp" variant={variantInput} onChange={(e) => setLatitude(parseFloat(e.target.value))} value={latitude.toFixed(6)} disabled={!isEditPoleInfo} label="Latitude" size="small" />
          </Grid>
          <Grid item sm={6}>
            <TextField className="inp" variant={variantInput} onChange={(e) => setLongitude(parseFloat(e.target.value))} value={longitude.toFixed(6)} disabled={!isEditPoleInfo} label="Longitude" size="small" />
          </Grid>
          <Grid item sm={12}>
            <TextField className="inp" variant={variantInput} value={street} disabled={false} label="Street Name" size="small" />
          </Grid>
          <Grid item sm={12}>
            <TextField id="select" className="inp" label="Fielding Request Type" variant={variantInput} disabled={!isEditPoleInfo} size="small" select value={fieldingType} onChange={e => setFieldingType(parseInt(e.target.value))}>
              {poleEnums?.fieldingTypes?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={12}>
            <TextField className="inp" name="note" variant={variantInput} onChange={(e) => setPoleNumber(e.target.value)} value={poleNumber} disabled={!isEditPoleInfo || isPoleNumberUnknown} label="Pole Tag / Number" size="small" />
            <div className={"litle-checkbox"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isPoleNumberUnknown}
                    onChange={(e) => setIsPoleNumberUnknown(e.target.checked)}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Unknown"
              />
            </div>
          </Grid>


          <Grid item sm={12}>
            <TextField className="inp" variant={variantInput} onChange={(e) => setFapTerminal(e.target.value)} value={fapTerminal} disabled={!isEditPoleInfo} label="FAP Terminal" size="small" />
          </Grid>
          <Grid item sm={12}>
            <TextField className="inp" variant={variantInput} onChange={(e) => setOsmose(e.target.value)} value={osmose} disabled={!isEditPoleInfo} label="Osmose Number" size="small" />
          </Grid>
          <Grid item sm={12}>
            <TextField className="inp" variant={variantInput} onChange={(e) => setOtherNumber(e.target.value)} value={otherNumber} disabled={!isEditPoleInfo} label="Other Number" size="small" />
          </Grid>

          <Grid item sm={6}>
            <TextField
              error={(isEditPoleInfo && !isGroundLineUnknown) ? errors.groundLine : false}
              className="inp"
              variant={variantInput}
              disabled={!isEditPoleInfo || isGroundLineUnknown}
              label="Ground Line Circumference"
              size="small"
              name="groundLine"
              value={circumference}
              onChange={(e) => setCircumference(e.target.value)}
              required={isEditPoleInfo && !isGroundLineUnknown}
              helperText={(isEditPoleInfo && !isGroundLineUnknown) ? errors.groundLine : ""}
              InputProps={{
                endAdornment: <InputAdornment position="end">inches</InputAdornment>
              }}
            />
            <div className={"litle-checkbox"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isGroundLineUnknown}
                    onChange={(e) => { setIsGroundLineUnknown(e.target.checked); e.target.checked && setIsGroundLineEstimated(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Unknown"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isGroundLineEstimated}
                    onChange={(e) => { setIsGroundLineEstimated(e.target.checked); e.target.checked && setIsGroundLineUnknown(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Estimate"
              />
            </div>
          </Grid>
          <Grid item sm={6}>
            <TextField className="inp" variant={variantInput} onChange={(e) => setPoleOwner(e.target.value)} value={poleOwner} disabled={!isEditPoleInfo} label="Pole Owner" size="small" />
          </Grid>

          <Grid item sm={6}>
            <TextField
              className="inp"
              variant={variantInput}
              disabled={!isEditPoleInfo || isYearUnknown}
              label="Year Pole Set"
              size="small"
              error={(isEditPoleInfo && !isYearUnknown) ? errors.year : false}
              name="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              required={isEditPoleInfo && !isYearUnknown}
              helperText={(isEditPoleInfo && !isYearUnknown) ? errors.year : ""}
            />
            <div className={"litle-checkbox"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isYearUnknown}
                    onChange={(e) => { setIsYearUnknown(e.target.checked); e.target.checked && setIsYearEstimated(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Unknown"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isYearEstimated}
                    onChange={(e) => { setIsYearEstimated(e.target.checked); e.target.checked && setIsYearUnknown(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Estimate"
              />
            </div>
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="select"
              className="inp"
              required={isEditPoleInfo}
              helperText={isEditPoleInfo ? errors.poleCondition : ""}
              label="Pole Condition"
              error={isEditPoleInfo ? errors.poleCondition : false}
              name="poleCondition"
              onChange={(e) => setPoleCondition(parseInt(e.target.value))}
              value={poleCondition}
              variant={variantInput}
              disabled={!isEditPoleInfo}
              size="small"
              select
            >
              {poleEnums?.poleConditions?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={12}>
            <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="MRE Estimation" variant='outlined' size='small' select value={mreRequirements === null ? -1 : mreRequirements} onChange={(e) => setMreRequirements(parseInt(e.target.value) === -1 ? null : parseInt(e.target.value))}>
              <MenuItem value={-1}>Select</MenuItem>
              {["None", "Minor", "Major"].map((val, index) => {
                return (
                  <MenuItem key={index} value={index}>{val}</MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item sm={12}>
            <TextField
              className="inp"
              variant={variantInput}
              value={tnTDate}
              disabled={!isEditPoleInfo}
              onChange={e => setTnTDate(e.target.value)}
              label="T&T Date"
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item sm={6}>
            <TextField
              className='inp'
              label="Radio Antenna"
              variant='outlined'
              size='small'
              select
              name='eyesPict'
              value={booleanOption.findIndex(item => item.value === isRadioAntenna)}
              onChange={e => setIsRadioAntenna(booleanOption[parseInt(e.target.value)].value)}
              disabled={!isEditPoleInfo}
            >
              {booleanOption.map((item, index) => {
                return (
                  <MenuItem key={index} value={index}>{item.label}</MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item sm={6}>
            <TextField
              className='inp'
              label="Pole Stamp Picture"
              variant='outlined'
              size='small'
              select
              name='eyesPict'
              value={booleanOption.findIndex(item => item.value === poleStamp)}
              onChange={e => setPoleStamp(booleanOption[parseInt(e.target.value)].value)}
              disabled={!isEditPoleInfo}
            >
              {booleanOption.map((item, index) => {
                return (
                  <MenuItem key={index} value={index}>{item.label}</MenuItem>
                )
              })}
            </TextField>
          </Grid>

          <Grid item sm={6}>
            <TextField
              id="select"
              className="inp"
              required={isEditPoleInfo && !isPoleLengthUnknown}
              helperText={(isEditPoleInfo && !isPoleLengthUnknown) ? errors.poleLength : ""}
              label="Pole Length"
              error={(isEditPoleInfo && !isPoleLengthUnknown) ? errors.poleLength : false}
              name="poleLength"
              onChange={(e) => setPoleHeight(parseInt(e.target.value))}
              value={poleHeight}
              variant={variantInput}
              disabled={!isEditPoleInfo || isPoleLengthUnknown}
              size="small"
              select
            >
              {poleEnums?.poleHeights?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>{item.display} ft</MenuItem>
              ))}
            </TextField>
            <div className={"litle-checkbox"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isPoleLengthUnknown}
                    onChange={(e) => { setIsPoleLengthUnknown(e.target.checked); e.target.checked && setIsPoleLengthEstimated(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Unknown"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isPoleLengthEstimated}
                    onChange={(e) => { setIsPoleLengthEstimated(e.target.checked); e.target.checked && setIsPoleLengthUnknown(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Estimate"
              />
            </div>
          </Grid>

          <Grid item sm={6}>
            <TextField
              id="select"
              className="inp"
              error={(isEditPoleInfo && !isPoleClassUnknown) ? errors.poleClass : false}
              helperText={(isEditPoleInfo && !isPoleClassUnknown) ? errors.poleClass : false}
              required={isEditPoleInfo && !isPoleClassUnknown}
              label="Pole Class"
              name="poleClass"
              onChange={(e) => setPoleClass(parseInt(e.target.value))}
              value={poleClass}
              variant={variantInput}
              disabled={!isEditPoleInfo || isPoleClassUnknown}
              size="small"
              select
            >
              {poleEnums?.poleClasses?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
              ))}
            </TextField>
            <div className={"litle-checkbox"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isPoleClassUnknown}
                    onChange={(e) => { setIsPoleClassUnknown(e.target.checked); e.target.checked && setIsPoleClassEstimated(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Unknown"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isPoleClassEstimated}
                    onChange={(e) => { setIsPoleClassEstimated(e.target.checked); e.target.checked && setIsPoleClassUnknown(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Estimate"
              />
            </div>
          </Grid>

          <Grid item sm={12}>
            <TextField
              id="select"
              className="inp"
              label="Species"
              required={isEditPoleInfo && !isSpeciesUnknown}
              helperText={(isEditPoleInfo && !isSpeciesUnknown) ? errors.species : ""}
              error={(isEditPoleInfo && !isSpeciesUnknown) ? errors.species : ""}
              name="species"
              onChange={(e) => setPoleSpecies(parseInt(e.target.value))}
              value={poleSpecies}
              variant={variantInput}
              disabled={!isEditPoleInfo || isSpeciesUnknown}
              size="small"
              select
            >
              {poleEnums?.poleSpecies?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>
              ))}
            </TextField>
            <div className={"litle-checkbox"}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isSpeciesUnknown}
                    onChange={(e) => { setIsSpeciesUnknown(e.target.checked); e.target.checked && setIsSpeciesEstimated(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Unknown"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isSpeciesEstimated}
                    onChange={(e) => { setIsSpeciesEstimated(e.target.checked); e.target.checked && setIsSpeciesUnknown(!e.target.checked) }}
                    name="antoine"
                    color="primary"
                    disabled={!isEditPoleInfo}
                  />
                }
                label="Estimate"
              />
            </div>
          </Grid>

          <Grid item sm={12}>
            <TextField multiline rows={2} className="inp" name="year" variant={variantInput} onChange={(e) => setNotes(e.target.value)} value={notes} disabled={!isEditPoleInfo} label="Note" size="small" />
          </Grid>

          <Grid item sm={12}>
            <div className="header-content">
              <h4>HOA</h4>
              {isEditPoleInfo ? (
                <Button className="add" variant="contained" size="small" onClick={() => dispatch(handleModalHOA())}>
                  {" "}
                  <Plus /> Add HOA
                </Button>
              ) : null}
            </div>
            <ModalTemplate onOpen={modalFormHOA} component={() => <FormHOA onClose={() => dispatch(handleModalHOA())} />} />
            <Table className="table-form">
              <Thead>
                <Tr>
                  <Th>Type</Th>
                  <Th>Height(Feet)</Th>
                  <Th>Height(Inch)</Th>

                  {isEditPoleInfo ? <Th>Action</Th> : null}
                </Tr>
              </Thead>
              <Tbody>
                {hoaData?.map((item: any, index: any) => (
                  <Tr key={index}>
                    <Td>{poleEnums.hoaTypes.filter((enumItem: any) => enumItem.value === item?.type)[0].display}</Td>
                    <Td>{item?.poleLengthInFeet}</Td>
                    <Td>{item?.poleLengthInInch}</Td>
                    {isEditPoleInfo ? (
                      <Td>
                        <div className="centered">
                          <Button variant="contained" onClick={() => dispatch(deleteHoaData(index))} size="small">
                            Delete
                          </Button>
                        </div>
                      </Td>
                    ) : null}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Grid>

          <Grid item sm={12}>
            <div className="header-content">
              <h4>Transformers</h4>
              {isEditPoleInfo ? (
                <Button className="add" variant="contained" size="small" onClick={() => dispatch(handleModalTransformer())}>
                  {" "}
                  <Plus /> Add Transformer
                </Button>
              ) : null}
            </div>
            <ModalTemplate onOpen={modalFormTransformer} component={() => <FormTransformer onClose={() => dispatch(handleModalTransformer())} />} />
            <Table className="table-form">
              <Thead>
                <Tr>
                  <Th>Value (KV)</Th>
                  {isEditPoleInfo ? <Th>Action</Th> : null}
                </Tr>
              </Thead>
              <Tbody>
                {transformersData?.map((item: any, index: any) => (
                  (item?.value)
                    ? (
                      <Tr key={index}>
                        <Td>{item?.value}</Td>
                        {isEditPoleInfo
                          ? (
                            <Td>
                              <div className="centered">
                                <Button variant="contained" size="small" onClick={() => dispatch(deleteTransformersData(index))}>
                                  Delete
                                </Button>
                              </div>
                            </Td>
                          )
                          : null
                        }
                      </Tr>
                    )
                    : null
                ))}
              </Tbody>
            </Table>
          </Grid>
          {isEditPoleInfo
            ? (
              <Grid item sm={6}>
                <Button className="view" variant="contained" size="small" onClick={() => dispatch(handleModalSpanAndDirection())}>
                  {" "}
                  <Eye /> View span and direction
                </Button>
                <Button className="view" variant="contained" size="small" onClick={() => dispatch(handleModalAnchor())}>
                  {" "}
                  <Eye /> View anchor
                </Button>
                <Button className="view" variant="contained" size="small" onClick={() => dispatch(handleModalRiserVgr())}>
                  {" "}
                  <Eye /> View riser and VGR
                </Button>
              </Grid>
            )
            : (
              <Grid item sm={12}>
                <div className="header-content">
                  <h4>Span and Direction</h4>
                </div>
                <div className="figsketch-map">
                  <SpanMapSmall />
                </div>
                <div className="header-content">
                  <h4>Anchor</h4>
                </div>
                <div className="figsketch-small">
                  <AnchorCanvasSmall />
                </div>
                <div className="header-content">
                  <h4>Riser and vgr</h4>
                </div>
                <div className="figsketch-small">
                  <RiserCanvasSmall />
                </div>
              </Grid>
            )
          }
          {poleDetails.fieldingRequestType !== 2 && (
            <Grid item sm={12}>
              <Button fullWidth color="primary" variant="contained" size="small" onClick={() => setStatusExtraDetails(true)}>
                Extra Details
              </Button>
            </Grid>
          )}
          <Modal className="image-editor-modal" open={statusExtraDetails}>
            <div className='modalsketchlib'>
              <h3>
                Extra Details
                <X onClick={() => setStatusExtraDetails(false)} />
              </h3>
              <div className="box">
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="Pole Set Surfaces" variant='outlined' size='small' select value={poleSetSurfaces} onChange={(e) => setPoleSetSurfaces(parseInt(e.target.value))}>
                    {["-", "Dirt", "Concrete", "Asphalt", "Other"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="C-Truss" variant='outlined' size='small' select value={(cTruss === true ? 2 : cTruss === false ? 1 : 0)} onChange={(e) => setCTruss(parseInt(e.target.value) === 2 ? true : parseInt(e.target.value) === 1 ? false : null)}>
                    {["-", "No", "Yes"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="C-Tree Trim" variant='outlined' size='small' select value={(treeTrim === true ? 2 : treeTrim === false ? 1 : 0)} onChange={(e) => setTreeTrim(parseInt(e.target.value) === 2 ? true : parseInt(e.target.value) === 1 ? false : null)}>
                    {["-", "No", "Yes"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="PL VIS Strips" variant='outlined' size='small' select value={(pL_VIS_Strips === true ? 2 : pL_VIS_Strips === false ? 1 : 0)} onChange={(e) => setPL_VIS_Strips(parseInt(e.target.value) === 2 ? true : parseInt(e.target.value) === 1 ? false : null)}>
                    {["-", "No", "Yes"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="PL VGR" variant='outlined' size='small' select value={(pL_VGR === true ? 2 : pL_VGR === false ? 1 : 0)} onChange={(e) => setPL_VGR(parseInt(e.target.value) === 2 ? true : parseInt(e.target.value) === 1 ? false : null)}>
                    {["-", "No", "Yes"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="INS DG" variant='outlined' size='small' select value={(inS_DG === true ? 2 : inS_DG === false ? 1 : 0)} onChange={(e) => setInS_DG(parseInt(e.target.value) === 2 ? true : parseInt(e.target.value) === 1 ? false : null)}>
                    {["-", "No", "Yes"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="RMV ABN DW'S" variant='outlined' size='small' select value={(rmV_ABN_DWS === true ? 2 : rmV_ABN_DWS === false ? 1 : 0)} onChange={(e) => setRmV_ABN_DWS(parseInt(e.target.value) === 2 ? true : parseInt(e.target.value) === 1 ? false : null)}>
                    {["-", "No", "Yes"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="Reloc DW'S Out Of Climbing Space" variant='outlined' size='small' select value={(relocDWS === true ? 2 : relocDWS === false ? 1 : 0)} onChange={(e) => setRelocDWS(parseInt(e.target.value) === 2 ? true : parseInt(e.target.value) === 1 ? false : null)}>
                    {["-", "No", "Yes"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item sm={12}>
                  <TextField disabled={!isEditPoleInfo} id="select" className='inp' label="PL GG On DG" variant='outlined' size='small' select value={(pL_GG_OnDG === true ? 2 : pL_GG_OnDG === false ? 1 : 0)} onChange={(e) => setPL_GG_OnDG(parseInt(e.target.value) === 2 ? true : parseInt(e.target.value) === 1 ? false : null)}>
                    {["-", "No", "Yes"].map((val, index) => {
                      return (
                        <MenuItem key={index} value={index}>{val}</MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
              </div>
            </div>
          </Modal>
          <Grid item sm={12}>
            <FormPoleImage images={images} setImages={setImages} />
          </Grid>

          <Grid item sm={12}>
            <FormPoleAttachment files={files} setFiles={setFiles} />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default FormPole;
