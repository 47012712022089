import { Button, TextField, InputAdornment, FormControlLabel, Checkbox } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Eye, EyeOff, Save, X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { editCompany, getCompany, postCompany } from '../company.api'
import Swal from 'sweetalert2'

export default function FormCompany(props: any) {
    const dispatch = useDispatch<any>()

    let [showPass, setShowPass] = useState<any>(false)
    let [showRePass, setShowRePass] = useState<any>(false)

    let [formValue, setFormValue] = useState<any>({
        name: "",
        address: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirm: "",
    })

    useEffect(() => {
        if (props.data.id) {
            setFormValue({
                name: props.data?.name,
                address: props.data?.address,
                phone: props.data?.phone,
            })
        }
    }, [props.data])

    const handleChange = (e: any) => {
        console.log(e.target.value, e.target.name)
        let { name, value } = e.target
        setFormValue({
            ...formValue,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (props.type === 'create') {
            dispatch(postCompany(formValue))
                .unwrap()
                .then((res: any) => {
                    if (res.status >= 400) {
                        Swal.fire(
                            'Create Company Failed!',
                            `${res.data.message || Object.values(res.data.errors)}`,
                            'error'
                        )
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'New company successfully created',
                            showConfirmButton: false,
                            timer: 1500
                        })
                            .then(() => {
                                dispatch(getCompany())
                                props.onClose()
                            })
                    }
                })
        }
        else if (props.type === 'edit') {
            dispatch(editCompany({
                ...formValue,
                id: props.data?.id
            }))
                .unwrap()
                .then((res: any) => {
                    if (res.status >= 400) {
                        Swal.fire(
                            'Edit Company Failed!',
                            `${res.data.message || Object.values(res.data.errors)}`,
                            'error'
                        )
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Company successfully edited',
                            showConfirmButton: false,
                            timer: 1500
                        })
                            .then(() => {
                                dispatch(getCompany())
                                props.onClose()
                            })
                    }
                })
        }
    }

    return (
        <div className='formcompany'>
            <h3>{props.type === 'create' ? "Create" : props.type === 'edit' ? "Edit" : ""} Company<X onClick={props.onClose} /></h3>
            <form onSubmit={handleSubmit}>
                <TextField
                    className='inp'
                    value={formValue.name}
                    onChange={handleChange}
                    name='name'
                    variant="outlined"
                    type='text'
                    label="Company Name"
                    size='small'
                    required
                />
                <TextField
                    className='inp'
                    value={formValue.address}
                    onChange={handleChange}
                    name='address'
                    variant="outlined"
                    type='text'
                    label="Address"
                    size='small'
                    required
                />
                <TextField
                    value={formValue.phone}
                    onChange={handleChange}
                    className='inp'
                    name='phone'
                    variant="outlined"
                    type='text'
                    label="Phone"
                    size='small'
                    required
                />
                {props.type === 'create' &&
                    <>
                        <TextField
                            className='inp'
                            name='email'
                            value={formValue.email}
                            onChange={handleChange}
                            variant="outlined"
                            type='email'
                            label="Email"
                            size='small'
                            required
                        />
                        <TextField
                            className='inp'
                            name='password'
                            value={formValue.password}
                            onChange={handleChange}
                            type={showPass ? 'text' : 'password'}
                            variant="outlined"
                            label="Password"
                            size='small'
                            required
                            inputProps={{
                                minLength: 8,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showPass ?
                                            <Eye onClick={() => setShowPass(!showPass)} />
                                            :
                                            <EyeOff onClick={() => setShowPass(!showPass)} />
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            className='inp'
                            name='passwordConfirm'
                            helperText={(<span className='helper-text'>Password must be at least 8 characters long, includes upper and lower case letter, at least 1 number and special character, and not matching to the email used</span>)}
                            value={formValue.passwordConfirm}
                            onChange={handleChange}
                            type={showRePass ? 'text' : 'password'}
                            variant="outlined"
                            label="Password Confirmation"
                            size='small'
                            required
                            inputProps={{
                                minLength: 8,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showRePass ?
                                            <Eye onClick={() => setShowRePass(!showRePass)} />
                                            :
                                            <EyeOff onClick={() => setShowRePass(!showRePass)} />
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                    </>
                }
                <div className="wrapbutton">
                    <Button type='submit' className="save" variant='outlined'><Save />Save</Button>
                </div>
            </form>
        </div>
    )
}


