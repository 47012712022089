import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import logoutil from '../../assets/images/logoutil.png'
import home_bg from '../../assets/images/welcome_home-background.png'
import { CheckCircle, KeyboardArrowLeft, KeyboardArrowRight, PersonOutline } from '@material-ui/icons'
import accountabilityIcon from '../../assets/images/welcome_icon-accountability.png'
import communicationIcon from '../../assets/images/welcome_icon-communication.png'
import teamworkIcon from '../../assets/images/welcome_icon-teamwork.png'
import { Container, IconButton } from '@material-ui/core'
import galery1 from '../../assets/images/welcome_carousel/20240422_135957.jpg'
import galery2 from '../../assets/images/welcome_carousel/20240422_154224.jpg'
import galery5 from '../../assets/images/welcome_carousel/IMG_6362.jpg'
import galery6 from '../../assets/images/welcome_carousel/IMG_6546.jpg'
import galery7 from '../../assets/images/welcome_carousel/IMG_6687.jpg'
import galery8 from '../../assets/images/welcome_carousel/IMG_7183.jpg'
import galery9 from '../../assets/images/welcome_carousel/IMG_7263.jpg'
import galery10 from '../../assets/images/welcome_carousel/IMG_7460.jpg'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router'

export default function Welcome() {

    const homeRef = useRef<any>(null)
    const aboutRef = useRef<any>(null)
    const serviceRef = useRef<any>(null)

    const navigate = useNavigate()

    const [galerySelected, setGalerySelected] = useState(0)

    const primary = "#0156d5"
    const secondary = "#f0f4ff"
    const fontColor = "#6a84a9"

    const menuList = [
        { name: "Home", ref: homeRef },
        { name: "About Us", ref: aboutRef },
        { name: "Service", ref: serviceRef },
        { name: "Contact", ref: null },
    ]

    const home = "Provide Operational Support And Contracting Services"

    const about = "Incorporated in 2019, we have performed projects throughout the Unitated States. We believe teamwork and self-discipline help us accomplish our goals. We ensure high quality deliverables with our trained and experienced team. We listen to our clients and prioritize good communications to keep projects on track. Valuating teamwork, we support our client's projects with flexibility and responsiveness to build collaborative relationship."

    const serviceList = [
        "GIS Surveying 7 Mapping",
        "Aerial Fielding",
        "As Built Inspection",
        "Existing System Analysis",
        "Inspection Services",
        "Intrusive Training",
        "Manhole Fielding",
        "Pole Line Inspection",
        "Architecture 7 Engineering",
        "Construction Drawing",
        "Make Ready Engineering",
        "Aerial Fiber Design",
        "Underground Fiber Design",
        "Design Services",
        "CAD Services",
        "Pole Load Analysis",
        "Utility Coordination Assistance",
        "Data Analytics",
        "System Validation",
        "Layout",
        "Traffic Control Plans",
        "Easements, Permits, and Licenses",
        "OSMOSE CVT Fielding",
        "Ike Fielding",
        "Ike HOA Processing and Clasification",
        "Katapult Fielding",
        "Katapult Association and Clasification"
    ]

    const valueList = [
        {
            icon: communicationIcon,
            title: "Communication",
            description: "We maintain efficient communication to understand your requirements"
        },
        {
            icon: teamworkIcon,
            title: "Teamwork",
            description: "We work with you to help you succeed"
        },
        {
            icon: accountabilityIcon,
            title: "Accountability",
            description: "We are responsible with our work and always strive for excellence"
        }
    ]

    const galeryList = [
        galery1,
        galery2,
        galery5,
        galery6,
        galery7,
        galery8,
        galery9,
        galery10
    ]

    const calculateGaleryPosition = (position: number) => {
        const newPosition = galerySelected + position
        if (newPosition > galeryList.length - 1) {
            return 0
        }
        else if (newPosition < 0) {
            return galeryList.length + position
        }
        else {
            return newPosition
        }
    }

    const handleScrollTo = (ref: any) => {
        if (ref) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Box height={"100vh"} width={"100vw"} overflow={"auto"} position={"relative"}>
            <Box width={"100%"} position={"absolute"} top={0} zIndex={5}>
                <Box display={"flex"} flexGrow={1} justifyContent={"space-between"} margin={5}>
                    <Box display={"flex"} gap={2} alignItems={"center"}>
                        <Box marginRight={3}>
                            <img style={{ height: "100px" }} className='logo' src={logoutil} alt="logo" />
                        </Box>
                        {menuList.map((menu, index) => (
                            <Box key={index}>
                                <Button sx={{ textTransform: "inherit", color: index === 0 ? primary : undefined }} variant='text' color='inherit' onClick={() => handleScrollTo(menu.ref)}>
                                    {menu.name}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                    <Box display={"flex"} alignItems={"center"}>
                        <Button sx={{ textTransform: "inherit" }} variant='contained' startIcon={<PersonOutline />} onClick={() => navigate("/login")}>Sign In</Button>
                    </Box>
                </Box>
            </Box>
            <Box ref={homeRef} sx={{ maxHeight: "100vh", position: "relative" }} top={1}>
                <img style={{ width: "100%", maxHeight: "100vh", zIndex: -1 }} src={home_bg} alt="bg" />
                <Box position={"absolute"} top={"50%"} width={"50vw"} marginLeft={15}>
                    <Typography variant={"h3"} fontWeight={"bold"}>{home}</Typography>
                </Box>
            </Box>
            <Box ref={aboutRef}>
                <Box paddingX={10} paddingY={20} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Typography align='center' variant={"h3"} fontWeight={"bold"} marginBottom={3}>About Us</Typography>
                    <Typography align='center' variant='body1' maxWidth={"1000px"} color={fontColor}>{about}</Typography>
                </Box>
            </Box>
            <Box ref={serviceRef} bgcolor={secondary} paddingY={10}>
                <Container>
                    <Typography variant={"h3"} fontWeight={"bold"} marginBottom={3}>Our Services</Typography>
                    <Grid container spacing={2}>
                        {serviceList.map((service, index) => (
                            <Grid item xs={4} key={index}>
                                <Box padding={1} display={"flex"} alignItems={"center"} gap={1}>
                                    <CheckCircle style={{ color: primary }} fontSize='small' />
                                    <Typography variant={"body1"} color={fontColor}>
                                        {service}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
            <Box >
                <Box paddingX={10} paddingY={20} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                    <Typography align='center' variant={"h3"} fontWeight={"bold"} marginBottom={3}>Our Value</Typography>
                    <Box display={"flex"} gap={5}>
                        {valueList.map((value, index) => (
                            <Paper key={index} elevation={10}>
                                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} height={180} width={180} padding={5} gap={2}>
                                    <img style={{ height: "100px" }} src={value.icon} alt="communication" />
                                    <Typography align='center' variant={"h5"} fontWeight={"bold"}>{value.title}</Typography>
                                    <Typography align='center' variant='body2' fontSize={10}>
                                        {value.description}
                                    </Typography>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                </Box>
            </Box>
            <Box height={1000} position={"relative"}>
                <Box position={"absolute"} top={"50%"} left={30} sx={{ transform: "translate(0%, -50%)" }} zIndex={4}>
                    <IconButton onClick={() => (galerySelected > 0) ? setGalerySelected(last => last - 1) : setGalerySelected(galeryList.length - 1)}>
                        <KeyboardArrowLeft />
                    </IconButton>
                </Box>
                <Box position={"absolute"} top={"50%"} left={"15%"} sx={{ transform: "translate(-50%, -50%)", opacity: 0.4 }} zIndex={1} width={300} height={400}>
                    <img style={{ height: "100%", width: "100%" }} src={galeryList[calculateGaleryPosition(-2)]} alt="gallery" />
                </Box>
                <Box position={"absolute"} top={"50%"} left={"30%"} sx={{ transform: "translate(-50%, -50%)", opacity: 0.5 }} zIndex={2} width={450} height={600}>
                    <img style={{ height: "100%", width: "100%" }} src={galeryList[calculateGaleryPosition(-1)]} alt="gallery" />
                </Box>
                <Box position={"absolute"} top={"50%"} left={"50%"} sx={{ transform: "translate(-50%, -50%)", opacity: 1 }} zIndex={3} width={600} height={800}>
                    <img style={{ height: "100%", width: "100%" }} src={galeryList[calculateGaleryPosition(0)]} alt="gallery" />
                </Box>
                <Box position={"absolute"} top={"50%"} left={"70%"} sx={{ transform: "translate(-50%, -50%)", opacity: 0.5 }} zIndex={2} width={450} height={600}>
                    <img style={{ height: "100%", width: "100%" }} src={galeryList[calculateGaleryPosition(1)]} alt="gallery" />
                </Box>
                <Box position={"absolute"} top={"50%"} left={"85%"} sx={{ transform: "translate(-50%, -50%)", opacity: 0.4 }} zIndex={1} width={300} height={400}>
                    <img style={{ height: "100%", width: "100%" }} src={galeryList[calculateGaleryPosition(2)]} alt="gallery" />
                </Box>
                <Box position={"absolute"} top={"50%"} right={30} sx={{ transform: "translate(0%, -50%)" }} zIndex={4}>
                    <IconButton onClick={() => (galerySelected < galeryList.length - 1) ? setGalerySelected(last => last + 1) : setGalerySelected(0)}>
                        <KeyboardArrowRight />
                    </IconButton>
                </Box>
            </Box>
            <Box bgcolor={secondary}>
                <Box paddingLeft={10} paddingY={1}>
                    <img style={{ height: "60px" }} className='logo' src={logoutil} alt="logo" />
                </Box>
            </Box>
            <Box height={50} bgcolor={primary}></Box>
        </Box>
    )
}