import { Button, Checkbox, TextField, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setCenter, setZoom } from "../../../dashboard/dashboard.reducer";
import { getPoleFieldingRequestByJobNumber, getPoleLoadingRequest } from "../../../poleInformation/poleInformation.api";
import { setListPoleLoadingRequest, setIsShowPoleFieldingRequest, setIsShowPoleLoadingRequest, setListPoleFieldingRequest } from "../../../poleInformation/poleInformation.reducer";
import { getFieldingRequestId, getLoadingRequest } from "../../sideTab.api";
import { handleLayerActive, handleLoadingRequestIdActive, handleModalLoadingRequest, handleNoteIcon, setFieldingIdList, setJobIdList, setLoadingRequestIdList } from "../../sideTab.reducer";
import pencilBlack from "../../../../assets/icons/pencilBlack.png";
import pencilGreen from "../../../../assets/icons/pencilGreen.png";
import ModalTemplate from "../../../../shared/ModalTemplate";
import LoadingRequestForm from "./LoadingRequestForm";

function LoadingRequest() {

  const dispatch = useDispatch<any>()

  const { jobNumberIdActive, listLoadingRequest, loadingRequestIdList, jobIdList, fieldingIdList, isLayerActive, modalLoadingRequest, statusJobNumber, sideTabActive } = useSelector((state: RootState) => state.sideTab);
  const { listPoleFieldingRequest, listPoleLoadingRequest, isShowPoleFieldingRequest } = useSelector((state: RootState) => state.poleInformation);
  const { zoom } = useSelector((state: RootState) => state.dashboard);

  const [polelLoadingSelected, setPoleLoadingSelected] = useState<any>({})

  useEffect(() => {
    dispatch(getLoadingRequest(jobNumberIdActive.id));
    dispatch(setIsShowPoleFieldingRequest(false));
    dispatch(setIsShowPoleLoadingRequest(true));
  }, [jobNumberIdActive]);

  let handleChangeCheckbox = (e: any, loadingRequest: any) => {
    if (e.target.checked) {
      let array: any = [...loadingRequestIdList];
      array.push(loadingRequest.id);
      dispatch(setLoadingRequestIdList(array));
      dispatch(getPoleLoadingRequest(loadingRequest.id))
        .unwrap()
        .then((response: any) => {
          let poleLoadingRequest: any = listPoleLoadingRequest?.filter((item: any) => item?.ocalcId != loadingRequest.id);
          dispatch(setListPoleLoadingRequest(poleLoadingRequest.concat(response.data)));
          dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
          if (zoom < 16) {
            dispatch(setZoom(16))
          }
        })
    }
    else {
      let array: any = [...loadingRequestIdList];
      let removeItem = array.indexOf(loadingRequest.id);
      if (removeItem !== -1) {
        array.splice(removeItem, 1);
        dispatch(setLoadingRequestIdList(array));
      }
      let poleLoadingRequest: any = listPoleLoadingRequest?.filter((item: any) => item?.ocalcId != loadingRequest.id);
      dispatch(setListPoleLoadingRequest(poleLoadingRequest));
      dispatch(setCenter({ latitude: poleLoadingRequest[0]?.latitude, longitude: poleLoadingRequest[0]?.longitude }))
      let jobIdListNew: any = jobIdList?.filter((item: any) => item !== loadingRequest.jobNumberId)
      dispatch(setJobIdList(jobIdListNew))
    }
  };

  const handleShowFielding = (e: any) => {
    dispatch(setIsShowPoleFieldingRequest(e.target.checked))
    if (e.target.checked) {
      if (!jobIdList.includes(jobNumberIdActive?.id)) {
        dispatch(setJobIdList([...jobIdList, jobNumberIdActive?.id]))
      }
      dispatch(getPoleFieldingRequestByJobNumber(jobNumberIdActive?.id))
        .unwrap()
        .then((res: any) => {
          let idList: any = []
          res.data.map((pole: any) => {
            idList.push(pole.id)
          })
          let poleFieldingRequest: any = listPoleFieldingRequest?.filter((item: any) => !idList.includes(item?.id));
          dispatch(setCenter({ latitude: res?.data[0]?.latitude, longitude: res?.data[0]?.longitude }))
          dispatch(setListPoleFieldingRequest(poleFieldingRequest.concat(res.data)));
        })
      dispatch(getFieldingRequestId(jobNumberIdActive?.id))
        .unwrap()
        .then((res: any) => {
          let idList: any = []
          res.data.map((fielding: any) => {
            if (!idList.includes(fielding.id)) {
              idList.push(fielding.id)
            }
          })
          let fieldingIdListNew: any = fieldingIdList?.filter((item: any) => !idList.includes(item));
          dispatch(setFieldingIdList(fieldingIdListNew.concat(idList)))
        })
    }
  }

  const handleAdd = () => {
    dispatch(handleModalLoadingRequest({ isOpen: true, formType: "" }));
  }

  const handleStarActive = (res: any) => {
    dispatch(handleNoteIcon(null))
    if (isLayerActive.layerLoadingId) {
      dispatch(handleLayerActive({}))
      dispatch(handleLoadingRequestIdActive(res));
    }
    else {
      dispatch(
        handleLayerActive({
          layerJobNumberId: "",
          layerFieldingId: "",
          layerLoadingId: res.id,
          note: false,
          status: true,
          label: "loading"
        })
      )
      dispatch(handleLoadingRequestIdActive(res));
      dispatch(setLoadingRequestIdList([res.id]));
    }
    dispatch(getPoleLoadingRequest(res.id))
      .unwrap()
      .then((response: any) => {
        dispatch(setListPoleLoadingRequest(listPoleLoadingRequest.filter((item: any) => item.ocalcId !== res.id).concat(response.data)));
        dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
        if (zoom < 16) {
          dispatch(setZoom(16))
        }
      })
  };

  const handleClickName = (dataSelected: any) => {
    if (dataSelected.id === polelLoadingSelected.id) {
      setPoleLoadingSelected({})
    }
    else {
      setPoleLoadingSelected(dataSelected)
    }
  }

  return (
    <>
      <ModalTemplate component={() => <LoadingRequestForm />} onOpen={modalLoadingRequest.isOpen} />
      {statusJobNumber > 0
        ? (
          <Tooltip className="need-reactive" title="reactivate job to create new loading request">
            <Button>
              <Plus /> Loading request
            </Button>
          </Tooltip>
        )
        : (
          <Button onClick={handleAdd}>
            <Plus /> Loading request
          </Button>
        )
      }
      <div className="fielding-toggle">
        <Checkbox className="customcheck" color="primary" size="small" checked={isShowPoleFieldingRequest} onChange={handleShowFielding} />
        <span>Show Pole Fielding Request</span>
      </div>
      <ul>
        {listLoadingRequest.map((res: any, i: any) => (
          <li key={i} className="projnumberlist" style={{ backgroundColor: (res.id === polelLoadingSelected.id ? "#cbced2" : "") }}>
            <div>
              <Checkbox className="customcheck" color="primary" checked={loadingRequestIdList.includes(res.id)} onChange={(e: any) => handleChangeCheckbox(e, res)} />
            </div>
            <span onClick={() => handleClickName(res)}>{res.name}</span>
            {(statusJobNumber === 0) && (<Tooltip title="edit/activate"><img src={isLayerActive.layerLoadingId === res.id && isLayerActive.status ? pencilGreen : pencilBlack} alt="star" onClick={() => handleStarActive(res)} /></Tooltip>)}
          </li>
        ))}
      </ul>
      {polelLoadingSelected.id && (
        <div className="status-aerial wraptab" style={{ left: 322 * (Object.values(sideTabActive).reduce((total: number, d: any) => total + (d ? 1 : 0), 0)) }}>
          <div className="title">Status</div>
          <Typography variant="subtitle1" align="center">{polelLoadingSelected.name}</Typography>
          <TextField className='inp' disabled value={polelLoadingSelected.createdDateString} label="Created Date" size="small" />
          <TextField className='inp' disabled value={polelLoadingSelected.dueDateString} label="Due Date" size="small" />
          <TextField className='inp' disabled value={polelLoadingSelected.completedPolePercentage} label="Status" size="small" />
          <TextField className='inp' disabled value={polelLoadingSelected.completeDateString} label="Complete Date" size="small" />
          <TextField className='inp' disabled value={polelLoadingSelected.poleCount} label="Poles" size="small" />
        </div>
      )}
    </>
  );
}

export default LoadingRequest;
