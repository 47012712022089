import { Button, MenuItem, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Edit2, Save, Trash, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import { setNotePath, setNotePathModal } from "../../../dashboard/dashboard.reducer";
import { deleteTempMarkerFielding, editMarkerFielding, getMarkerFielding, postMarkerFielding } from "../../../poleInformation/poleInformation.api";
import { setListMarkerFieldingRequest } from "../../../poleInformation/poleInformation.reducer";

export default function FormFieldingPath() {

  const dispatch = useDispatch<any>()

  const { notePathModal, notePath } = useSelector((state: RootState) => state.dashboard)
  const { fieldingRequestIdActive, noteEnums } = useSelector((state: RootState) => state.sideTab)
  const { listMarkerFieldingRequest } = useSelector((state: RootState) => state.poleInformation)

  const [form, setForm] = useState<any>({
    fieldingRequestId: fieldingRequestIdActive.id,
    type: 0,
    lineData: JSON.stringify(notePath),
    subject: "",
    notes: "",
    markerColor: 0
  })
  const [isView, setIsView] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (notePathModal.data) {
      setIsView(true)
      setForm(notePathModal.data)
    }
  }, [notePathModal])

  const handleChangeForm = (e: any) => {
    let name = e.target.name
    let value = e.target.value
    setForm({
      ...form,
      [name]: value
    })
  }

  const handleCancel = () => {
    dispatch(setNotePathModal({ status: false }))
  }

  const handleSave = (e: any) => {
    if (isEdit) {
      dispatch(editMarkerFielding(form))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire("Edit path fielding failed!");
          }
          else {
            dispatch(getMarkerFielding(form.fieldingRequestId))
              .unwrap()
              .then((response: any) => {
                Swal.fire("Edit path fielding success");
                let listMarkerFieldingRequestNew = listMarkerFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== form.fieldingRequestId);
                dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequestNew.concat(response.data)));
                handleCancel()
              })
          }
        })
    }
    else {
      dispatch(postMarkerFielding(form))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire("Add path fielding failed!");
          }
          else {
            handleCancel()
            dispatch(getMarkerFielding(fieldingRequestIdActive.id))
              .unwrap()
              .then((response: any) => {
                Swal.fire("Add path fielding success");
                let listMarkerFieldingRequestNew = listMarkerFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== fieldingRequestIdActive.id);
                dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequestNew.concat(response.data)));
                handleCancel()
                dispatch(setNotePath([]))
              })
          }
        })
    }
  }

  const handleEdit = () => {
    setIsView(false)
    setIsEdit(true)
  }

  const handleDelete = () => {
    dispatch(deleteTempMarkerFielding(notePathModal?.data?.id))
      .unwrap()
      .then((res: any) => {
        dispatch(getMarkerFielding(form.fieldingRequestId))
          .unwrap()
          .then((response: any) => {
            let listMarkerFieldingRequestNew = listMarkerFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== form.fieldingRequestId);
            dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequestNew.concat(response.data)));
            handleCancel()
          })
      })
  }

  return (
    <div className="formproject">
      <h3>Path Fielding<X onClick={handleCancel} /></h3>
      <form>
        <TextField
          className="inp"
          variant="outlined"
          type="text"
          label="Subject"
          size="small"
          name="subject"
          value={form.subject}
          onChange={handleChangeForm}
          inputProps={{
            readOnly: isView
          }}
        />
        <TextField
          className="inp"
          variant="outlined"
          type="text"
          label="Note"
          size="small"
          multiline={true}
          name="notes"
          value={form.notes}
          onChange={handleChangeForm}
          inputProps={{
            readOnly: isView
          }}
        />
        <TextField
          id="select"
          className="inp"
          label="Color"
          variant="outlined"
          size="small"
          select
          name="markerColor"
          value={form.markerColor}
          onChange={handleChangeForm}
          inputProps={{
            readOnly: isView
          }}>
          {noteEnums?.markerColors?.map((item: any, itemIndex: number) => (
            <MenuItem key={item} value={item.value}>{item.display}</MenuItem>
          ))}
        </TextField>
        <br />
        <div className="wrapbutton">
          {isView
            ? (
              <>
                <Button className="delete" variant="outlined" onClick={handleDelete}>
                  <Trash /> Delete
                </Button>
                <Button className="edit" variant="outlined" onClick={handleEdit}>
                  <Edit2 /> Edit
                </Button>
              </>
            )
            : (
              <Button className="save" variant="outlined" onClick={handleSave}>
                <Save /> Save
              </Button>
            )
          }
        </div>
      </form>
    </div>
  );
}
