import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { handleIsMapModal } from '../assignFielding.reducer';
import LoadingCustom from '../../../shared/LoadingCustom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function MapModal() {

    const dispatch = useDispatch<any>()

    const { loading, mapPoleList } = useSelector((state: RootState) => state.assignFielding)

    const onLoad = (map: any) => {};

    return (
        <div className='map-modal'>
            <div className="header">
                Location
                <X onClick={() => dispatch(handleIsMapModal())} />
            </div>
            <LoadingCustom isLoading={loading} />
            <LoadScript googleMapsApiKey="AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs" libraries={["places"]}>
                <GoogleMap
                    mapContainerStyle={{
                        height: "100%",
                        width: "100%",
                    }}
                    onLoad={onLoad}
                    center={{ lat: parseFloat(mapPoleList[0].latitude), lng: parseFloat(mapPoleList[0].longitude) }}
                    zoom={10}
                    mapTypeId="terrain"
                    clickableIcons={false}
                >
                    {mapPoleList?.map((pole: any, i: number) => {
                        return (
                            <Marker
                                key={i}
                                position={{ lat: parseFloat(pole.latitude), lng: parseFloat(pole.longitude) }}
                                label={pole.jobNumber + " - " + pole.fieldingRequest}
                            />
                        )
                    })}
                </GoogleMap>
            </LoadScript>
        </div>
    );
}

export default MapModal;