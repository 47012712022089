import { Button, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { useEffect, useState } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import { editLoadingRequest } from "../../../sideTab/sideTab.api";

export default function LoadingRequestAssign(props: any) {

    const dispatch = useDispatch<any>()

    const { listAccount } = useSelector((state: RootState) => state.account);

    const [calcSpecs, setCalcSpecs] = useState<any>([])

    useEffect(() => {
        setCalcSpecs(
            listAccount.filter((account: any) => (props.values.assignedCalcSpecIds ? props.values.assignedCalcSpecIds.split(', ') : []).includes(account.id))
        )
    }, [])

    const handleUpdate = () => {
        dispatch(editLoadingRequest({
            id: props.values.poleLoadingId,
            description: props.values.description,
            dueDateString: moment(new Date(props.values.dueDateString)).format("MM/DD/yyyy"),
            calcSpecManagerIds: props.values.assignedCalcSpecManagerIds ? props.values.assignedCalcSpecManagerIds.split(', ') : [],
            calcSpecIds: calcSpecs.map((item: any) => item.id)
        }))
            .unwrap()
            .then((res: any) => {
                if (res.status >= 400) {
                    Swal.fire("Edit Loading Request failed!");
                }
                else {
                    Swal.fire({
                        icon: "success",
                        title: "Successfully assigned",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                        .then((res: any) => {
                            props.handleClose()
                        });
                }
            });
    }

    return (
        <div className="formproject">
            <h3>Assign<X onClick={props.handleClose} /></h3>
            <Autocomplete
                multiple
                options={listAccount.filter((item: any) => item.companyId === localStorage.getItem("companyIdSelected") && (item.roles.includes("CalcSpec") || item.roles.includes("LoadingQc")))}
                getOptionLabel={(item => item.email)}
                value={calcSpecs}
                onChange={(e, value) => setCalcSpecs(value)}
                className="inp"
                renderInput={(params) => <TextField {...params} label="Loading Processing" size="small" variant="outlined" />}
            />
            <br />
            <div className="wrapbutton">
                <Button className="save" onClick={handleUpdate}>Save</Button>
            </div>
        </div>
    )
}