
import { Box, Button, Container, Grid, LinearProgress, Link, TextField } from '@mui/material';
import Template from '../../../shared/Template';
import QRCode from "react-qr-code";
import ProfileMap from '../component/ProfileMap';
import empty_profile from "../../../assets/images/empty_profile.png";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, linkToFP, unlink } from '../profile.api';
import axios from 'axios';
import { baseUrl } from '../../../state';
import { CheckCircle, Link2 } from 'react-feather';
import Swal from 'sweetalert2';
import LoadingCustom from '../../../shared/LoadingCustom';
import { RootState } from '../../../app/store';

function Profile() {

    const loginAccess: boolean = localStorage.getItem('auth') && localStorage.getItem('token') ? true : false

    const dispatch = useDispatch<any>()

    const { loading } = useSelector((state: RootState) => state.profile)

    const [profile, setProfile] = useState<any>({})
    const [isOnProgress, setIsOnProgress] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(75)
    const [linkUfShowEmail, setLinkUfShowEmail] = useState(false)
    const [linkUfEmail, setLinkUfEmail] = useState("")

    const getProfileData = () => {
        let pathName = window.location.pathname
        let keyArr = pathName.split('/')
        let key = keyArr[(keyArr.length - 1)]
        dispatch(getProfile(key))
            .unwrap()
            .then((res: any) => {
                setProfile(res.data)
            })
    }

    useState(() => {
        getProfileData()
    })

    const onChangeImage = async (e: any) => {
        setIsOnProgress(true)
        setUploadProgress(0)
        let data = new FormData();
        data.append("userId", profile?.id)
        data.append("file", e.target.files[0])
        try {
            await axios.post(`${baseUrl}/api/Account/UploadProfilePicture`, data, {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                    setUploadProgress(percentCompleted)
                }
            });
            setIsOnProgress(false)
            getProfileData()
        } catch (error: any) {
            console.log(error);
        }
    }

    const handleLinkFp = () => {
        if (linkUfEmail) {
            dispatch(linkToFP(linkUfEmail))
                .unwrap()
                .then((response: any) => {
                    if (response.status) {
                        getProfileData()
                        setLinkUfShowEmail(false)
                    }
                    else {
                        Swal.fire("Error", "Failed to link account", "error")
                    }
                })
                .catch((error: any) => {
                    Swal.fire("Error", "Failed to link account", "error")
                })
        }
    }

    const handleUnlink = () => {
        if (profile.linkedEmail) {
            setLinkUfEmail("")
            dispatch(unlink())
                .unwrap()
                .then((response: any) => {
                    if (response.status) {
                        getProfileData()
                        setLinkUfShowEmail(false)
                    }
                    else {
                        Swal.fire("Error", "Failed to unlink account", "error")
                    }
                })
        }
    }

    let component = () => (
        <>
            <LoadingCustom isLoading={loading} />
            <Container maxWidth={false} className="profile">
                <div className="box">
                    <h1>Profile</h1>
                    <Grid container className='contain'>
                        <Grid item md={3} className='left'>
                            <div className="box">
                                <img src={profile?.profilePicturePath ? baseUrl + "/api/Global/Download?path=" + profile?.profilePicturePath : empty_profile} alt="image" />
                                {loginAccess && (
                                    <label htmlFor="button-file">
                                        <input
                                            style={{ display: "none" }}
                                            id="addKml"
                                            type="file"
                                            accept="image/*"
                                            onChange={onChangeImage}
                                        />
                                        <label htmlFor="addKml">
                                            <Button className="upload-btn" variant='outlined' component="span">
                                                Change Picture
                                            </Button>
                                            {isOnProgress && <LinearProgress variant="determinate" value={uploadProgress} />}
                                        </label>
                                    </label>
                                )}
                                <h2>{profile?.firstName} {profile?.lastName}</h2>
                                <h3>{profile?.email}</h3>
                                <QRCode value={profile?.barcodeUrl ? profile?.barcodeUrl : ""} />
                                <Box marginTop={3} display={"flex"} gap={1}>
                                    <Link2 />
                                    <Link style={{ cursor: "pointer" }} component={"button"} onClick={() => setLinkUfShowEmail(last => !last)}>
                                        Link account to Fielder Pro
                                    </Link>
                                    {profile.linkedEmail && (<CheckCircle color='green' />)}
                                </Box>
                                {linkUfShowEmail && (
                                    <Box display={"flex"} alignItems={"center"} marginTop={1}>
                                        <TextField variant='outlined' style={{ marginTop: 4 }} size='small' placeholder="Email" type="email" value={profile.linkedEmail || linkUfEmail} InputProps={{ readOnly: Boolean(profile.linkedEmail) }} onChange={(e) => setLinkUfEmail(e.target.value)} />
                                        {Boolean(profile.linkedEmail)
                                            ? <Button variant="contained" style={{ marginLeft: 8 }} onClick={handleUnlink}>Unlink</Button>
                                            : <Button variant="contained" style={{ marginLeft: 8 }} onClick={handleLinkFp}>Link</Button>
                                        }
                                    </Box>
                                )}
                            </div>
                        </Grid>
                        <Grid item md={9} className='right'>
                            <div className="box">
                                <h1>Hi, I'm {profile?.firstName}</h1>
                                {profile?.lastLocation && <ProfileMap lastLocation={profile?.lastLocation} />}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </>
    )
    return <Template component={component} />
}

export default Profile;