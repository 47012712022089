import { Button, Checkbox, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setAerialFielding } from "../../../assignFielding/assignFielding.reducer";
import { getMarkerFieldingRequestByJobNumber, getMeasureMarkerByJob, getMidspanFieldingRequestByJobNumber, getPoleFieldingRequestByJobNumber } from "../../../poleInformation/poleInformation.api";
import {
    handleModalJobNumber,
    handleFormTypeJobNumber,
    handleJobNumberIdActive,
    setJobDetail,
    setFieldingIdList,
    setJobIdList,
    setNoteList,
    setDetailMenu,
    setFieldingList,
    setLoadingRequestIdList,
    setStatusJobNumber,
    setSidetabActive
} from "../../sideTab.reducer";
import pencilBlack from "../../../../assets/icons/pencilBlack.png";
import ModalTemplate from "../../../../shared/ModalTemplate";
import ModalForm from "./ModalForm";
import { setIsShowPoleFieldingRequest, setListMarkerFieldingRequest, setListMeasureMarker, setListMidspanFieldingRequest, setListPoleFieldingRequest, setListPoleLoadingRequest } from "../../../poleInformation/poleInformation.reducer";
import { getFieldingRequestId, getJobNumber, getJobNumberDetails, getNoteListByJobNumberId } from "../../sideTab.api";
import { setCenter, setNoteToShow, setZoom } from "../../../dashboard/dashboard.reducer";
import Roles from "../../../../shared/Roles";
import { useLocation } from "react-router-dom";

export default function JobNumber() {

    const dispatch = useDispatch<any>()
    const location = useLocation();

    const { listJobNumber, jobNumberIdActive, modalJobNumber, projectIdActive, fieldingIdList, jobIdList, noteList, statusJobNumber, sideTabActive, loading } = useSelector((state: RootState) => state.sideTab);
    const { listPoleFieldingRequest, listMidspanFieldingRequest, listMarkerFieldingRequest, listMeasureMarker } = useSelector((state: RootState) => state.poleInformation);
    const { zoom } = useSelector((state: RootState) => state.dashboard);

    const [filteredListJobNumber, setFilteredListJobNumber] = useState<any>([])

    useEffect(() => {
        const jobnumberStatus = new URLSearchParams(location.search).get('jobnumberStatus');
        if (!loading) {
            if (jobnumberStatus !== undefined && jobnumberStatus !== null) {
                handleChangeList({ target: { value: jobnumberStatus } })
            }
            else {
                handleChangeList({ target: { value: "0" } })
            }
        }
    }, [projectIdActive.id])

    useEffect(() => {
        const jobnumberId = new URLSearchParams(location.search).get('jobnumberId');
        if (jobnumberId) {
            let listJobnumberFromParams = listJobNumber.filter((jobnumber: any) => jobnumber.id === jobnumberId)
            if (listJobnumberFromParams.length > 0) {
                dispatch(handleJobNumberIdActive(listJobnumberFromParams[0]))
                dispatch(getJobNumberDetails(listJobnumberFromParams[0].id));
                dispatch(setJobDetail(listJobnumberFromParams[0]));
                dispatch(setSidetabActive({ ...sideTabActive, jobDetail: true, jobDetailMenu: true }))
            }
        }
    }, [listJobNumber])

    useEffect(() => {
        setFilteredListJobNumber(listJobNumber)
    }, [listJobNumber])

    const handleClickJobNumber = () => {
        dispatch(handleModalJobNumber());
        dispatch(handleFormTypeJobNumber("new"));
    };

    const handleChange = async (e: any, job: any) => {
        handleJobNumber(job)
        dispatch(setIsShowPoleFieldingRequest(true))
        dispatch(setNoteToShow([0, 1, 2, 3, 4]))
        // note
        if (e.target.checked) {
            dispatch(getNoteListByJobNumberId(job.id))
                .unwrap()
                .then((res: any) => {
                    dispatch(setNoteList(noteList.concat(res.data)))
                })
        }
        else {
            let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== job.id)
            dispatch(setNoteList(noteListNew))
        }
        // jobid
        if (e.target.checked) {
            if (!jobIdList.includes(job.id)) {
                dispatch(setJobIdList([...jobIdList, job.id]))
            }
        }
        else {
            let jobIdListNew: any = jobIdList?.filter((item: any) => item !== job.id)
            dispatch(setJobIdList(jobIdListNew))
        }
        await dispatch(getFieldingRequestId(job.id))
            .unwrap()
            .then(async (res: any) => {
                let fieldingIdListNew: any = []
                if (e.target.checked) {
                    fieldingIdListNew = fieldingIdList.concat(res.data.map((d: any) => d.id))
                    await dispatch(getPoleFieldingRequestByJobNumber(job.id))
                        .unwrap()
                        .then((res: any) => {
                            dispatch(setCenter({ latitude: res?.data[0]?.latitude, longitude: res?.data[0]?.longitude }))
                            if (zoom < 16) {
                                dispatch(setZoom(16))
                            }
                            dispatch(setListPoleFieldingRequest(listPoleFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId)).concat(res.data)));
                        })
                    await dispatch(getMidspanFieldingRequestByJobNumber(job.id))
                        .unwrap()
                        .then((res: any) => {
                            dispatch(setCenter({ latitude: res?.data[0]?.latitude, longitude: res?.data[0]?.longitude }))
                            if (zoom < 16) {
                                dispatch(setZoom(16))
                            }
                            dispatch(setListMidspanFieldingRequest(listMidspanFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId)).concat(res.data)));
                        })
                    await dispatch(getMarkerFieldingRequestByJobNumber(job.id))
                        .unwrap()
                        .then((res: any) => {
                            dispatch(setCenter({ latitude: res?.data[0]?.latitude, longitude: res?.data[0]?.longitude }))
                            if (zoom < 16) {
                                dispatch(setZoom(16))
                            }
                            dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId)).concat(res.data)));
                        })
                    await dispatch(getMeasureMarkerByJob(job.id))
                        .unwrap()
                        .then((res: any) => {
                            dispatch(setCenter({ latitude: res?.data[0]?.latitude, longitude: res?.data[0]?.longitude }))
                            if (zoom < 16) {
                                dispatch(setZoom(16))
                            }
                            dispatch(setListMeasureMarker(listMeasureMarker?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId)).concat(res.data)));
                        })
                }
                else {
                    fieldingIdListNew = fieldingIdList.filter((d: any) => !res.data.map((d: any) => d.id).includes(d))
                    dispatch(setListPoleFieldingRequest(listPoleFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId))));
                    dispatch(setListMidspanFieldingRequest(listMidspanFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId))));
                    dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId))));
                    dispatch(setListMeasureMarker(listMeasureMarker?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId))));
                }
                dispatch(setFieldingIdList(fieldingIdListNew))
            })
    }

    const handleJobNumber = (data: any) => {
        dispatch(setAerialFielding({}));
        dispatch(setDetailMenu({}))
        dispatch(setFieldingList([]))
        if (data.id === jobNumberIdActive.id) {
            dispatch(handleJobNumberIdActive({}))
            dispatch(setJobDetail(null))
        }
        else {
            dispatch(getJobNumberDetails(data.id));
            dispatch(setJobDetail(data));
            dispatch(setSidetabActive({ ...sideTabActive, jobDetail: true, jobDetailMenu: true }))
        }
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('jobnumberId', data.id);
        window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    };

    const handleChangeList = (e: any) => {
        dispatch(setFieldingIdList([]))
        dispatch(setListPoleFieldingRequest([]))
        dispatch(setLoadingRequestIdList([]))
        dispatch(setListPoleLoadingRequest([]))
        dispatch(handleJobNumberIdActive({}))
        dispatch(setNoteList([]))
        dispatch(setJobIdList([]))
        dispatch(setAerialFielding({}));
        dispatch(setJobDetail(null));
        let value = parseInt(e.target.value)
        dispatch(setStatusJobNumber(value))
        dispatch(getJobNumber({
            id: projectIdActive.id,
            status: value
        }))
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('jobnumberStatus', e.target.value);
        window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    }

    const handleStar = (job: any) => {
        dispatch(getJobNumberDetails(job.id));
        dispatch(handleModalJobNumber());
        dispatch(handleFormTypeJobNumber("edit"));
    }

    const handleSearch = (e: any) => {
        let result: any = []
        listJobNumber.map((item: any) => {
            if (item.name.toUpperCase().includes(e.target.value.toUpperCase())) {
                result.push(item)
            }
        });
        setFilteredListJobNumber(result);
    }

    return (
        <>
            <ModalTemplate component={() => <ModalForm onClose={() => dispatch(handleModalJobNumber())} />} onOpen={modalJobNumber} />
            <div className="wraptab">
                <Typography variant="subtitle1" align="center" style={{ fontWeight: "bold" }}>Job number for</Typography>
                <Typography variant="subtitle1" align="center">Project:</Typography>
                <Typography variant="subtitle1" align="center">{projectIdActive.projectNumber}</Typography>
                {(Roles("jobnumber_add") && statusJobNumber === 0) && (
                    <Button onClick={handleClickJobNumber}>
                        <Plus /> JOB NUMBER
                    </Button>
                )}
                <TextField className="inp" variant="outlined" placeholder="Search" size="small" onChange={handleSearch} />
                <TextField className="inp" variant="outlined" size="small" select value={statusJobNumber} onChange={handleChangeList}>
                    <MenuItem value="0">Active Jobs</MenuItem>
                    <MenuItem value="1">Completed Jobs</MenuItem>
                    <MenuItem value="2">Archived Jobs</MenuItem>
                </TextField>
                <ul>
                    {filteredListJobNumber.map((res: any, i: any) => (
                        <li key={i} className="projnumberlist" style={{ backgroundColor: res.id === jobNumberIdActive.id ? "#cbced2" : "" }}>
                            <div>
                                <Tooltip title="view pole">
                                    <Checkbox
                                        className="customcheck"
                                        color="primary"
                                        checked={jobIdList.includes(res.id)}
                                        onChange={(e: any) => handleChange(e, res)}
                                    />
                                </Tooltip>
                            </div>
                            <Tooltip title="show details">
                                <span onClick={() => handleJobNumber(res)}> {res.name} </span>
                            </Tooltip>
                            {(Roles("jobnumber_edit") && statusJobNumber === 0) && (
                                <Tooltip title="Edit job number and due date">
                                    <img
                                        src={pencilBlack}
                                        alt="star"
                                        onClick={() => handleStar(res)}
                                    />
                                </Tooltip>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};