import { Button, TextField, InputAdornment, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Eye, EyeOff, Save, X } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { RootState } from '../../../../app/store'
import Roles from '../../../../shared/Roles'
import { editAccount, getAccount, postAccount } from '../account.api'

export default function FormAccount(props: any) {

    const dispatch = useDispatch<any>()

    let [showPass, setShowPass] = useState<any>(false)
    let [showRePass, setShowRePass] = useState<any>(false)
    let [isCscaEnabled, setIsCscaEnabled] = useState(false)

    const { listCompany } = useSelector((state: RootState) => state.company)

    let [formValue, setFormValue] = useState<any>({
        "firstName": "",
        "lastName": "",
        "email": "",
        "password": "",
        "passwordConfirm": "",
        "companyId": localStorage.getItem("companyIdSelected"),
        "roles": {
            "csca": false,
            "cscf": false,
            "companyAdmin": false,
            "fielder": false,
            "engineer": false,
            "fieldingManager": false,
            "projectManager": false,
            "projectTeam": false,
            "calcSpecManager": false,
            "calcSpec": false,
            "loadingQc": false,
            "fieldingRequestSimple": false,
            "fielderSimple": false
        }
    })

    useEffect(() => {
        if (props.data?.id) {
            console.log(props.data)
            setFormValue({
                "firstName": props.data?.firstName,
                "lastName": props.data?.lastName,
                "companyId": props.data?.companyId,
                "roles": {
                    "csca": props.data.roles.includes("CSCA"),
                    "cscf": props.data.roles.includes("CSCF"),
                    "companyAdmin": props.data.roles.includes("CompanyAdmin"),
                    "fielder": props.data.roles.includes("Fielder"),
                    "engineer": props.data.roles.includes("Engineer"),
                    "fieldingManager": props.data.roles.includes("FieldingManager"),
                    "projectManager": props.data.roles.includes("ProjectManager"),
                    "projectTeam": props.data.roles.includes("ProjectTeam"),
                    "calcSpecManager": props.data.roles.includes("CalcSpecManager"),
                    "calcSpec": props.data.roles.includes("CalcSpec"),
                    "loadingQc": props.data.roles.includes("LoadingQc"),
                    "fieldingRequestSimple": props.data.roles.includes("FieldingRequestSimple"),
                    "fielderSimple": props.data.roles.includes("FielderSimple"),
                }
            })
        }
        else {
            setFormValue({
                "firstName": "",
                "lastName": "",
                "email": "",
                "password": "",
                "passwordConfirm": "",
                "companyId": localStorage.getItem("companyIdSelected"),
                "roles": {
                    "csca": false,
                    "cscf": false,
                    "companyAdmin": false,
                    "fielder": false,
                    "engineer": false,
                    "fieldingManager": false,
                    "projectManager": false,
                    "projectTeam": false,
                    "calcSpecManager": false,
                    "calcSpec": false,
                    "loadingQc": false,
                    "fieldingRequestSimple": false,
                    "fielderSimple": false
                }
            })
        }
    }, [props.data])

    const handleChange = (e: any) => {
        let { name, value } = e.target
        if (name === "email") {
            let isEmailUf = value.includes("utilityfielding.com")
            setIsCscaEnabled(isEmailUf)
        }
        setFormValue({
            ...formValue,
            [name]: value
        })
    }
    const handleRoles = (e: any) => {
        let { name, checked } = e.target
        setFormValue({
            ...formValue,
            roles: {
                ...formValue.roles,
                [name]: checked
            }
        })
    }

    const checkRolesForm = (obj: any) => {
        for (const [key, value] of Object.entries(obj)) {
            if (value) {
                return true
            }
        }
        return false
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (!checkRolesForm(formValue.roles)) {
            Swal.fire(
                '',
                'You need to choose role!',
                'warning'
            )
        } else {
            if (props.type === 'create') {
                dispatch(postAccount(formValue))
                    .unwrap()
                    .then((res: any) => {
                        if (res.status >= 400) {
                            Swal.fire(
                                'Create Account Failed!',
                                `${res.data.message || Object.values(res.data.errors)}`,
                                'error'
                            )
                        } else {
                            Swal.fire({
                                icon: 'success',
                                title: 'New Account Successfully Created',
                                showConfirmButton: false,
                                timer: 1500
                            })
                                .then(() => {
                                    dispatch(getAccount())
                                    props.onClose()
                                })
                        }
                    })
            }
            else if (props.type === 'edit') {
                dispatch(editAccount({
                    ...formValue,
                    id: props.data?.id
                }))
                    .unwrap()
                    .then((res: any) => {
                        if (res.status >= 400) {
                            Swal.fire(
                                'Edit Account Failed!',
                                `${res.data.message || Object.values(res.data.errors)}`,
                                'error'
                            )
                        } else {
                            Swal.fire({
                                icon: 'success',
                                title: 'Account Successfully Edited',
                                showConfirmButton: false,
                                timer: 1500
                            })
                                .then(() => {
                                    dispatch(getAccount())
                                    props.onClose()
                                })
                        }
                    })
            }
        }
    }

    return (
        <div className='formaccount'>
            <h3>{props.type === 'create' ? "Create" : props.type === 'edit' ? "Edit" : ""} Account <X onClick={props.onClose} /></h3>
            <form onSubmit={handleSubmit}>
                <TextField id="select" className='inp'
                    label="Company"
                    required
                    name='companyId'
                    placeholder='Choose company'
                    onChange={handleChange}
                    value={formValue.companyId}
                    variant="outlined"
                    size='small' select
                    disabled={Roles("tracker_account_filtered") ? true : false}
                >
                    {
                        listCompany.map((res: any, i: number) =>
                            <MenuItem key={i} value={res.id}>{res.name}</MenuItem>
                        )
                    }
                </TextField>
                <TextField
                    className='inp'
                    name='firstName'
                    onChange={handleChange}
                    value={formValue.firstName}
                    variant="outlined"
                    type='text'
                    label="First Name"
                    size='small'
                    required
                />
                <TextField
                    className='inp'
                    name='lastName'
                    onChange={handleChange}
                    value={formValue.lastName}
                    variant="outlined"
                    type='text'
                    label="Last Name"
                    size='small'
                    required
                />
                {props.type === 'create' &&
                    <>
                        <TextField
                            className='inp'
                            name='email'
                            onChange={handleChange}
                            value={formValue.handleChange}
                            variant="outlined"
                            type='email'
                            label="Email"
                            size='small'
                            required
                        />
                        <TextField
                            className='inp'
                            name='password'
                            onChange={handleChange}
                            value={formValue.password}
                            type={showPass ? 'text' : 'password'}
                            variant="outlined"
                            label="Password"
                            size='small'
                            required
                            inputProps={{
                                minLength: 8,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showPass ?
                                            <Eye onClick={() => setShowPass(!showPass)} />
                                            :
                                            <EyeOff onClick={() => setShowPass(!showPass)} />
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            className='inp'
                            name='passwordConfirm'
                            helperText={(<span className='helper-text'>Password must be at least 8 characters long, includes upper and lower case letter, at least 1 number, and not matching to the email used</span>)}
                            onChange={handleChange}
                            value={formValue.passwordConfirm}
                            type={showRePass ? 'text' : 'password'}
                            variant="outlined"
                            label="Password Confirmation"
                            size='small'
                            required
                            inputProps={{
                                minLength: 8,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showRePass ?
                                            <Eye onClick={() => setShowRePass(!showRePass)} />
                                            :
                                            <EyeOff onClick={() => setShowRePass(!showRePass)} />
                                        }
                                    </InputAdornment>
                                )
                            }}
                        />
                    </>
                }
                <p>USER ROLE</p>
                <div className='roles'>
                    {Roles("tracker_account_option_csca") && (
                        <FormControlLabel
                            control={
                                <Checkbox color="primary" checked={formValue.roles.csca} name='csca' onChange={handleRoles} />
                            }
                            label="CSCA"
                            disabled={!isCscaEnabled}
                        />
                    )}
                    {Roles("tracker_account_option_cscf") && (
                        <FormControlLabel
                            control={
                                <Checkbox color="primary" checked={formValue.roles.cscf} name='cscf' onChange={handleRoles} />
                            }
                            label="Master Admin Fielder"
                        />
                    )}
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.companyAdmin} name='companyAdmin' onChange={handleRoles} />
                        }
                        label="Company Admin"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.engineer} name='engineer' onChange={handleRoles} />
                        }
                        label="Planner/Fielding Request"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.fieldingManager} name='fieldingManager' onChange={handleRoles} />
                        }
                        label="Assign Fielding"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.fielder} name='fielder' onChange={handleRoles} />
                        }
                        label="Fielder"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.projectManager} name='projectManager' onChange={handleRoles} />
                        }
                        label="Project Manager"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.projectTeam} name='projectTeam' onChange={handleRoles} />
                        }
                        label="Project Team"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.calcSpecManager} name='calcSpecManager' onChange={handleRoles} />
                        }
                        label="Loading Management"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.calcSpec} name='calcSpec' onChange={handleRoles} />
                        }
                        label="Loading Processing"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.loadingQc} name='loadingQc' onChange={handleRoles} />
                        }
                        label="Loading QC"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.fieldingRequestSimple} name='fieldingRequestSimple' onChange={handleRoles} />
                        }
                        label="Fielding Request Simple"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={formValue.roles.fielderSimple} name='fielderSimple' onChange={handleRoles} />
                        }
                        label="Fielder Simple"
                    />
                </div>
                <div className="wrapbutton">
                    <Button type='submit' className="save" variant='outlined'><Save /> Save</Button>
                </div>
            </form>
        </div>
    )
}


