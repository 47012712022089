import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import Project from "../components/project/Project";
import JobNumber from "../components/jobnumber/JobNumber";
import JobDetail from "../components/jobdetail/JobDetail";
import JobDetailMenu from "../components/jobdetailmenu/JobDetailMenu";
import { setSidetabActive } from "../sideTab.reducer";

function SideTab(props: any) {
  const dispatch = useDispatch<any>();

  const { projectIdActive, jobNumberIdActive, sideTabActive, detailMenu } = useSelector((state: RootState) => state.sideTab);

  const [lastSideTabActive, setLastSideTabActive] = useState({ project: true, jobNumber: false, jobDetail: false, jobDetailMenu: false })

  useEffect(() => {
    if (!Boolean(sideTabActive.project) && !Boolean(projectIdActive.id)) {
      dispatch(setSidetabActive({ project: true, jobNumber: true, jobDetail: true, jobDetailMenu: true }))
    }
  }, [sideTabActive]);

  useEffect(() => {
    if (
      lastSideTabActive.project !== sideTabActive.project ||
      lastSideTabActive.jobNumber !== sideTabActive.jobNumber ||
      lastSideTabActive.jobDetail !== sideTabActive.jobDetail ||
      lastSideTabActive.jobDetailMenu !== sideTabActive.jobDetailMenu
    ) {
      const sideTabActiveArr = [
        sideTabActive.project,
        sideTabActive.jobNumber,
        sideTabActive.jobDetail,
        sideTabActive.jobDetailMenu
      ]
      const lastSideTabActiveArr = [
        lastSideTabActive.project,
        lastSideTabActive.jobNumber,
        lastSideTabActive.jobDetail,
        lastSideTabActive.jobDetailMenu
      ]
      const openedTab = sideTabActiveArr.filter((d: any) => d === true).length;
      const openedTabLast = lastSideTabActiveArr.filter((d: any) => d === true).length;
      if (openedTab > openedTabLast) {
        if (openedTab > 2) {
          const openedTabIndex = sideTabActiveArr.findIndex((d: any) => d === true);
          const openedTabIndexLast = lastSideTabActiveArr.findIndex((d: any) => d === true);
          const openedTabIndexReverse = 4 - sideTabActiveArr.reverse().findIndex((d: any) => d === true);
          const openedTabIndexReverseLast = 4 - lastSideTabActiveArr.reverse().findIndex((d: any) => d === true);
          const closeCountLeft = openedTabIndexLast - openedTabIndex
          const closeCountRight = openedTabIndexReverse - openedTabIndexReverseLast
          let newTabActiveArr = [...sideTabActiveArr]
          if (closeCountLeft > 0) {
            for (let i = 0; i < closeCountLeft; i++) {
              for (let o = newTabActiveArr.length - 1; o >= 0; o--) {
                if (newTabActiveArr[o] === true) {
                  newTabActiveArr[o] = false
                  break;
                }
              }
            }
          }
          if (closeCountRight > 0) {
            for (let i = 0; i < closeCountRight; i++) {
              for (let o = 0; o < newTabActiveArr.length; o++) {
                if (newTabActiveArr[o] === true) {
                  newTabActiveArr[o] = false
                  break;
                }
              }
            }
          }
          const newSideTabActive = {
            project: newTabActiveArr[0],
            jobNumber: newTabActiveArr[1],
            jobDetail: newTabActiveArr[2],
            jobDetailMenu: newTabActiveArr[3]
          }
          setLastSideTabActive(newSideTabActive);
          dispatch(setSidetabActive(newSideTabActive));
        }
        else {
          setLastSideTabActive(sideTabActive);
        }
      }
      else {
        setLastSideTabActive(sideTabActive);
      }
    }
  }, [sideTabActive])

  return (
    <div className="sidetab">
      <div className="tab-header-container">
        <div className={`tab-header ${sideTabActive?.project ? 'active' : ''}`} onClick={() => dispatch(setSidetabActive({ ...sideTabActive, project: !sideTabActive?.project }))}>
          <span>
            Project
          </span>{" "}
        </div>
        <div className={`tab-header ${sideTabActive?.jobNumber && Boolean(projectIdActive.id) ? 'active' : ''}`} onClick={() => dispatch(setSidetabActive({ ...sideTabActive, jobNumber: !sideTabActive?.jobNumber }))}>
          <span>
            Job Number
          </span>{" "}
        </div>
        <div className={`tab-header ${(sideTabActive?.jobDetail && Boolean(jobNumberIdActive.id)) ? 'active' : ''}`} onClick={() => dispatch(setSidetabActive({ ...sideTabActive, jobDetail: !sideTabActive?.jobDetail }))}>
          <span>
            Job Detail
          </span>{" "}
        </div>
        {Boolean(detailMenu.id) && (
          <div className={`tab-header ${(sideTabActive?.jobDetailMenu && Boolean(jobNumberIdActive.id)) ? 'active' : ''}`} onClick={() => dispatch(setSidetabActive({ ...sideTabActive, jobDetailMenu: !sideTabActive?.jobDetailMenu }))}>
            <span>
              {detailMenu.label}
            </span>{" "}
          </div>
        )}
      </div>
      <div className="tab-content-container">
        {sideTabActive?.project && <Project />}
        {(sideTabActive?.jobNumber && Boolean(projectIdActive.id)) && <JobNumber />}
        {(sideTabActive?.jobDetail && Boolean(jobNumberIdActive.id)) && <JobDetail />}
        {(sideTabActive?.jobDetailMenu && Boolean(jobNumberIdActive.id)) && <JobDetailMenu />}
      </div>
    </div>
  );
}

export default SideTab;
