import { Button, LinearProgress } from "@material-ui/core";
import { X, Check, Save } from "react-feather";
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import axios from "axios";
import { getPoleDetails } from "../poleInformation.api";
import { baseUrl } from "../../../state";

function ImageModalEditor(props: any) {

    const dispatch = useDispatch<any>()

    const tui: any = useRef(null)

    const { poleFieldingRequest } = useSelector((state: RootState) => state.poleInformation);

    const [imageBlob, setImageBlob] = useState("")
    const [progressText, setProgressText] = useState("")
    const [progressValue, setProgressValue] = useState(0)

    useEffect(() => {
        setProgressText("Loading image")
        setProgressValue(0)
        fetch(props.file.filePath.includes('.com') ? props.file.filePath : baseUrl + "/api/Global/Download?path=" + props.file.filePath)
            .then((res) => res.blob())
            .then((response) => {
                const dataType = response.type;
                const binaryData = [];
                binaryData.push(response);
                let href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
                setImageBlob(href)
                setProgressText("")
            })
            .catch(() => {
            })
    }, [])

    const b64toBlob = (b64Data: any, contentType: any, sliceSize: any) => {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const upload = async (file: any) => {
        setProgressText("Uploading image 0%")
        let data = new FormData();
        data.append("poleId", poleFieldingRequest?.payload?.id)
        data.append("", file, props.file.fileName)
        try {
            const response = await axios.post(`${baseUrl}/api/Pole/UploadImageAttachment`, data, {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                    setProgressText("Uploading image " + percentCompleted + "%")
                    setProgressValue(percentCompleted)
                }
            });
            dispatch(getPoleDetails(response.data.data[0].poleId))
            setProgressText("")
            setProgressValue(0)
            props.handleCancel()
        } catch (error: any) {
            console.log(error);
            setProgressText("")
            setProgressValue(0)
        }
    }

    const handleSave = (() => {
        let instance = tui.current.getInstance()
        let dataUrl = instance.toDataURL()
        var block = dataUrl.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var blob = b64toBlob(realData, contentType, 512);
        upload(blob)
    })

    return (
        <div className="image-editor-modal-content">
            {imageBlob !== ""
                ? (
                    <>
                        <div className="hider"></div>
                        <ImageEditor
                            ref={tui}
                            includeUI={{
                                loadImage: {
                                    path: imageBlob,
                                    name: 'SampleImage',
                                },
                                menu: ['shape', 'text', 'draw'],
                                initMenu: 'filter',
                                uiSize: {
                                    width: '100%',
                                    height: '85vh',
                                },
                                menuBarPosition: 'bottom',
                            }}
                        />
                    </>
                )
                : (
                    <div style={{ height: "80vh", backgroundColor: "#303030" }}></div>
                )}
            <div className="footer">
                <div className="progress">
                    {progressText !== "" && (
                        <>
                            <span>{progressText}</span>
                            <LinearProgress className="progress-bar" variant="determinate" value={progressValue} />
                        </>
                    )}
                </div>
                <div className="wrapbutton">
                    <Button variant="contained" className="cancel" onClick={props.handleCancel}><X /> Cancel</Button>
                    <Button variant="contained" className="save" onClick={handleSave} disabled={progressText !== ""}><Save /> Save</Button>
                </div>
            </div>
        </div>
    );
}

export default ImageModalEditor;