
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { baseUrl } from '../../state';

export const getProfile = createAsyncThunk("profile/getProfile", async (key: any) => {
    try {
        const response = await axios.get(`${baseUrl}/api/Account/GetProfile`, {
            params: {
                searchKey: key,
            },
        });
        return response.data;
    }
    catch (error: any) {
        return error.response
    }
});

export const linkToFP = createAsyncThunk("profile/linkToFP", async (linkUfEmail: any) => {
    try {
        const response = await axios.put(`${baseUrl}/newapi/Account/UFLinkToFP?email=${linkUfEmail}&token=${localStorage.getItem('token')}`);
        return response.data;
    }
    catch (error: any) {
        return error.response
    }
});

export const unlink = createAsyncThunk("profile/unlink", async () => {
    try {
        const response = await axios.put(`${baseUrl}/newapi/Account/Unlink?token=${localStorage.getItem('token')}`);
        return response.data;
    }
    catch (error: any) {
        return error.response
    }
});