import { useEffect } from 'react';
import '../src/assets/styles/App.scss';
import {
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import LoginPage from './features/login/containers/LoginPage';
import Dashboard from './features/dashboard/containers/Dashboard';
import Disclaimer from './features/additionalPages/Disclaimer';
import PrivacyPolicy from './features/additionalPages/PrivacyPolicy';
import Eula from './features/additionalPages/Eula';
import TermsOfUse from './features/additionalPages/TermsOfUse';
import Account from './features/tracker/account/container/Account';
import Company from './features/tracker/company/container/Company';
import PoleLoading from './features/tracker/poleLoading/container/PoleLoading';
import Jpa from './features/tracker/jpa/container/Jpa';
import Mre from './features/tracker/mre/container/Mre';
import AssignFielding from './features/assignFielding/container/AssignFielding';
import Profile from './features/profile/container/Profile';
import JobNumberPdf from './features/additionalPages/JobNumberPdf';
import Tcp from './features/tracker/tcp/container/Tcp';
import FieldingRequest from './features/tracker/fieldingRequest/container/FieldingRequest';
import Welcome from './features/welcome/Welcome';

function App() {

  const navigate = useNavigate()

  const checkAccess = () => {
    if (localStorage.getItem('auth') && localStorage.getItem('token')) {
      let born = localStorage.getItem("born")
      if (born) {
        if (born === '1') {
        }
        else {
          let bornValue = parseInt(born)
          let relative = (new Date().getTime()) - bornValue
          if (relative > 86400000) {
            localStorage.removeItem('companyIdSelected')
            localStorage.removeItem('id')
            localStorage.removeItem('auth')
            localStorage.removeItem('roles')
            localStorage.removeItem('company')
            localStorage.removeItem('email')
            localStorage.removeItem('token')
            localStorage.removeItem('companyId')
            localStorage.removeItem('born')
            return false
          }
        }
      }
      else {
        localStorage.removeItem('companyIdSelected')
        localStorage.removeItem('id')
        localStorage.removeItem('auth')
        localStorage.removeItem('roles')
        localStorage.removeItem('company')
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        localStorage.removeItem('companyId')
        localStorage.removeItem('born')
        return false
      }
      return true
    }
    return false
  }

  const loginAccess = checkAccess()

  useEffect(() => {
    if (window.location.host === "office.utilityfielding.com") {
      window.open("https://utilityfielding.com", "_self")
    }
    if (!loginAccess) {
      navigate('/')
    }
  }, [])

  return (
    <Routes>
      <Route path='/' Component={Welcome} />
      <Route path='/login' Component={LoginPage} />
      <Route path='/home' Component={Dashboard} />
      <Route path='/account' Component={Account} />
      <Route path='/company' Component={Company} />
      <Route path='/assign-fielding' Component={AssignFielding} />
      <Route path='/profile/*' Component={Profile} />
      <Route path='/poleloading' Component={PoleLoading} />
      <Route path='/jpa' Component={Jpa} />
      <Route path='/mre' Component={Mre} />
      <Route path='/tcp' Component={Tcp} />
      <Route path='/fieldingrequest' Component={FieldingRequest} />
      <Route path='/disclaimer' Component={Disclaimer} />
      <Route path='/privacy-policy' Component={PrivacyPolicy} />
      <Route path='/eula' Component={Eula} />
      <Route path='/terms-of-use' Component={TermsOfUse} />
      <Route path='/jobnumberpdf' Component={JobNumberPdf} />
      <Route path='*' Component={() => <p>404 | Page not found.!</p>} />
    </Routes>
  );
}

export default App;
