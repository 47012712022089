import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
  Polyline,
  Polygon,
  InfoWindow,
  useLoadScript
} from "@react-google-maps/api";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeasureMarker, getMidspanDetails, getMidSpanFieldingRequest, getPoleDetails, getPoleFieldingRequest, getPoleInventory, getPoleLoadingRequest, moveMeasureMarker, postMidspanFieldingRequest, postPoleFieldingRequest } from "../../poleInformation/poleInformation.api";
import moment from "moment";
import Swal from "sweetalert2";
import { Crop, MapPin, Move, Search } from "react-feather";
import { Button, ButtonGroup, Card, CardContent, Tooltip } from "@material-ui/core";
import { handleRightButton, setNotePath, setNotePathModal, setNoteShape, setNoteShapeModal, setCenter, setNoteSymbolModal, setSelectedIdMarkers, setZoom, setNotePoint, setNotePointModal, setSearchResult, setLeftButton, setIsDragSelectActive, setMeasureMarkerData } from "../dashboard.reducer";
import { clearPoleDetails, setEditPoleInfo, setListMeasureMarker, setListMidspanFieldingRequest, setListPoleFieldingRequest, setListPoleInventory, setListPoleLoadingRequest, setMidspanDetail, setPoleFieldingRequest, setPoleOcalcId, setPoleType } from "../../poleInformation/poleInformation.reducer";
import midspan from "../../../assets/icons/midspan.png";
import white_blue_pole_loading from "../../../assets/icons/white_blue_pole_loading.png";
import white_yellow_pole_loading from "../../../assets/icons/white_yellow_pole_loading.png";
import white_red_pole_loading from "../../../assets/icons/white_red_pole_loading.png";
import pole_color_red from "../../../assets/icons/pole_color_red.png";
import pole_color_blue from "../../../assets/icons/pole_color_blue.png";
import pole_color_white from "../../../assets/icons/pole_color_white.png";
import pole_color_green from "../../../assets/icons/pole_color_green.png";
import pole_color_black from "../../../assets/icons/pole_color_black.png";
import pole_color_yellow from "../../../assets/icons/pole_color_yellow.png";
import pole_color_purple from "../../../assets/icons/pole_color_purple.png";
import pole_color_orange from "../../../assets/icons/pole_color_orange.png";
import pole_color_gray from "../../../assets/icons/pole_color_gray.png";
import pole_color_brown from "../../../assets/icons/pole_color_brown.png";
import pole_color_cyan from "../../../assets/icons/pole_color_cyan.png";
import pole_type_full from "../../../assets/icons/pole_type_full.png";
import pole_type_support from "../../../assets/icons/pole_type_support.png";
import pole_type_reference from "../../../assets/icons/pole_type_reference.png";
import pole_full from "../../../assets/icons/pole_full.png";
import pole_unfill from "../../../assets/icons/pole_unfill.png";
import pole_transparent from "../../../assets/icons/pole_transparent.png";
import point_red from "../../../assets/icons/point_red.png";
import point_blue from "../../../assets/icons/point_blue.png";
import point_white from "../../../assets/icons/point_white.png";
import point_green from "../../../assets/icons/point_green.png";
import point_black from "../../../assets/icons/point_black.png";
import point_yellow from "../../../assets/icons/point_yellow.png";
import point_purple from "../../../assets/icons/point_purple.png";
import point_orange from "../../../assets/icons/point_orange.png";
import point_gray from "../../../assets/icons/point_gray.png";
import point_brown from "../../../assets/icons/point_brown.png";
import treeSmall from "../../../assets/icons/treeSmall.png";
import anchorSmall from "../../../assets/icons/anchorSmall.png";
import target from "../../../assets/icons/target.png";
import inventory from "../../../assets/icons/inventory.png";
import measure_marker_start from "../../../assets/icons/measure_marker_start.png";
import measure_marker_end from "../../../assets/icons/measure_marker_end.png";
import { RootState } from "../../../app/store";
import { editNoteLocation, getFieldingRequestId, getJobNumber, getJobNumberDetails, getNoteListByJobNumberId, postNoteMarker, removePolesToLoadingRequest } from "../../sideTab/sideTab.api";
import { handleFieldingIcon, handleJobNumberIdActive, handleLayerActive, handleNoteIcon, handleNotesActive, setDetailMenu, setFieldingIdList, setJobDetail, setJobIdList, setNoteList } from "../../sideTab/sideTab.reducer";
import { editPoleLocation } from "../dashboard.api";
import ModalTemplate from "../../../shared/ModalTemplate";
import NotePointModal from "../../sideTab/components/jobdetailmenu/NotePointModal";
import NoteShapeModal from "../../sideTab/components/jobdetailmenu/NoteShapeModal";
import NotePathModal from "../../sideTab/components/jobdetailmenu/NotePathModal";
import NoteSymbolModal from "../../sideTab/components/jobdetailmenu/NoteSymbolModal";
import { Straighten } from "@material-ui/icons";
import mergeImages from 'merge-images'
import FormFieldingPath from "../../sideTab/components/jobdetailmenu/FormFieldingPath";
import FormFieldingShape from "../../sideTab/components/jobdetailmenu/FormFieldingShape";
import IndexMapNotifications from "./IndexMapNotification";
import MeasureMarkerModal from "../../sideTab/components/jobdetailmenu/MeasureMarkerModal";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";

const apikey = "AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs";
const libraries: Libraries = ['places'];

const mapStyles = {
  height: "80vh",
  width: "100%",
};

function IndexMap() {
  const dispatch = useDispatch<any>();

  const { listPoleFieldingRequest, listMidspanFieldingRequest, listPoleLoadingRequest, listPoleInventory, listPolePplx, isShowPoleFieldingRequest, isShowPoleLoadingRequest, poleDetails, midspanDetail, listMarkerFieldingRequest, listMeasureMarker } = useSelector((state: RootState) => state.poleInformation);
  const { fieldingIcon, fieldingRequestIdActive, loadingRequestIdActive, noteIcon, noteList, jobNumberIdActive, isLayerActive, jobIdList, fieldingIdList, noteEnums, projectIdActive, statusJobNumber, statusNotes, inventoryActive } = useSelector((state: RootState) => state.sideTab);
  const { rightButton, noteShape, notePath, center, zoom, noteToShow, selectedIdMarkers, isDragSelectActive, notePointModal, noteShapeModal, notePathModal, noteSymbolModal, searchResult, measureMarkerData } = useSelector((state: RootState) => state.dashboard);

  const [dataAutocomplete, setdataAutocomplete] = useState<any>({});
  const [isMovePoleActive, setIsMovePoleActive] = useState(false);
  const [infoSpan, setInfoSpan] = useState<any>(null);
  const [selectPosStart, setSelectPosStart] = useState<any>({})
  const [selectPosEnd, setSelectPosEnd] = useState<any>({})
  const [selectLastIsMouseClicked, setSelectLastIsMouseClicked] = useState(false)
  const [isShowSearch, setIsShowSearch] = useState(false)
  const [searchCoordinate, setSearchCoordinate] = useState("")
  const [mapType, setMapType] = useState("hybrid")
  const [isMeasureActive, setIsMeasureActive] = useState(false)
  const [measurePoint, setmeasurePoint] = useState<any>([])
  // const [libraries] = useState(['places']);
  const [icons, setIcons] = useState<any>([])
  const [mouseCoordinate, setMouseCoordinate] = useState<any>({})
  const [isLineFielding, setIsLineFIelding] = useState(false)
  const [isAreaFielding, setIsAreaFielding] = useState(false)
  const [closedMeasureIds, setClosedMeasureIds] = useState<any>([])
  const [measureMarkerTotalLength, setMeasureMarkerTotalLength] = useState(0)

  useEffect(() => {
    let isMounted = true;
    generateIcon().then((data) => {
      if (isMounted) {
        setIcons(data);
      }
    });
    dispatch(setSearchResult({}))
    return () => { isMounted = false };
  }, [])

  const generateIcon = async () => {
    const colorList = [pole_color_white, pole_color_red, pole_color_blue, pole_color_white, pole_color_green, pole_color_black, pole_color_yellow, pole_color_purple, pole_color_orange, pole_color_gray, pole_color_brown, pole_color_cyan]
    const fullList = [pole_transparent, pole_full]
    const unFillList = [pole_transparent, pole_unfill]
    const typeList = [pole_type_full, pole_type_support, pole_type_reference]
    let result: any = []
    for (let c = 0; c < colorList.length; c++) {
      result = [...result, []]
      for (let f = 0; f < fullList.length; f++) {
        result[c] = [...result[c], []]
        for (let u = 0; u < unFillList.length; u++) {
          result[c][f] = [...result[c][f], []]
          for (let t = 0; t < typeList.length; t++) {
            let r = await new Promise(resolve => {
              mergeImages([colorList[c], fullList[f], unFillList[u], typeList[t]])
                .then((b64: any) => {
                  resolve(b64)
                })
            })
            result[c][f][u] = [...result[c][f][u], r]
          }
        }
      }
    }
    return result;
    // setIcons(result)
  }

  const handleClick = (coord: any) => {
    let lat = coord.latLng.lat()
    let lng = coord.latLng.lng()
    if (Boolean(measureMarkerData.editData?.id)) {
      dispatch(moveMeasureMarker({
        measurementMarkerId: measureMarkerData.editData.id,
        latitude: String(lat),
        longitude: String(lng),
        userId: localStorage.getItem("id")
      }))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire("Move measure marker failed!", res?.data?.message);
          }
          else {
            dispatch(getMeasureMarker(measureMarkerData.editData.fieldingRequestId))
              .unwrap()
              .then(async (response: any) => {
                dispatch(setListMeasureMarker(listMeasureMarker?.filter((item: any) => item?.fieldingRequestId !== measureMarkerData.editData.fieldingRequestId).concat(response.data)));
                dispatch(setMeasureMarkerData({}))
              })
          }
        })
    }
    else if (fieldingIcon !== null && fieldingIcon !== -1) {
      if (isLayerActive.label === "aerial") {
        if (fieldingIcon === 4) {
          setIsLineFIelding(true)
          dispatch(setNotePath(notePath.concat([{ lat: lat, lng: lng }])))
        }
        else if (fieldingIcon === 5) {
          setIsAreaFielding(true)
          dispatch(setNoteShape(noteShape.concat([{ lat: lat, lng: lng }])))
        }
        else if (fieldingIcon === 6) {
          saveMeasureMarker(lat, lng)
        }
        else {
          savePoleFieldingRequest(coord);
        }
      }
      else if (isLayerActive.label === "loading") {
        savePoleLoadingRequest(coord)
      }
      else if (isLayerActive.label === "inventory") {
        savePoleInventory(coord)
      }
    }
    else if (noteIcon !== null) {
      setIsLineFIelding(false)
      if (noteIcon === 0 || noteIcon === 1) {
        handleAddNote(lat, lng)
      }
      if (noteIcon === 2) {
        dispatch(setNoteShape(noteShape.concat([{ lat: lat, lng: lng }])))
      }
      if (noteIcon === 3) {
        dispatch(setNotePath(notePath.concat([{ lat: lat, lng: lng }])))
      }
      if (noteIcon === 4) {
        dispatch(setNotePoint({ lat: lat, lng: lng }))
        dispatch(setNotePointModal({ status: true }))
      }
    }
    else if (isMeasureActive) {
      setmeasurePoint([...measurePoint, {
        lat: lat,
        lng: lng
      }])
    }
    else {
      dispatch(setCenter({ latitude: coord.latLng.lat(), longitude: coord.latLng.lng() }))
      dispatch(setSearchResult({}))
    }
  };

  const handleAddNote = (lat: any, lng: any) => {
    dispatch(postNoteMarker({
      markerImportType: 0,
      markerType: noteIcon,
      name: "",
      description: "",
      style: "",
      latitude: String(lat),
      longitude: String(lng),
      lineData: "",
      jobNumberId: jobNumberIdActive.id,
      userId: localStorage.getItem("id"),
      recipientEmails: []
    }))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Add symbol note failed!");
        }
        else {
          dispatch(getNoteListByJobNumberId(jobNumberIdActive.id))
            .unwrap()
            .then((res: any) => {
              let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== jobNumberIdActive.id)
              dispatch(setNoteList(noteListNew.concat(res.data)))
            })
        }
      })
  }

  const handleClickMarker = (e: any, res: any) => {
    if (isMeasureActive) {
      setmeasurePoint([
        ...measurePoint,
        {
          lat: parseFloat(res.latitude),
          lng: parseFloat(res.longitude)
        }
      ])
    }
    else if (fieldingIcon === -1 && isLayerActive.label === "loading") {
      dispatch(removePolesToLoadingRequest({
        "ocalcId": loadingRequestIdActive.id,
        "poleIds": [res.id]
      }))
        .unwrap()
        .then(() => {
          dispatch(getPoleLoadingRequest(loadingRequestIdActive.id))
            .unwrap()
            .then((response: any) => {
              let poleLoadingRequest: any = listPoleLoadingRequest?.filter((item: any) => item?.ocalcId != loadingRequestIdActive.id);
              dispatch(setListPoleLoadingRequest(poleLoadingRequest.concat(response.data)));
            })
        })
    }
    else if (noteIcon === null) {
      if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
        if (!selectedIdMarkers.includes(res.id)) {
          dispatch(setSelectedIdMarkers([...selectedIdMarkers, res.id]));
        }
        else {
          dispatch(setSelectedIdMarkers(selectedIdMarkers.filter((item: any) => item !== res.id)));
        }
      } else {
        dispatch(clearPoleDetails())
        dispatch(setEditPoleInfo(false))
        dispatch(setMidspanDetail({}))
        dispatch(getPoleDetails(res.id))
        dispatch(setCenter({ latitude: res.latitude, longitude: res.longitude }))
        dispatch(setPoleFieldingRequest({ payload: res }));
        if (!rightButton) {
          dispatch(handleRightButton());
        }
      }
    }
  };

  const handleClickMidspan = (e: any, res: any) => {
    if (noteIcon === null) {
      if (e.domEvent.ctrlKey || e.domEvent.metaKey) {
      }
      else {
        dispatch(clearPoleDetails())
        dispatch(getMidspanDetails(res.id))
        dispatch(setPoleType("MIDSPAN"))
        dispatch(setEditPoleInfo(false))
        dispatch(setCenter({ latitude: res.latitude, longitude: res.longitude }))
        if (!rightButton) {
          dispatch(handleRightButton());
        }
      }
    }
  };

  const savePoleFieldingRequest = async (coord: any) => {
    const geocoder = new google.maps.Geocoder()
    let response = await geocoder.geocode({ location: coord.latLng })
    let streetName = response?.results?.length > 0 ? response?.results[0]?.formatted_address : ""
    if (fieldingIcon === 3) {
      dispatch(postMidspanFieldingRequest({
        fieldingRequestId: fieldingRequestIdActive.id,
        latitude: coord.latLng.lat().toString(),
        longitude: coord.latLng.lng().toString(),
        street: streetName,
        sequence: "",
        span: "",
        notes: "",
        clearanceHeightList: []
      }))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire(
              "Add midspan failed!",
              "",
              "error"
            );
          } else {
            dispatch(getMidSpanFieldingRequest(fieldingRequestIdActive.id))
              .unwrap()
              .then((response: any) => {
                dispatch(setListMidspanFieldingRequest(listMidspanFieldingRequest.concat(response.data)));
              })
          }
        });
    }
    else {
      let dateNow = moment(new Date()).format("MM/DD/yyyy");
      let body = {
        fieldingRequestId: fieldingRequestIdActive.id,
        poleNumber: "",
        poleYear: "",
        poleCondition: 0,
        poleHeight: 18,
        poleClass: 13,
        poleSpecies: 0,
        type: fieldingIcon,
        color: 0,
        latitude: coord.latLng.lat().toString(),
        longitude: coord.latLng.lng().toString(),
        clearance: "",
        groundCircumference: "",
        informationDateString: dateNow,
        treeHeight: "",
        osmose: "",
        street: streetName,
        vapTerminal: "",
        otherNumber: "",
        // poleStamp: false,
        notes: "",
        // isRadioAntenna: false,
        poleSequence: "",
        userId: localStorage.getItem("id"),
        fieldingType: 2
      };

      dispatch(postPoleFieldingRequest(body))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire(
              "Add pole failed!",
              "",
              "error"
            );
          } else {
            dispatch(getPoleFieldingRequest(fieldingRequestIdActive.id))
              .unwrap()
              .then((response: any) => {
                dispatch(setListPoleFieldingRequest(listPoleFieldingRequest.concat(response.data)));
                dispatch(getJobNumber({
                  id: projectIdActive?.id,
                  status: statusJobNumber
                }))
                  .unwrap()
                  .then((response: any) => {
                    let jobnumberActive = response.data.filter((data: any) => data.id === jobNumberIdActive.id)
                    dispatch(getJobNumberDetails(jobnumberActive[0].id));
                    dispatch(setJobDetail(jobnumberActive[0]));
                  })
                // if (listFieldingRequest.length === array.length) {
                //   dispatch(setJobIdList(jobIdList.concat(fielding.jobNumberId)))
                // }
              })
          }
        });
    }
  };

  const saveMeasureMarker = (lat: number, long: number) => {
    dispatch(setMeasureMarkerData({
      newModalStatus: true,
      new: {
        latitude: lat,
        longitude: long
      }
    }))
  }

  const savePoleLoadingRequest = async (coord: any) => {
    const geocoder = new google.maps.Geocoder()
    let response = await geocoder.geocode({ location: coord.latLng })
    let streetName = response?.results?.length > 0 ? response?.results[0]?.formatted_address : ""
    let dateNow = moment(new Date()).format("MM/DD/yyyy");
    let body = {
      ocalcId: loadingRequestIdActive.id,
      fieldingRequestId: null,
      poleNumber: "",
      poleYear: "",
      poleCondition: 0,
      poleHeight: 18,
      poleClass: 13,
      poleSpecies: 0,
      type: fieldingIcon,
      color: 0,
      latitude: coord.latLng.lat().toString(),
      longitude: coord.latLng.lng().toString(),
      clearance: "",
      groundCircumference: "",
      informationDateString: dateNow,
      treeHeight: "",
      osmose: "",
      street: streetName,
      vapTerminal: "",
      otherNumber: "",
      // poleStamp: false,
      notes: "",
      // isRadioAntenna: false,
      poleSequence: "",
      userId: localStorage.getItem("id")
    };
    dispatch(postPoleFieldingRequest(body))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire(
            "Add pole failed!",
            "",
            "error"
          );
        } else {
          dispatch(getPoleLoadingRequest(loadingRequestIdActive.id))
            .unwrap()
            .then((response: any) => {
              dispatch(setListPoleLoadingRequest(listPoleLoadingRequest.concat(response.data)));
            })
        }
      });
  };

  const savePoleInventory = async (coord: any) => {
    const geocoder = new google.maps.Geocoder()
    let response = await geocoder.geocode({ location: coord.latLng })
    let streetName = response?.results?.length > 0 ? response?.results[0]?.formatted_address : ""
    let dateNow = moment(new Date()).format("MM/DD/yyyy");
    let body = {
      inventoryId: inventoryActive.id,
      ocalcId: null,
      fieldingRequestId: null,
      poleNumber: "",
      poleYear: "",
      poleCondition: 0,
      poleHeight: 18,
      poleClass: 13,
      poleSpecies: 0,
      type: fieldingIcon,
      color: 0,
      latitude: coord.latLng.lat().toString(),
      longitude: coord.latLng.lng().toString(),
      clearance: "",
      groundCircumference: "",
      informationDateString: dateNow,
      treeHeight: "",
      osmose: "",
      street: streetName,
      vapTerminal: "",
      otherNumber: "",
      // poleStamp: false,
      notes: "",
      // isRadioAntenna: false,
      poleSequence: "",
      userId: localStorage.getItem("id")
    };
    dispatch(postPoleFieldingRequest(body))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire(
            "Add pole failed!",
            "",
            "error"
          );
        } else {
          dispatch(getPoleInventory(inventoryActive.id))
            .unwrap()
            .then((response: any) => {
              dispatch(setListPoleInventory(listPoleInventory.concat(response.data)));
            })
        }
      });
  };

  const onLoad = (ref: any) => setdataAutocomplete(ref);

  const hanldePlacesChanged = () => {
    if (dataAutocomplete !== null) {
      dispatch(setSearchResult({
        lat: dataAutocomplete.getPlace().geometry.location.lat(),
        lng: dataAutocomplete.getPlace().geometry.location.lng(),
      }))
      dispatch(setCenter({
        latitude: dataAutocomplete.getPlace().geometry.location.lat(),
        longitude: dataAutocomplete.getPlace().geometry.location.lng(),
      }));
      dispatch(setZoom(13));
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
    setIsShowSearch(false)
  };

  const handleSearchCoordinate = (e: any) => {
    e.preventDefault()
    let lat = searchCoordinate.split(',')[0]
    let lng = searchCoordinate.split(',')[1]
    dispatch(setSearchResult({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    }))
    dispatch(setCenter({
      latitude: lat,
      longitude: lng,
    }));
    dispatch(setZoom(16));
    setIsShowSearch(false)
  }

  let countMaps = 0;
  const onLoadMap = (map: any) => {
    map.addListener("zoom_changed", () => {
      dispatch(setCenter({ latitude: map.center.lat(), longitude: map.center.lng() }))
      dispatch(setZoom(map.zoom));
    });

    map.addListener("bounds_changed", () => {
      countMaps++;
      if (countMaps % 10 == 0) {
        dispatch(setCenter({ latitude: map.center.lat(), longitude: map.center.lng() }))
        countMaps = 0;
      }
    });

    map.streetView.addListener("visible_changed", () => {
      dispatch(setLeftButton(!(map.streetView.visible)))
    })

  };

  const handleMovePole = () => {
    if (!isMovePoleActive) {
      Swal.fire({
        icon: "success",
        title: "Move pole or notes activated",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setIsMovePoleActive(!isMovePoleActive)
  }

  const poleDragEnd = (pole: any, coord: any) => {
    dispatch(setCenter({ latitude: coord.latLng.lat(), longitude: coord.latLng.lng() }))
    let listPoleFieldingRequestNew: any = []
    let newSpanDirectionData = pole.spanDirectionList.map((spanDirection: any) => {
      let lineData = JSON.parse(spanDirection.lineData)
      lineData[0] = {
        lat: coord.latLng.lat(),
        lng: coord.latLng.lng(),
      }
      return {
        ...spanDirection,
        lineData: JSON.stringify(lineData)
      }
    })
    listPoleFieldingRequest.map((value: any) => {
      let poleNew: any = JSON.parse(JSON.stringify(value))
      if (poleNew.id === pole.id) {
        poleNew.latitude = String(coord.latLng.lat())
        poleNew.longitude = String(coord.latLng.lng())
        poleNew.spanDirectionList = newSpanDirectionData
      }
      listPoleFieldingRequestNew.push(poleNew)
    })
    dispatch(setListPoleFieldingRequest(listPoleFieldingRequestNew))
    let data = {
      poleId: pole.id,
      latitude: String(coord.latLng.lat()),
      longitude: String(coord.latLng.lng()),
      spanDirectionList: newSpanDirectionData
    }
    dispatch(editPoleLocation(data))
      .unwrap()
      .then((res: any) => {
        if (res?.status >= 400) {
          alert(res.statusText)
        }
      })
      .catch((err: any) => {
        alert(err)
      })
  }

  const noteDragEnd = (note: any, coord: any) => {
    dispatch(setCenter({ latitude: coord.latLng.lat(), longitude: coord.latLng.lng() }))
    let noteListNew: any = []
    noteList.map((value: any) => {
      let noteNew: any = JSON.parse(JSON.stringify(value))
      if (noteNew.id === note.id) {
        noteNew.latitude = String(coord.latLng.lat())
        noteNew.longitude = String(coord.latLng.lng())
      }
      noteListNew.push(noteNew)
    })
    dispatch(setNoteList(noteListNew))
    let data = {
      id: note.id,
      latitude: String(coord.latLng.lat()),
      longitude: String(coord.latLng.lng())
    }
    dispatch(editNoteLocation(data))
      .unwrap()
      .then((res: any) => {
        if (res?.status >= 400) {
          alert(res.statusText)
        }
      })
      .catch((err: any) => {
        alert(err)
      })
  }

  const handleClickPoly = (note: any) => {
    setIsAreaFielding(false)
    dispatch(setNoteShapeModal({ status: true, data: note }))
  }

  const handleCLickLine = (note: any) => {
    setIsLineFIelding(false)
    dispatch(setNotePathModal({ status: true, data: note }))
  }

  const handleCLickLineFielding = (note: any) => {
    setIsLineFIelding(true)
    dispatch(setNotePathModal({ status: true, data: note }))
  }

  const handleClickArea = (note: any) => {
    setIsAreaFielding(true)
    dispatch(setNoteShapeModal({ status: true, data: note }))
  }

  const handleCLickSymbol = (note: any) => {
    dispatch(setNoteSymbolModal({ status: true, data: note }))
  }

  const handleCLickPoint = (note: any) => {
    dispatch(setNotePointModal({ status: true, data: note }))
  }

  const handleSpanHoverIn = (e: any, length: string) => {
    setInfoSpan({
      text: length,
      position: { "lat": e.latLng.lat(), "lng": e.latLng.lng() }
    })
  }

  const handleSpanHoverOut = (e: any) => {
    setInfoSpan(null)
  }

  const handleMouseMove = (e: any) => {
    let mouseLat = e.latLng.lat()
    let mouseLng = e.latLng.lng()
    if (isDragSelectActive) {
      if (e.domEvent.buttons === 1) {
        if (selectLastIsMouseClicked === false) {
          setSelectLastIsMouseClicked(true)
          setSelectPosStart({ "lat": mouseLat, "lng": mouseLng })
        }
        else {
          setSelectPosEnd({ "lat": mouseLat, "lng": mouseLng })
          dispatch(setSelectedIdMarkers(selectedIdMarkers.concat(listPoleFieldingRequest
            .filter((pole: any) => {
              let lat = parseFloat(pole?.latitude)
              let lng = parseFloat(pole?.longitude)
              if (
                (lat < selectPosStart?.lat && lng > selectPosStart?.lng) &&
                (lat > mouseLat && lng < mouseLng) &&
                (pole.isActive === true)
              ) {
                if (!selectedIdMarkers.includes(pole?.id)) {
                  return true
                }
              }
              return false
            })
            .map((pole: any) => pole.id))))
          setMeasureMarkerTotalLength(
            listMeasureMarker
              .filter((pole: any) => {
                let lat = parseFloat(pole?.latitude)
                let lng = parseFloat(pole?.longitude)
                if (
                  (lat < selectPosStart?.lat && lng > selectPosStart?.lng) &&
                  (lat > mouseLat && lng < mouseLng)
                ) {
                  return true
                }
                return false
              })
              .reduce((total: number, pole: any) => total += parseInt(pole.length), 0)
          )
        }
      }
    }
    else if (isMeasureActive) {
      setMouseCoordinate({ lat: mouseLat, lng: mouseLng })
    }
  }

  const handleAddPointNote = () => {
    if (jobNumberIdActive?.id) {
      console.log(jobNumberIdActive?.id)
      dispatch(setDetailMenu({ id: 2, label: "Notes", icon: true }))
      dispatch(getNoteListByJobNumberId(jobNumberIdActive.id))
        .unwrap()
        .then((res: any) => {
          let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== jobNumberIdActive.id)
          dispatch(setNoteList(noteListNew.concat(res.data)))
        })
      dispatch(handleNotesActive());
      if (!isLayerActive.status) {
        if (!jobIdList.includes(jobNumberIdActive.id)) {
          dispatch(setJobIdList([...jobIdList, jobNumberIdActive.id]))
        }
        dispatch(getPoleFieldingRequest(jobNumberIdActive.id))
          .unwrap()
          .then((res: any) => {
            let idList: any = []
            res.data.map((pole: any) => {
              idList.push(pole.id)
            })
            let poleFieldingRequest: any = listPoleFieldingRequest?.filter((item: any) => !idList.includes(item?.id));
            dispatch(setListPoleFieldingRequest(poleFieldingRequest.concat(res.data)));
          })
        dispatch(getFieldingRequestId(jobNumberIdActive.id))
          .unwrap()
          .then((res: any) => {
            let idList: any = []
            res.data.map((fielding: any) => {
              if (!idList.includes(fielding.id)) {
                idList.push(fielding.id)
              }
            })
            let fieldingIdListNew: any = fieldingIdList?.filter((item: any) => !idList.includes(item));
            dispatch(setFieldingIdList(fieldingIdListNew.concat(idList)))
          })
      }
      dispatch(handleLayerActive({ ...isLayerActive, note: true, status: !isLayerActive.status }))
      dispatch(handleNoteIcon(4))
      dispatch(handleFieldingIcon(null))
    }
    else {
      console.log("no jobnumber id selected")
      Swal.fire("No selected job number!", "", "error");
    }
  }

  const handleAddMeasure = () => {
    if (isMeasureActive) {
      setmeasurePoint([])
    }
    setIsMeasureActive(!isMeasureActive)
  }

  const rad = (x: number) => {
    return x * Math.PI / 180;
  };

  const getDistance = (p1: any, p2: any) => {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return (d * 3.2808).toFixed(2);
  };

  const noteColorId2Name = (id: number) => {
    let markerColors = noteEnums.markerColors
    let markerColor = markerColors.filter((item: any) => { return item.value === id })
    return markerColor[0]?.display
  }

  const handleButtonSelectMultiple = (status: boolean) => {
    dispatch(setSelectedIdMarkers([]))
    dispatch(setIsDragSelectActive(status))
    setSelectLastIsMouseClicked(false)
    setSelectPosStart({})
    setSelectPosEnd({})
    setMeasureMarkerTotalLength(0)
  }

  const pointNoteColor: any = {
    Red: point_red,
    Blue: point_blue,
    White: point_white,
    Green: point_green,
    Black: point_black,
    Yellow: point_yellow,
    Purple: point_purple,
    Orange: point_orange,
    Gray: point_gray,
    Brown: point_brown
  }

  // fixing memory leak when LoadScript
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apikey,
    libraries: libraries,
  });
  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div>
      <ModalTemplate onOpen={notePointModal.status} component={() => <NotePointModal />} />
      <ModalTemplate onOpen={noteShapeModal.status && (!isAreaFielding)} component={() => <NoteShapeModal />} />
      <ModalTemplate onOpen={noteShapeModal.status && (isAreaFielding)} component={() => <FormFieldingShape />} />
      <ModalTemplate onOpen={notePathModal.status && (!isLineFielding)} component={() => <NotePathModal />} />
      <ModalTemplate onOpen={notePathModal.status && (isLineFielding)} component={() => <FormFieldingPath />} />
      <ModalTemplate onOpen={noteSymbolModal.status} component={() => <NoteSymbolModal />} />
      <ModalTemplate onOpen={measureMarkerData.newModalStatus || measureMarkerData.editModalStatus} component={() => <MeasureMarkerModal />} />
      {/*<LoadScript*/}
      {/*  googleMapsApiKey={apikey}*/}
      {/*  //@ts-ignore*/}
      {/*  libraries={libraries}*/}
      {/*>*/}
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={zoom}
        tilt={0}
        center={Boolean(center.latitude) && Boolean(center.longitude) ? { lat: parseFloat(String(center.latitude)), lng: parseFloat(String(center.longitude)) } : undefined}
        onClick={handleClick}
        onLoad={onLoadMap}
        options={{
          draggable: !isDragSelectActive,
          mapTypeId: mapType
        }}
        onMouseMove={handleMouseMove}
      >
        {isShowPoleFieldingRequest && listPoleFieldingRequest.map((res: any, i: number) => {
          if (icons.length === 0) {
            return null
          }
          if (!res.isActive) {
            return null
          }
          return (
            <>
              <Marker
                key={i + (listPoleFieldingRequest.length * 3)}
                position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                onClick={(e) => { dispatch(setPoleType("POLE")); handleClickMarker(e, res) }}
                icon={icons[res.color >= 12 ? 0 : res.color][res.fieldingStatus === 2 ? 1 : 0][res.isMandatoryDataFilled === false && res.fieldingStatus === 2 ? 1 : 0][res.type]}
                animation={selectedIdMarkers.includes(res.id) ? 1 : 0}
                draggable={isMovePoleActive}
                onDragEnd={(coord) => poleDragEnd(res, coord)}
                title={
                  "seq: " + res?.poleSequence + "\n" +
                  (res?.poleNumber || "")
                }
              />
              {
                res.spanDirectionList.map((line: any, lineIndex: number) => {
                  if (line.color !== 'string') {
                    return (
                      <Polyline
                        key={lineIndex}
                        path={JSON.parse(line.lineData)}
                        onMouseOver={(e: any) => handleSpanHoverIn(e, line.length)}
                        onMouseOut={handleSpanHoverOut}
                        options={{
                          strokeColor: line.color,
                          strokeOpacity: 0.8,
                          strokeWeight: 7,
                          //@ts-ignore
                          fillColor: '#FF0000',
                          fillOpacity: 0.35,
                          clickable: true,
                          draggable: false,
                          editable: false,
                          visible: true,
                          radius: 30000,
                          paths: line.data,
                          zIndex: 1
                        }}
                      />
                    )
                  }
                })
              }
            </>
          );
        })}
        {isShowPoleLoadingRequest && listPoleLoadingRequest.map((res: any, i: number) => {
          const colorList = [white_blue_pole_loading, white_yellow_pole_loading, white_red_pole_loading]
          return (
            <>
              <Marker
                key={i}
                position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                onClick={(e) => { dispatch(setPoleType("LOADING")); handleClickMarker(e, res); dispatch(setPoleOcalcId(res.ocalcId)) }}
                icon={colorList[res.type]}
                animation={selectedIdMarkers.includes(res.id) ? 1 : 0}
                draggable={isMovePoleActive}
                onDragEnd={(coord) => poleDragEnd(res, coord)}
                title={
                  "seq: " + res?.poleSequence + "\n" +
                  (res?.poleNumber || "")
                }
              />
              {
                res.spanDirectionList.map((line: any, lineIndex: number) => {
                  if (line.color !== 'string') {
                    return (
                      <Polyline
                        key={lineIndex}
                        path={JSON.parse(line.lineData)}
                        onMouseOver={(e: any) => handleSpanHoverIn(e, line.length)}
                        onMouseOut={handleSpanHoverOut}
                        options={{
                          strokeColor: line.color,
                          strokeOpacity: 0.8,
                          strokeWeight: 7,
                          //@ts-ignore
                          fillColor: '#FF0000',
                          fillOpacity: 0.35,
                          clickable: true,
                          draggable: false,
                          editable: false,
                          visible: true,
                          radius: 30000,
                          paths: line.data,
                          zIndex: 1
                        }}
                      />
                    )
                  }
                })
              }
            </>
          );
        })}
        {listMidspanFieldingRequest.map((res: any, i: number) => {
          return (
            <>
              <Marker
                key={i}
                position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                clickable={!isMeasureActive}
                onClick={(e) => handleClickMidspan(e, res)}
                icon={midspan}
                animation={selectedIdMarkers.includes(res.id) ? 1 : 0}
                draggable={isMovePoleActive}
                onDragEnd={(coord) => poleDragEnd(res, coord)}
                title={"M" + res?.sequence}
              />
              {
                (res.span !== '' ? JSON.parse(res?.span) : []).map((line: any, lineIndex: number) => {
                  if (line.color !== 'string') {
                    return (
                      <Polyline
                        key={lineIndex}
                        path={typeof (line.lineData) === "string" ? JSON.parse(line.lineData) : line.lineData}
                        onMouseOver={(e: any) => handleSpanHoverIn(e, line.length)}
                        onMouseOut={handleSpanHoverOut}
                        options={{
                          strokeColor: line.color,
                          strokeOpacity: 0.8,
                          strokeWeight: 7,
                          //@ts-ignore
                          fillColor: '#FF0000',
                          fillOpacity: 0.35,
                          clickable: true,
                          draggable: false,
                          editable: false,
                          visible: true,
                          radius: 30000,
                          paths: line.data,
                          zIndex: 1
                        }}
                      />
                    )
                  }
                })
              }
            </>
          );
        })}
        {listMarkerFieldingRequest.filter((d: any) => d.isActive).map((marker: any) => (
          (marker.type === 1
            ? (
              <Polygon
                key={marker.id}
                path={JSON.parse(marker?.lineData)}
                options={{ strokeOpacity: 0.8, strokeColor: noteColorId2Name(marker.markerColor), fillColor: noteColorId2Name(marker.markerColor), clickable: !isMeasureActive }}
                onClick={() => handleClickArea(marker)}
              />
            )
            : null
          )
        ))}
        {listMarkerFieldingRequest.filter((d: any) => d.isActive).map((marker: any) => (
          (marker.type === 0
            ? (
              <Polyline
                key={marker.id}
                path={JSON.parse(marker?.lineData)}
                //@ts-ignore
                options={{ strokeOpacity: 0.8, strokeColor: noteColorId2Name(marker.markerColor), fillColor: noteColorId2Name(marker.markerColor) }}
                onClick={() => handleCLickLineFielding(marker)}
              />
            )
            : null
          )
        ))}
        {measureMarkerData.start?.latitude && (
          <Marker
            position={{ lat: measureMarkerData.start?.latitude, lng: measureMarkerData.start?.longitude }}
            icon={measure_marker_start}
          />
        )}
        {measureMarkerData.end?.latitude && (
          <Fragment>
            <Marker
              position={{ lat: measureMarkerData.end?.latitude, lng: measureMarkerData.end?.longitude }}
              icon={measure_marker_end}
            />
            <Polyline
              path={[
                { lat: measureMarkerData.start?.latitude, lng: measureMarkerData.start?.longitude },
                { lat: measureMarkerData.end?.latitude, lng: measureMarkerData.end?.longitude }
              ]}
            />
            <InfoWindow
              onLoad={onLoad}
              position={{
                lat: (measureMarkerData.start?.latitude + measureMarkerData.end?.latitude) / 2,
                lng: (measureMarkerData.start?.longitude + measureMarkerData.end?.longitude) / 2
              }}
            >
              <div style={{ background: 'white', color: 'black' }}>
                {getDistance({ lat: measureMarkerData.start?.latitude, lng: measureMarkerData.start?.longitude }, { lat: measureMarkerData.end?.latitude, lng: measureMarkerData.end?.longitude })} Feet
              </div>
            </InfoWindow>
          </Fragment>
        )}
        {listMeasureMarker.map((marker: any) => {
          let startMarker: any = {}
          let endMarker: any = listMeasureMarker.filter((m: any) => m.startMeasurementMarkerId === marker.id)[0]
          if (Boolean(marker.startMeasurementMarkerId)) {
            startMarker = listMeasureMarker.filter((m: any) => m.id === marker.startMeasurementMarkerId)[0]
          }
          return (
            <Fragment key={marker.id}>
              <Marker
                position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                icon={Boolean(marker.startMeasurementMarkerId) ? measure_marker_end : measure_marker_start}
                animation={((measureMarkerData.editData?.id === marker.id) && (measureMarkerData.editModalStatus === false)) ? 1 : 0}
                onClick={() => dispatch(setMeasureMarkerData({
                  editModalStatus: true,
                  editData: marker,
                  distance: Boolean(startMarker?.id)
                    ? getDistance(
                      { lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) },
                      { lat: parseFloat(startMarker.latitude), lng: parseFloat(startMarker.longitude) }
                    )
                    : (Boolean(endMarker?.id)
                      ? getDistance(
                        { lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) },
                        { lat: parseFloat(endMarker.latitude), lng: parseFloat(endMarker.longitude) }
                      )
                      : 0
                    )
                }))}
                title={
                  "seq: " + marker?.sequence
                }
              />
              {Boolean(startMarker?.id) && (
                <Fragment>
                  <Polyline
                    path={[
                      { lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) },
                      { lat: parseFloat(startMarker?.latitude), lng: parseFloat(startMarker?.longitude) }
                    ]}
                    onClick={() => setClosedMeasureIds(closedMeasureIds.filter((i: String) => i !== marker.id))}
                  />
                  {!closedMeasureIds.includes(marker.id) && (
                    <InfoWindow
                      onLoad={onLoad}
                      position={{
                        lat: (parseFloat(marker.latitude) + parseFloat(startMarker.latitude)) / 2,
                        lng: (parseFloat(marker.longitude) + parseFloat(startMarker.longitude)) / 2
                      }}
                      onCloseClick={() => setClosedMeasureIds([...closedMeasureIds, marker.id])}
                    >
                      <div style={{ background: 'white', color: 'black' }}>
                        {getDistance({ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }, { lat: parseFloat(startMarker.latitude), lng: parseFloat(startMarker.longitude) })} Feet
                      </div>
                    </InfoWindow>
                  )}
                </Fragment>
              )}
            </Fragment>
          )
        })}

        {listPolePplx.map((res: any, i: number) => {
          const colorList = [white_blue_pole_loading, white_yellow_pole_loading, white_red_pole_loading]
          return (
            <>
              <Marker
                key={i}
                position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                onClick={(e) => { dispatch(setPoleType("PPLX")); handleClickMarker(e, res) }}
                icon={colorList[res.type]}
                animation={selectedIdMarkers.includes(res.id) ? 1 : 0}
                draggable={isMovePoleActive}
                onDragEnd={(coord) => poleDragEnd(res, coord)}
                title={
                  "seq: " + res?.poleSequence + "\n" +
                  (res?.poleNumber || "")
                }
              />
              {
                res.spanDirectionList.map((line: any, lineIndex: number) => {
                  if (line.color !== 'string') {
                    return (
                      <Polyline
                        key={lineIndex}
                        path={JSON.parse(line.lineData)}
                        onMouseOver={(e: any) => handleSpanHoverIn(e, line.length)}
                        onMouseOut={handleSpanHoverOut}
                        options={{
                          strokeColor: line.color,
                          strokeOpacity: 0.8,
                          strokeWeight: 7,
                          //@ts-ignore
                          fillColor: '#FF0000',
                          fillOpacity: 0.35,
                          clickable: true,
                          draggable: false,
                          editable: false,
                          visible: true,
                          radius: 30000,
                          paths: line.data,
                          zIndex: 1
                        }}
                      />
                    )
                  }
                })
              }
            </>
          );
        })}
        {listPoleInventory.map((res: any, i: number) => {
          return (
            <>
              <Marker
                key={res.id}
                position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                onClick={(e) => { dispatch(setPoleType("INVENTORY")); handleClickMarker(e, res) }}
                icon={inventory}
                title={
                  "seq: " + res?.poleSequence + "\n" +
                  (res?.poleNumber || "")
                }
              />
              {
                res.spanDirectionList.map((line: any, lineIndex: number) => {
                  if (line.color !== 'string') {
                    return (
                      <Polyline
                        key={lineIndex}
                        path={JSON.parse(line.lineData)}
                        onMouseOver={(e: any) => handleSpanHoverIn(e, line.length)}
                        onMouseOut={handleSpanHoverOut}
                        options={{
                          strokeColor: line.color,
                          strokeOpacity: 0.8,
                          strokeWeight: 7,
                          //@ts-ignore
                          fillColor: '#FF0000',
                          fillOpacity: 0.35,
                          clickable: true,
                          draggable: false,
                          editable: false,
                          visible: true,
                          radius: 30000,
                          paths: line.data,
                          zIndex: 1
                        }}
                      />
                    )
                  }
                })
              }
            </>
          );
        })}
        {
          infoSpan !== null ?
            <InfoWindow
              onLoad={onLoad}
              position={infoSpan.position}
            >
              <div style={{ background: 'white', color: 'black' }}>
                {infoSpan.text}
              </div>
            </InfoWindow>
            : null
        }

        {rightButton && (
          <Marker
            position={{ lat: parseFloat(poleDetails.latitude || midspanDetail.latitude), lng: parseFloat(poleDetails.longitude || midspanDetail.longitude) }}
            clickable={false}
            icon={target}
          />
        )}

        <Polygon
          path={noteShape}
          options={{ strokeOpacity: 0.8, strokeColor: "black", fillColor: "black", clickable: !isMeasureActive }}
        />

        <Polyline
          path={notePath}
          //@ts-ignore
          options={{ strokeOpacity: 0.8, strokeColor: (isLineFielding ? "yellow" : "blue"), fillColor: (isLineFielding ? "yellow" : "blue") }}
        />

        {noteList.map((note: any, i: number) => (
          (note.markerType === 2 && noteToShow.includes(2) && (statusNotes === note.status)
            ? (
              <Polygon
                key={i}
                path={JSON.parse(note.lineData)}
                options={{ strokeOpacity: 0.8, strokeColor: noteColorId2Name(note.markerColor), fillColor: noteColorId2Name(note.markerColor), clickable: !isMeasureActive }}
                onClick={(e) => handleClickPoly(note)}
              />
            )
            : null
          )
        ))}

        {noteList.map((note: any, i: number) => (
          (note.markerType === 0 && noteToShow.includes(0) && (statusNotes === note.status)
            ? (
              <Marker
                position={{ lat: parseFloat(note.latitude), lng: parseFloat(note.longitude) }}
                icon={anchorSmall}
                onClick={() => handleCLickSymbol(note)}
                draggable={isMovePoleActive}
                onDragEnd={(coord) => noteDragEnd(note, coord)}
              />
            )
            : (note.markerType === 1 && noteToShow.includes(1) && (statusNotes === note.status)
              ? (
                <Marker
                  position={{ lat: parseFloat(note.latitude), lng: parseFloat(note.longitude) }}
                  icon={treeSmall}
                  onClick={() => handleCLickSymbol(note)}
                  draggable={isMovePoleActive}
                  onDragEnd={(coord) => noteDragEnd(note, coord)}
                />
              )
              : (note.markerType === 3 && noteToShow.includes(3) && (statusNotes === note.status)
                ? (
                  <Polyline
                    key={i}
                    path={JSON.parse(note.lineData)}
                    //@ts-ignore
                    options={{ strokeOpacity: 0.8, strokeColor: noteColorId2Name(note.markerColor), fillColor: noteColorId2Name(note.markerColor) }}
                    onClick={() => handleCLickLine(note)}
                  />
                )
                : (note.markerType === 4 && noteToShow.includes(4) && (statusNotes === note.status)
                  ? (
                    <Marker
                      position={{ lat: parseFloat(note.latitude), lng: parseFloat(note.longitude) }}
                      icon={note.markerColor === 0 ? pointNoteColor.Red : pointNoteColor[noteColorId2Name(note.markerColor)]}
                      onClick={() => handleCLickPoint(note)}
                      draggable={isMovePoleActive}
                      onDragEnd={(coord) => noteDragEnd(note, coord)}
                    />
                  )
                  : null
                )
              )
            )
          )))}

        {selectPosStart.lat && (
          <Polygon
            path={[
              { "lat": selectPosStart?.lat, "lng": selectPosStart?.lng },
              { "lat": selectPosStart?.lat, "lng": selectPosEnd?.lng },
              { "lat": selectPosEnd?.lat, "lng": selectPosEnd?.lng },
              { "lat": selectPosEnd?.lat, "lng": selectPosStart?.lng }
            ]}
          />
        )}
        {selectPosStart.lat && (
          <InfoWindow
            onLoad={onLoad}
            position={{
              lat: selectPosStart?.lat + ((selectPosEnd?.lat - selectPosStart?.lat) / 2),
              lng: selectPosStart?.lng + ((selectPosEnd?.lng - selectPosStart?.lng) / 2)
            }}
          >
            <div style={{ background: 'white', color: 'black' }}>
              Total measure marker length: {measureMarkerTotalLength}ft
            </div>
          </InfoWindow>
        )}

        {measurePoint.map((mesure: any, i: number) => {
          if (i < measurePoint.length - 1) {
            return (
              <Fragment key={i}>
                <Polyline
                  path={measurePoint}
                  options={{ strokeColor: "red" }}
                />
                <InfoWindow
                  onLoad={onLoad}
                  position={{
                    lat: (measurePoint[i].lat + measurePoint[i + 1].lat) / 2,
                    lng: (measurePoint[i].lng + measurePoint[i + 1].lng) / 2
                  }}
                >
                  <div style={{ background: 'white', color: 'black' }}>
                    {getDistance(measurePoint[i], measurePoint[i + 1])} Feet
                  </div>
                </InfoWindow>
              </Fragment>
            )
          }
          else {
            return (
              <Fragment key={i}>
                <Polyline
                  path={[measurePoint[i], mouseCoordinate]}
                  onClick={handleClick}
                />
                <InfoWindow
                  onLoad={onLoad}
                  position={{
                    lat: (measurePoint[i].lat + mouseCoordinate.lat) / 2,
                    lng: (measurePoint[i].lng + mouseCoordinate.lng) / 2
                  }}
                >
                  <div style={{ background: 'white', color: 'black' }}>
                    {getDistance(measurePoint[i], mouseCoordinate)} Feet
                  </div>
                </InfoWindow>
              </Fragment>
            )
          }
        })}

        {searchResult.lat && (
          <Marker
            position={searchResult}
          />
        )}

        <div className="search-container">
          {isShowSearch
            ? (
              <div>
                <Autocomplete onLoad={onLoad} onPlaceChanged={hanldePlacesChanged}>
                  <input
                    className="input-location"
                    type="text"
                    placeholder="Search location"
                  />
                </Autocomplete>
                <form className="input-coordinate" onSubmit={handleSearchCoordinate}>
                  <input
                    type="text"
                    placeholder="Search coordinate"
                    onChange={(e) => setSearchCoordinate(e.target.value)}
                  />
                  <button type="submit">
                    <Search />
                  </button>
                </form>
              </div>
            )
            : (
              <div className="map_button" onClick={() => setIsShowSearch(true)}>
                <Search />
              </div>
            )}
        </div>

        {isLayerActive.label === "loading" && (<div className="float-notification">
          <Card variant="outlined" color="warning">
            <CardContent>
              Press CTRL while click on the pole(s) on the map to select individual poles from existing fielding request.<br />
              Click Select Multiple Pole button and mark the area around the poles to select poles within a region.<br />
              To add new pole(s), select pole icon in fielding request menu.<br />
              To deselect pole, press CTRL while clicking on the intended pole.
            </CardContent>
          </Card>
        </div>
        )}

        <Tooltip title="Move pole or notes, click here to start move pole or notes, click again to end moving">
          <div className={isMovePoleActive ? "map_button move_pole map_button_active" : "map_button move_pole"} onClick={handleMovePole}>
            <Move />
          </div>
        </Tooltip>
        <Tooltip title="Add postnote, click to start add postnote, put a postnote on the map, click here again to stop adding">
          <div className={"map_button select_pole"} onClick={handleAddPointNote}>
            <MapPin />
          </div>
        </Tooltip>
        <Tooltip title="Measurement">
          <div className={isMeasureActive ? "map_button measure map_button_active" : "map_button measure"} onClick={handleAddMeasure}>
            <Straighten />
          </div>
        </Tooltip>
        {(isDragSelectActive || selectPosStart.lat || selectedIdMarkers.length > 0)
          ? (
            <Button variant="contained" className={"clear_select"} onClick={() => handleButtonSelectMultiple(false)}>Clear Selection</Button>
          )
          : (
            <Tooltip title="Select Multiple Pole">
              <div className={"map_button drag_select"} onClick={() => handleButtonSelectMultiple(true)}>
                <Crop />
              </div>
            </Tooltip>
          )}
        <ButtonGroup className="maptype-button">
          <Button className={mapType === "roadmap" ? "active" : ""} onClick={() => setMapType("roadmap")}>Map</Button>
          <Button className={mapType === "hybrid" ? "active" : ""} onClick={() => setMapType("hybrid")}>Satellite</Button>
        </ButtonGroup>
        {/* <IndexMapNotifications /> */}
      </GoogleMap>
      {/*</LoadScript>*/}
    </div >
  );
}

export default IndexMap;
