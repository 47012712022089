import { Button, Checkbox, FormControlLabel, LinearProgress, MenuItem, Modal, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { Trash, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import { baseUrl } from "../../../../state";
import { deleteFieldingRequestAttachment, deleteFieldingRequestAttachmentMultiple, generateAllDocument, getFieldingRequestAttachmentList, getFieldingRequestAttachmentLogs } from "../../sideTab.api";
import AerialFieldingImageModal from "./AerialFieldingImageModal";

export default function AerialFIeldingFiles(props: any) {

    const dispatch = useDispatch<any>()

    const { fieldingAttachmentList } = useSelector((state: RootState) => state.sideTab);
    const { aerialFielding } = useSelector((state: RootState) => state.assignFielding);

    const [localFiles, setLocalFiles] = useState<any>(false);
    const [localFilesNew, setLocalFilesNew] = useState(false)
    const [downloadImages, setDownloadImages] = useState<any>({
        isDownloading: false,
        progressValue: 70
    })
    const [attachmentSelectedIndex, setAttachmentSelectedIndex] = useState<any>(null)
    const [sortBy, setSortBy] = useState("Taken date")
    const [selectedIds, setSelectedIds] = useState<any>([])

    useEffect(() => {
        dispatch(getFieldingRequestAttachmentLogs({
            fieldingRequestId: aerialFielding.id,
            skip: 0,
            take: 10
        }))
    }, [])

    useEffect(() => {
        if (localFilesNew) {
            setLocalFilesNew(false)
            localFiles.map(async (localFile: any, localFileIndex: number) => {
                let data = new FormData();
                data.append("fieldingRequestId", aerialFielding.id)
                data.append("userId", localStorage.getItem('id') || "")
                data.append("", localFile.file)
                try {
                    await axios.post(`${baseUrl}/api/FieldingRequest/UploadAttachments`, data, {
                        onUploadProgress: (progressEvent) => {
                            var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                            let newLocalFiles: any = []
                            let found = false
                            localFiles.map((localFileProgress: any) => {
                                if (localFileProgress.name === localFile.name) {
                                    localFileProgress.uploadProgress = percentCompleted
                                    found = true
                                }
                                newLocalFiles.push(localFileProgress)
                            })
                            if (!found) {
                                newLocalFiles.push(localFile)
                            }
                            setLocalFiles(newLocalFiles)
                        }
                    });
                    Swal.fire({
                        icon: "success",
                        title: localFile.name + " successfully uploaded",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    setLocalFiles([])
                    dispatch(getFieldingRequestAttachmentList(aerialFielding.id))
                } catch (error: any) {
                    Swal.fire({
                        icon: "error",
                        title: "Failed uploading " + localFile.name,
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    console.log(error);
                    setLocalFiles([])
                }
            })
        }
    }, [localFilesNew, localFiles])

    const onChangeFile = (e: any) => {
        var files = Array.from(e.target.files);
        if (files) {
            let newLocalFiles: any = []
            let newLocalFilesUploaded: any = []
            files.map((file: any) => {
                newLocalFiles.push({ file: file, name: file.name, uploadProgress: 0 })
                newLocalFilesUploaded.push(false)
            })
            setLocalFiles(newLocalFiles)
            setLocalFilesNew(true)
        }
    }

    const handleDownloadAll = () => {
        setDownloadImages({
            isDownloading: true,
            progressValue: 0
        })
        dispatch(generateAllDocument(aerialFielding.id))
            .unwrap()
            .then((res: any) => {
                let arr = res.split("/")
                axios({
                    url: res,
                    method: "GET",
                    responseType: "blob",
                    onDownloadProgress: (progressEvent) => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0));
                        setDownloadImages({
                            isDownloading: true,
                            progressValue: percentCompleted
                        })
                    }
                })
                    .then(response => {
                        const downloadLink = document.createElement('a')
                        downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
                        downloadLink.setAttribute('download', arr[arr.length - 1])
                        document.body.appendChild(downloadLink)
                        downloadLink.click()
                        downloadLink.remove()
                        setDownloadImages({
                            isDownloading: false,
                            progressValue: 0
                        })
                    })
            })
            .catch(() => {
                setDownloadImages({
                    isDownloading: false,
                    progressValue: 0
                })
            })
    }

    const handleDelete = (id: string) => {
        Swal.fire({
            title: 'Are you sure delete this document?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteFieldingRequestAttachment(id))
                    .unwrap()
                    .then((res: any) => {
                        if (res.status >= 400) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error delete document',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                icon: 'success',
                                title: 'Document successfully deleted',
                                showConfirmButton: false,
                                timer: 1500
                            })
                                .then(() => {
                                    dispatch(getFieldingRequestAttachmentList(aerialFielding.id))
                                })
                        }
                    })
            }
        })
    }

    const handleCheckAll = (e: any) => {
        if (e.target.checked) {
            setSelectedIds(fieldingAttachmentList.map((d: any) => d.id))
        }
        else {
            setSelectedIds([])
        }
    }

    const handleCheck = (e: any, id: string) => {
        if (e.target.checked) {
            setSelectedIds([...selectedIds, id])
        }
        else {
            setSelectedIds(selectedIds.filter((d: any) => d !== id))
        }
    }

    const handleDeleteMultiple = () => {
        Swal.fire({
            title: 'Are you sure delete this document?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteFieldingRequestAttachmentMultiple(selectedIds))
                    .unwrap()
                    .then((res: any) => {
                        if (res.status >= 400) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error delete document',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        } else {
                            Swal.fire({
                                icon: 'success',
                                title: 'Document successfully deleted',
                                showConfirmButton: false,
                                timer: 1500
                            })
                                .then(() => {
                                    setSelectedIds([])
                                    dispatch(getFieldingRequestAttachmentList(aerialFielding.id))
                                })
                        }
                    })
            }
        })
    }

    return (
        <div className='modalsketchlib file-list-modal'>
            <h3>
                Unsyncronized Picture
                <X onClick={props.onClose} />
            </h3>
            <input
                accept=".doc,.docx,.xml,.kml,.pdf,.pplx"
                style={{ display: 'none' }}
                id="button-file"
                type="file"
                multiple
                onChange={onChangeFile}
            />
            {localFiles.length > 0
                ? <label htmlFor="button-file">
                    <Button className='upload-btn' component="span" disabled>
                        Uploading {parseInt(localFiles.reduce((total: any, file: any) => { return (file.uploadProgress + total) / localFiles.length }, 0))}%
                    </Button>
                    <LinearProgress variant="determinate" value={localFiles.reduce((total: any, file: any) => { return (file.uploadProgress + total) / localFiles.length }, 0)} />
                </label>
                : <label htmlFor="button-file">
                    <Button className='upload-btn' component="span">
                        Upload
                    </Button>
                </label>
            }
            {fieldingAttachmentList.length > 0 && (
                <>
                    <Button className='upload-btn' component="span" onClick={handleDownloadAll} disabled={downloadImages.isDownloading}>
                        {downloadImages.isDownloading
                            ? "Downloading " + downloadImages.progressValue + "%"
                            : "Download All"
                        }
                    </Button>
                    {downloadImages.isDownloading && (
                        <LinearProgress style={{ width: "100%" }} variant="determinate" value={downloadImages.progressValue} />
                    )}
                    <div style={{ textAlign: "center" }}>
                        <Typography variant="caption">
                            Download WinRAR <a href="https://www.win-rar.com/download.html" target={"_blank"}>here</a> to open downloaded pictures file
                        </Typography>
                    </div>
                </>
            )}
            <Modal className="image-editor-modal" open={attachmentSelectedIndex !== null}>
                <AerialFieldingImageModal attachmentIndex={attachmentSelectedIndex} sortBy={sortBy} onClose={() => setAttachmentSelectedIndex(null)} />
            </Modal>
            <TextField value={sortBy} onChange={(e) => setSortBy(e.target.value)} select label="Sort by">
                <MenuItem value="Taken date">Taken date</MenuItem>
                <MenuItem value="Uploaded date">Uploaded date</MenuItem>
                <MenuItem value="Name">Name</MenuItem>
            </TextField>
            <br />
            <br />
            <div className="delete-header">
                <FormControlLabel
                    className="remember"
                    control={
                        <Checkbox checked={fieldingAttachmentList.length === selectedIds.length} onChange={handleCheckAll} />
                    }
                    label="Select all"
                />
                {selectedIds.length > 0 && (
                    <Button variant="contained" size="small" onClick={handleDeleteMultiple}>
                        Delete
                    </Button>
                )}
            </div>
            {sortBy === "Taken date" && (
                <div className="supporting-list">
                    {JSON.parse(JSON.stringify(fieldingAttachmentList))
                        .sort((a: any, b: any) => {
                            if ((new Date(a.detectedImageTakenDate).getTime()) < (new Date(b.detectedImageTakenDate).getTime())) {
                                return -1
                            }
                            if ((new Date(a.detectedImageTakenDate).getTime()) > (new Date(b.detectedImageTakenDate).getTime())) {
                                return 1
                            }
                            return 0
                        })
                        .map((attachment: any, index: number) => (
                            <div key={index} className="supporting-container" >
                                <div>
                                    <Checkbox checked={selectedIds.includes(attachment.id)} onChange={(e) => handleCheck(e, attachment.id)} />
                                    <a style={{ cursor: 'pointer' }} onClick={() => setAttachmentSelectedIndex(index)}>{attachment.fileName},</a>
                                    <a style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => setAttachmentSelectedIndex(index)}>date taken: {attachment.detectedImageTakenDate || '-'}</a>
                                    <a style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => setAttachmentSelectedIndex(index)}>date upload: {attachment.uploadDateString || '-'}</a>
                                </div>
                                <Trash onClick={() => handleDelete(attachment.id)} />
                            </div>
                        ))}
                </div>
            )}
            {sortBy === "Uploaded date" && (
                <div className="supporting-list">
                    {JSON.parse(JSON.stringify(fieldingAttachmentList))
                        .sort((a: any, b: any) => {
                            if ((new Date(a.uploadDateString).getTime()) < (new Date(b.uploadDateString).getTime())) {
                                return -1
                            }
                            if ((new Date(a.uploadDateString).getTime()) > (new Date(b.uploadDateString).getTime())) {
                                return 1
                            }
                            return 0
                        })
                        .map((attachment: any, index: number) => (
                            <div key={index} className="supporting-container" >
                                <div>
                                    <Checkbox checked={selectedIds.includes(attachment.id)} onChange={(e) => handleCheck(e, attachment.id)} />
                                    <a style={{ cursor: 'pointer' }} onClick={() => setAttachmentSelectedIndex(index)}>{attachment.fileName},</a>
                                    <a style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => setAttachmentSelectedIndex(index)}>date taken: {attachment.detectedImageTakenDate || '-'}</a>
                                    <a style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => setAttachmentSelectedIndex(index)}>date upload: {attachment.uploadDateString || '-'}</a>
                                </div>
                                <Trash onClick={() => handleDelete(attachment.id)} />
                            </div>
                        ))}
                </div>
            )}
            {sortBy === "Name" && (
                <div className="supporting-list">
                    {JSON.parse(JSON.stringify(fieldingAttachmentList))
                        .sort((a: any, b: any) => {
                            if (a.fileName < b.fileName) {
                                return -1
                            }
                            if (a.fileName > b.fileName) {
                                return 1
                            }
                            return 0
                        })
                        .map((attachment: any, index: number) => (
                            <div key={index} className="supporting-container" >
                                <div>
                                    <a style={{ cursor: 'pointer' }} onClick={() => setAttachmentSelectedIndex(index)}>{attachment.fileName},</a>
                                    <a style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => setAttachmentSelectedIndex(index)}>date taken: {attachment.detectedImageTakenDate || '-'}</a>
                                    <a style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => setAttachmentSelectedIndex(index)}>date upload: {attachment.uploadDateString || '-'}</a>
                                </div>
                                <Trash onClick={() => handleDelete(attachment.id)} />
                            </div>
                        ))}
                </div>
            )}
        </div>
    )
}