import { Backdrop, IconButton, Modal, Tooltip } from "@material-ui/core";
import axios from "axios";
import { useState } from "react";
import { Download, X } from "react-feather";

export default function ImagePreview(props: any) {

    const [downloadImages, setDownloadImages] = useState<any>({
        isDownloading: false,
        progressValue: 70
    })

    const handleDownload = () => {
        setDownloadImages({
            isDownloading: true,
            progressValue: 0
        })
        axios({
            url: props.src,
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0));
                setDownloadImages({
                    isDownloading: true,
                    progressValue: percentCompleted
                })
                console.log(percentCompleted)
            }
        })
            .then(response => {
                const downloadLink = document.createElement('a')
                downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
                downloadLink.setAttribute('download', props.name)
                document.body.appendChild(downloadLink)
                downloadLink.click()
                downloadLink.remove()
                setDownloadImages({
                    isDownloading: false,
                    progressValue: 0
                })
            })
    }

    return (
        <Modal
            className='custom_modal'
            open={props.isOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className="image_preview">
                <div className="header">
                    <Tooltip title="Download">
                        <IconButton onClick={handleDownload} color="primary" disabled={downloadImages.isDownloading}>
                            <Download />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Close">
                        <IconButton onClick={props.onClose} color="secondary">
                            <X />
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="content">
                    <img src={props.src} alt="image" />
                </div>
            </div>
        </Modal>
    )
}