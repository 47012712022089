import { Button, TextField, Card, CardContent, Typography, List, ListItem, ListItemText, CardActions, IconButton, ListItemSecondaryAction, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { useEffect, useState } from "react";
import { Edit2, Save, Send, Trash, X, XCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import { setNotePath, setNotePathModal } from "../../../dashboard/dashboard.reducer";
import { completeMarker, createComment, deleteTempNoteMarker, editNoteMarker, getNoteChat, getNoteDeleteRequests, getNoteListByJobNumberId, getNoteListDeleted, postNoteMarker, rejectRequestDeleteNoteMarker, requestDeleteNoteMarker } from "../../sideTab.api";
import { clearChatList, setNoteList, setNoteListDeleted } from "../../sideTab.reducer";

export default function NotePathModal() {

  const dispatch = useDispatch<any>()

  const { notePathModal, notePath } = useSelector((state: RootState) => state.dashboard)
  const { jobNumberIdActive, noteList, noteListDeleted, chatList, requestDeleteList } = useSelector((state: RootState) => state.sideTab)

  const [form, setForm] = useState<any>({
    markerImportType: 0,
    markerType: 3,
    name: "",
    description: "",
    latitude: "",
    longitude: "",
    markerColor: 1,
    lineData: JSON.stringify(notePath),
    jobNumberId: jobNumberIdActive.id,
    userId: localStorage.getItem("id")
  })
  const [isView, setIsView] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [emails, setEmails] = useState<any>([])
  const [emailTemp, setEmailTemp] = useState("")
  const [chat, setChat] = useState("")

  useEffect(() => {
    if (notePathModal.data) {
      setIsView(true)
      setForm({
        ...form,
        name: notePathModal.data.name,
        description: notePathModal.data.description,
        markerColor: notePathModal.data.markerColor
      })
      setEmails(notePathModal.data.recipientEmails || [])
      dispatch(getNoteChat(notePathModal?.data?.id))
      dispatch(getNoteDeleteRequests(notePathModal?.data?.id))
    }
    else {
      dispatch(clearChatList())
      setEmails([])
    }
  }, [notePathModal])

  const handleChangeForm = (e: any) => {
    let name = e.target.name
    let value = e.target.value
    setForm({
      ...form,
      [name]: value
    })
  }

  const handleCancel = () => {
    dispatch(setNotePathModal({ status: false }))
  }

  const handleSave = (e: any) => {
    if (isEdit) {
      let data = {
        id: notePathModal?.data?.id,
        description: form?.description,
        name: form?.name,
        markerColor: form?.markerColor,
        recipientEmails: emails
      }
      console.log(data)
      dispatch(editNoteMarker(data))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire("Edit path note failed!", res?.data?.message);
          }
          else {
            handleCancel()
            dispatch(setNotePath([]))
            dispatch(getNoteListByJobNumberId(jobNumberIdActive.id))
              .unwrap()
              .then((res: any) => {
                let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== jobNumberIdActive.id)
                dispatch(setNoteList(noteListNew.concat(res.data)))
              })
          }
        })
    }
    else {
      dispatch(postNoteMarker({
        ...form,
        recipientEmails: emails
      }))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire("Add path note failed!", res?.data?.message);
          }
          else {
            if (chat) {
              dispatch(createComment({
                markerId: res?.data?.id,
                comment: chat,
                userId: localStorage.getItem('id')
              }))
            }
            handleCancel()
            dispatch(setNotePath([]))
            dispatch(getNoteListByJobNumberId(jobNumberIdActive.id))
              .unwrap()
              .then((res: any) => {
                let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== jobNumberIdActive.id)
                dispatch(setNoteList(noteListNew.concat(res.data)))
              })
          }
        })
    }
  }

  const handleEdit = () => {
    setIsView(false)
    setIsEdit(true)
  }

  const handleDelete = () => {
    dispatch(deleteTempNoteMarker(notePathModal?.data?.id))
      .unwrap()
      .then((res: any) => {
        dispatch(getNoteListByJobNumberId(notePathModal?.data?.jobNumberId))
          .unwrap()
          .then((res: any) => {
            let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== notePathModal?.data?.jobNumberId)
            dispatch(setNoteList(noteListNew.concat(res.data)))
            dispatch(setNotePathModal({ status: false }))
          })
        dispatch(getNoteListDeleted(notePathModal?.data?.jobNumberId))
          .unwrap()
          .then((res: any) => {
            let noteListDeletedNew: any = noteListDeleted?.filter((item: any) => item.jobNumberId !== notePathModal?.data?.jobNumberId)
            dispatch(setNoteListDeleted(noteListDeletedNew.concat(res.data)))
          })
      })
  }

  const handleRequestDelete = () => {
    dispatch(requestDeleteNoteMarker(notePathModal?.data?.id))
      .unwrap()
      .then((res: any) => {
        Swal.fire("Success", `Your delete action has been requested to ${notePathModal?.data?.creator}`)
      })
  }

  const handleRejectRequestDelete = (id: string) => {
    dispatch(rejectRequestDeleteNoteMarker(id))
      .unwrap()
      .then((res: any) => {
        dispatch(getNoteDeleteRequests(notePathModal?.data?.id))
      })
  }

  const handleSubmitChat = () => {
    dispatch(createComment({
      markerId: notePathModal?.data?.id,
      comment: chat,
      userId: localStorage.getItem('id')
    }))
      .unwrap()
      .then(() => {
        setChat("")
        dispatch(getNoteChat(notePathModal?.data?.id))
      })
  }

  const handleComplete = (id: string) => {
    dispatch(completeMarker({ targetMarkerId: id, userId: localStorage.getItem('id') }))
      .unwrap()
      .then(() => {
        dispatch(getNoteListByJobNumberId(jobNumberIdActive.id))
          .unwrap()
          .then((res: any) => {
            dispatch(setNoteList(res.data))
            dispatch(setNotePathModal({ status: false }))
            Swal.fire("Success", "Notes successfully completed", "success")
          })
      })
  }

  return (
    <div className="formnote">
      <h3>Line<X onClick={handleCancel} /></h3>
      <div className="creator">
        {isView && (
          <span>
            created by {notePathModal?.data?.creator} at {moment(new Date(notePathModal?.data?.createdDate)).format("MM-DD-yyyy hh:mm:ss")}
          </span>
        )}
      </div>
      <form>
        <TextField
          className="inp"
          variant="outlined"
          type="text"
          label="Subject"
          size="small"
          name="name"
          value={form.name}
          onChange={handleChangeForm}
          inputProps={{
            readOnly: isView
          }}
        />
        <TextField
          className="inp"
          variant="outlined"
          type="text"
          label="Note"
          size="small"
          multiline={true}
          name="description"
          value={form.description}
          onChange={handleChangeForm}
          inputProps={{
            readOnly: isView
          }}
        />
        <Autocomplete
          multiple
          options={[...emails, emailTemp]}
          id="disable-clearable"
          value={emails}
          onInputChange={(e: any) => setEmailTemp(e.target?.value || "")}
          onChange={(e, value) => setEmails(value)}
          className="inp"
          disableClearable
          disabled={isView}
          renderInput={(params) => <TextField {...params} label="To" size="small" placeholder="Search email" variant="outlined" />}
        />
        <br />
        <Card>
          <CardContent>
            <Typography variant="h6">
              Chats
            </Typography>
            <List>
              {chatList.map((chat: any) => (
                <ListItem>
                  <ListItemText
                    primary={chat.comment}
                    secondary={`> ${chat.userName} at ${chat.time}`}

                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
          <CardActions>
            <TextField fullWidth variant="outlined" label="Message" value={chat} onChange={(e) => setChat(e.target.value)} />
            {(emails.includes(localStorage.getItem("email")) || localStorage.getItem("email") === notePathModal?.data?.creator) && (
              <IconButton onClick={handleSubmitChat}>
                <Send />
              </IconButton>
            )}
          </CardActions>
        </Card>
        {localStorage.getItem("email") === notePathModal?.data?.creator && (
          <>
            <br />
            <Card>
              <CardContent>
                <Typography variant="h6">
                  Delete Request
                </Typography>
                <List>
                  {requestDeleteList.map((request: any) => (
                    <ListItem>
                      <ListItemText
                        primary={request.requestedBy}
                        secondary={`> at ${request.dateRequest}`}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title="reject request">
                          <IconButton onClick={() => handleRejectRequestDelete(request.id)}>
                            <XCircle />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </>
        )}
        {(localStorage.getItem("email") === notePathModal?.data?.creator || !isView)
          ? (
            <>
              <br />
              <div className="wrapbutton" >
                <div className="left">
                  {notePathModal?.data?.status === 0 && (
                    <Button className="done" onClick={() => handleComplete(notePathModal?.data?.id)}>
                      Complete Note
                    </Button>
                  )}
                </div>
                {isView
                  ? (
                    <>
                      <Button className="delete" variant="outlined" onClick={handleDelete}>
                        <Trash /> Delete
                      </Button>
                      <Button className="edit" variant="outlined" onClick={handleEdit}>
                        <Edit2 /> Edit
                      </Button>
                    </>
                  )
                  : (
                    <Button className="save" variant="outlined" onClick={handleSave}>
                      <Save /> Save
                    </Button>
                  )
                }
              </div>
            </>
          )
          : (
            <>
              <br />
              <div className="wrapbutton" >
                <Button className="delete" variant="outlined" onClick={handleRequestDelete}>
                  <Trash /> Delete
                </Button>
              </div>
            </>
          )}
      </form>
    </div>
  );
}
