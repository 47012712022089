import { Box, CircularProgress, Typography } from "@material-ui/core";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { FileText, Trash2 } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { baseUrl } from "../../../state";
import { getMidspanDetails } from "../poleInformation.api";

export default function FormMidspanAttachment(props: any) {

    const dispatch = useDispatch<any>()

    const { isEditPoleInfo, midspanDetail } = useSelector((state: RootState) => state.poleInformation)

    const [isFilesUploading, setIsFilesUploading] = useState(false);
    const [localFiles, setLocalFiles] = useState<any>([]);

    useEffect(() => {
        if (localFiles.length > 0 && localFiles.every((item: any) => item.uploadProgress === 0)) {
            uploadProgress()
        }
        else if (localFiles.length === 0 && isFilesUploading === true) {
            setIsFilesUploading(false)
        }
    }, [localFiles])

    const onChangeFile = async (files: any) => {
        if (files) {
            setIsFilesUploading(true)
            let x: any = []
            files.map((file: any) => {
                x.push({
                    name: file.name,
                    objectUrl: URL.createObjectURL(file),
                    uploadProgress: 0,
                    file: file
                })
            })
            setLocalFiles(x)
        }
    };

    const uploadProgress = async () => {
        let data = new FormData();
        data.append("midSpanId", midspanDetail?.id)
        data.append("userId", localStorage.getItem('id') || "")
        data.append("", localFiles[0].file)
        try {
            await axios.post(`${baseUrl}/api/MidSpan/UploadDocumentAttachments`, data, {
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                    setLocalFiles(
                        localFiles.map((lastFile: any) => {
                            if (lastFile.name === localFiles[0].name) {
                                return {
                                    ...lastFile,
                                    uploadProgress: percentCompleted
                                }
                            }
                            else {
                                return lastFile
                            }
                        })
                    )
                }
            })
            dispatch(getMidspanDetails(midspanDetail?.id))
                .unwrap()
                .then(() => {
                    setLocalFiles(localFiles.filter((item: any) => item.name !== localFiles[0].name))
                })
        } catch (error: any) {
            console.log(error);
        }
    }

    const handleDeleteAttachment = async (id: any) => {
        // await dispatch(deletePoleAttachment(id))
        dispatch(getMidspanDetails(midspanDetail?.id))
            .unwrap()
            .then((response: any) => {
                props.setFiles(response?.data?.documentList)
            })
    }

    const handleDownloadDoc = (item: any) => {
        var element = document.createElement('a');
        element.setAttribute('href', item.filePath.includes(".com") ? item.filePath : baseUrl + "/" + item.filePath);
        element.setAttribute('download', item.fileName);
        element.setAttribute('target', '_blank');
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (
        <Fragment>
            <div className="header-content">
                <h4>Document</h4>
            </div>
            {isEditPoleInfo && (!isFilesUploading
                ? (
                    <Dropzone onDrop={onChangeFile} accept={{
                        "application/msword": [],
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
                        "application/xml": [],
                        "application/vnd.google-earth.kml+xml": [],
                        "application/pdf": [],
                        "application/vnd.ms-powerpoint.presentation.macroenabled.12": []
                    }}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="dropzone-container">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p>Drag and drop some files here, or click to select files</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                )
                : (
                    <div className="dropzone-container">
                        <p>Uploading, {localFiles.length} file left</p>
                    </div>
                ))}
            <div className="doc-content">
                {props.files?.map((item: any, index: number) => (
                    <div key={index} className="doc-container" style={{ cursor: isEditPoleInfo ? 'auto' : 'pointer' }} onClick={() => !isEditPoleInfo && handleDownloadDoc(item)}>
                        <div className="doc">
                            <FileText />
                            <p>{item?.fileName}</p>
                        </div>
                        {isEditPoleInfo ?
                            (
                                <div className='attach-delete-icon' onClick={() => handleDeleteAttachment(item?.id)}>
                                    <Trash2 />
                                </div>
                            )
                            : null
                        }
                    </div>
                ))}
                {localFiles?.map((item: any, index: number) => (
                    <div key={index} className="doc-container">
                        <div className="progress-container">
                            <div className="doc">
                                <FileText />
                                <p>{item?.name}</p>
                            </div>
                            <div className='attach-delete-icon'>
                                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                    <CircularProgress variant="determinate" value={item.uploadProgress} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: 'absolute',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            component="div"
                                        >{`${item.uploadProgress}%`}</Typography>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Fragment>
    )
}