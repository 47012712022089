import { createSlice } from '@reduxjs/toolkit'
import { getProfile, linkToFP, unlink } from './profile.api'

export interface ProfileState {
  loading: boolean,
}
const initialState: ProfileState = {
  loading: false,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get
    builder.addCase(getProfile.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false
    })

    // linkToFP
    builder.addCase(linkToFP.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(linkToFP.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(linkToFP.rejected, (state, action) => {
      state.loading = false
    })

    //unlink
    builder.addCase(unlink.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(unlink.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(unlink.rejected, (state, action) => {
      state.loading = false
    })

  },
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default profileSlice.reducer