import { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import { handleAnchorCanvasFences, handleAnchorCanvasStreets, handleAnchorCanvasDotes, handleAnchorAddType } from '../../poleInformation.reducer'
import bgAnchor from '../../../../assets/images/bgAnchor.png'

export default function AnchorCanvas() {
    const dispatch = useDispatch<any>()
    const {
        anchorCanvasFences,
        anchorCanvasStreets,
        anchorCanvasDotes,
        anchorAddType
    } = useSelector((state: RootState) => state.poleInformation)
    const [lines, setLines] = useState<any>({})

    const canvas: any = document.getElementById('canvasAnchor')

    const translatedX = (x: any) => {
        var rect = canvas.getBoundingClientRect();
        var factor = canvas.width / rect.width;
        return factor * (x - rect.left);
    }

    const translatedY = (y: any) => {
        var rect = canvas.getBoundingClientRect();
        var factor = canvas.height / rect.height;
        return factor * (y - rect.top);
    }

    useLayoutEffect(() => {
        const canvas: any = document.getElementById('canvasAnchor')
        const ctx: any = canvas.getContext("2d")

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        anchorCanvasDotes.forEach((res: any) => {
            ctx.strokeStyle = 'grey'
            ctx.beginPath();
            ctx.arc((res.circleX * 2), (res.circleY * 2), 15, 0, 2 * Math.PI, false);
            ctx.font = '10px, Calibri';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'red';
            ctx.fillText(res.text, (res.circleX * 2), (res.circleY * 2) + 3);
            ctx.closePath();
            ctx.stroke();
        })

        anchorCanvasStreets.forEach((res: any) => {
            ctx.strokeStyle = `${res.stroke}`
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.lineTo((res.points.x1 * 2), (res.points.y1 * 2));
            ctx.lineTo((res.points.x2 * 2), (res.points.y2 * 2));
            ctx.closePath();
            ctx.stroke();
        })

        anchorCanvasFences.forEach((res: any) => {
            ctx.strokeStyle = `${res.stroke}`
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.lineTo((res.points.x1 * 2), (res.points.y1 * 2));
            ctx.lineTo((res.points.x2 * 2), (res.points.y2 * 2));
            ctx.closePath();
            ctx.stroke();
        })

    }, [
        anchorCanvasDotes,
        anchorCanvasStreets,
        anchorCanvasFences
    ])


    let handleClick = (e: any) => {
        if (anchorAddType === 'anchorDot') {
            let { clientX, clientY } = e
            let lastName = anchorCanvasDotes.length > 0 ? anchorCanvasDotes.at(-1).text : '0'
            dispatch(
                handleAnchorCanvasDotes(
                    [
                        ...anchorCanvasDotes,
                        {
                            circleX: translatedX(clientX) / 2,
                            circleY: translatedY(clientY) / 2,
                            textX: 0,
                            textY: 0,
                            text: 'A' + (parseInt(lastName.replace('A', '0')) + 1).toString(),
                            distance: 0,
                            size: 0,
                            anchorEye: 0,
                            eyesPict: true,
                            // poleID: "ef0f370e-d8d2-4109-9611-eba425d42361",
                            imageType: 0,
                            anchorCondition: 0,
                            downGuyList: []
                        }
                    ]
                )
            )
            dispatch(handleAnchorAddType(''))
        }
    }

    let handleMouseDown = (e: any) => {
        if (anchorAddType === 'anchorStreet') {
            let { clientX, clientY } = e
            setLines({ x1: translatedX(clientX) / 2, y1: translatedY(clientY) / 2 })
        }
        if (anchorAddType === 'anchorFence') {
            let { clientX, clientY } = e
            setLines({ x1: translatedX(clientX) / 2, y1: translatedY(clientY) / 2 })
        }
    }

    let handleMouseUp = (e: any) => {
        if (anchorAddType === 'anchorStreet') {
            let { clientX, clientY } = e
            dispatch(handleAnchorCanvasStreets([...anchorCanvasStreets, { points: { ...lines, x2: translatedX(clientX) / 2, y2: translatedY(clientY) / 2 }, stroke: 'black' }]))
            dispatch(handleAnchorAddType(''))
        }
        if (anchorAddType === 'anchorFence') {
            let { clientX, clientY } = e
            dispatch(handleAnchorCanvasFences([...anchorCanvasFences, { points: { ...lines, x2: translatedX(clientX) / 2, y2: translatedY(clientY) / 2 }, stroke: 'brown' }]))
            dispatch(handleAnchorAddType(''))
        }
    }

    return (
        <div id="frame">
            <canvas
                id='canvasAnchor'
                style={{ backgroundImage: `url('${bgAnchor}')`, cursor: 'crosshair' }}
                width={600}
                height={400}
                // width={window.innerWidth}
                // height={window.innerHeight}
                onMouseDown={handleMouseDown}
                // onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onClick={handleClick}
            >
            </canvas>

        </div>

    )
}
