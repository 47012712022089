import { Button, Tooltip } from "@material-ui/core";
import { Crop, Edit } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { handleFieldingIcon, handleModalAerialFielding, handleLayerActive, handleNoteIcon, handleModalLoadingRequest, handleInventoryFormModal } from "../../sideTab/sideTab.reducer";
import white_blue_pole from "../../../assets/icons/white_blue_pole.png";
import white_yellow_pole from "../../../assets/icons/white_yellow_pole.png";
import white_red_pole from "../../../assets/icons/white_red_pole.png";
import inventory from "../../../assets/icons/inventory.png";
import midspan from "../../../assets/icons/midspan.png";
import erase from "../../../assets/icons/erase.png";
import { setNoteShapeModal, setNoteShape, setNotePath, setNotePathModal, setIsDragSelectActive, setSelectedIdMarkers } from "../dashboard.reducer";
import Swal from "sweetalert2";
import { addPolesToLoadingRequest } from "../../sideTab/sideTab.api";
import { getPoleLoadingRequest } from "../../poleInformation/poleInformation.api";
import { setListPoleLoadingRequest } from "../../poleInformation/poleInformation.reducer";
import cableAerialYellow from "../../../assets/icons/cableAerialYellow.png";
import area from "../../../assets/icons/area.jpeg";
import { Fragment } from "react";
import Roles from "../../../shared/Roles";

function LayerActive() {
  const dispatch = useDispatch<any>();
  const { isLayerActive, fieldingIcon, modalAerialFielding, noteIcon, modalLoadingRequest, loadingRequestIdActive, inventoryFormModal, fieldingRequestIdActive } = useSelector((state: RootState) => state.sideTab);
  const { selectedIdMarkers, noteShape, noteShapeModal, notePath, notePathModal, isDragSelectActive } = useSelector((state: RootState) => state.dashboard);
  const { listPoleLoadingRequest } = useSelector((state: RootState) => state.poleInformation);

  let clickModalRequestFielding = () => {
    dispatch(handleModalAerialFielding({ isOpen: !modalAerialFielding.isOpen, formType: "edit" }));
  };

  let clickModalRequestLoading = () => {
    dispatch(handleModalLoadingRequest({ isOpen: !modalLoadingRequest.isOpen, formType: "edit-detail" }));
  };

  let clickModalInventory = () => {
    dispatch(handleInventoryFormModal({ isOpen: !inventoryFormModal.isOpen, formType: "edit" }));
  };

  let handleDone = async () => {
    await new Promise((resolve) => {
      if (noteShape.length > 0) {
        Swal.fire({
          text: fieldingIcon === 5 ? 'Added Area is Not Yet Saved' : 'Added Note Shape is Not Yet Saved',
          icon: 'warning',
          confirmButtonText: "Save",
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            let newNoteShapeModal = JSON.parse(JSON.stringify(noteShapeModal))
            newNoteShapeModal.status = true
            dispatch(setNoteShapeModal(newNoteShapeModal))
            dispatch(setNoteShape([]))
            resolve(true)
          }
        })
      }
      else {
        dispatch(setNoteShape([]))
        resolve(true)
      }
    })
    await new Promise((resolve) => {
      if (notePath.length > 0) {
        Swal.fire({
          text: fieldingIcon === 4 ? 'Added Path is Not Yet Saved' : 'Added Note Line is Not Yet Saved',
          icon: 'warning',
          confirmButtonText: "Save",
          showCancelButton: true
        }).then((result) => {
          if (result.isConfirmed) {
            let newNotePathModal = JSON.parse(JSON.stringify(notePathModal))
            newNotePathModal.status = true
            dispatch(setNotePathModal(newNotePathModal))
            dispatch(setNotePath([]))
            resolve(true)
          }
        })
      }
      else {
        dispatch(setNotePath([]))
        resolve(true)
      }
    })
    if (selectedIdMarkers.length > 0) {
      await handleAdd()
    }
    dispatch(handleLayerActive({}));
    dispatch(handleFieldingIcon(null));
    dispatch(handleNoteIcon(null));
    if (isLayerActive.label === "loading") {
      dispatch(handleModalLoadingRequest({ isOpen: !modalLoadingRequest.isOpen, formType: "edit" }));
    }
  };

  const handleSave = () => {
    if (noteIcon === 2) {
      let newNoteShapeModal = JSON.parse(JSON.stringify(noteShapeModal))
      newNoteShapeModal.status = true
      dispatch(setNoteShapeModal(newNoteShapeModal))
    }
    else if (noteIcon === 3) {
      let newNotePathModal = JSON.parse(JSON.stringify(notePathModal))
      newNotePathModal.status = true
      dispatch(setNotePathModal(newNotePathModal))
    }
  }

  const handleUndo = () => {
    if (noteIcon === 2) {
      let newNoteShape: any = JSON.parse(JSON.stringify(noteShape))
      newNoteShape.splice(-1, 1)
      dispatch(setNoteShape(newNoteShape))
    }
    else if (noteIcon === 3) {
      let newNotePath: any = JSON.parse(JSON.stringify(notePath))
      newNotePath.splice(-1, 1)
      dispatch(setNotePath(newNotePath))
    }
  }

  const handleClear = () => {
    if (noteIcon === 2) {
      dispatch(setNoteShape([]))
    }
    else if (noteIcon === 3) {
      dispatch(setNotePath([]))
    }
  }

  const handleSaveFielding = () => {
    if (fieldingIcon === 4) {
      let newNotePathModal = JSON.parse(JSON.stringify(notePathModal))
      newNotePathModal.status = true
      dispatch(setNotePathModal(newNotePathModal))
    }
    else if (fieldingIcon === 5) {
      let newNoteShapeModal = JSON.parse(JSON.stringify(noteShapeModal))
      newNoteShapeModal.status = true
      dispatch(setNoteShapeModal(newNoteShapeModal))
    }
  }

  const handleUndoFielding = () => {
    if (fieldingIcon === 4) {
      let newNotePath: any = JSON.parse(JSON.stringify(notePath))
      newNotePath.splice(-1, 1)
      dispatch(setNotePath(newNotePath))
    }
    else if (fieldingIcon === 5) {
      let newNoteShape: any = JSON.parse(JSON.stringify(noteShape))
      newNoteShape.splice(-1, 1)
      dispatch(setNoteShape(newNoteShape))
    }
  }

  const handleClearFielding = () => {
    if (fieldingIcon === 4) {
      dispatch(setNotePath([]))
    }
    else if (fieldingIcon === 5) {
      dispatch(setNoteShape([]))
    }
  }

  const handleAdd = () => {
    return new Promise(resolve => {
      dispatch(addPolesToLoadingRequest({
        "ocalcId": loadingRequestIdActive.id,
        "poleIds": selectedIdMarkers
      }))
        .unwrap()
        .then(() => {
          dispatch(setSelectedIdMarkers([]))
          dispatch(getPoleLoadingRequest(loadingRequestIdActive.id))
            .unwrap()
            .then((response: any) => {
              let poleLoadingRequest: any = listPoleLoadingRequest?.filter((item: any) => item?.ocalcId != loadingRequestIdActive.id);
              dispatch(setListPoleLoadingRequest(poleLoadingRequest.concat(response.data)));
              resolve(true)
            })
        })
    })
  }

  const handleDragSelect = () => {
    if (!isDragSelectActive) {
      Swal.fire({
        icon: "success",
        title: "Drag select mode active",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    dispatch(setIsDragSelectActive(!isDragSelectActive))
  }

  if (isLayerActive.status) {
    return (
      <>
        <div className="layeractive">
          <div className="buttons">
            {isLayerActive.label === "aerial"
              ? (
                <Button className="info" onClick={clickModalRequestFielding} variant="contained">
                  Edit Fielding Request and Date <Edit />
                </Button>
              )
              : isLayerActive.label === "loading"
                ? (
                  <Button className="info" onClick={clickModalRequestLoading} variant="contained">
                    Edit Loading Request and Date <Edit />
                  </Button>
                )
                : isLayerActive.label === "inventory"
                  ? (
                    <Button className="info" onClick={clickModalInventory} variant="contained">
                      Edit Pole Inventory <Edit />
                    </Button>
                  )
                  : null
            }
          </div>
          {isLayerActive.label === "aerial"
            ? (
              fieldingRequestIdActive.fieldingCategory === 1
                ? (
                  <div className="mapicon">
                    Add Measure Marker &nbsp;
                    {/* <img className={fieldingIcon === 6 ? "active" : ""} src={measure_marker_start} alt="mark" onClick={() => dispatch(handleFieldingIcon(6))} /> */}
                    <Tooltip title="Path">
                      <img className={fieldingIcon === 4 ? "active" : ""} src={cableAerialYellow} alt="mark" onClick={() => dispatch(handleFieldingIcon(4))} />
                    </Tooltip>
                    {/* <Tooltip title="Area">
                      <img className={fieldingIcon === 5 ? "active" : ""} src={area} alt="mark" onClick={() => dispatch(handleFieldingIcon(5))} />
                    </Tooltip> */}
                  </div>
                )
                : (
                  <div className="mapicon">
                    Add {Roles("fielding_request_add_pole") && "Pole"} &nbsp;
                    <Tooltip title="Path">
                      <img className={fieldingIcon === 4 ? "active" : ""} src={cableAerialYellow} alt="mark" onClick={() => dispatch(handleFieldingIcon(4))} />
                    </Tooltip>
                    <Tooltip title="Area">
                      <img className={fieldingIcon === 5 ? "active" : ""} src={area} alt="mark" onClick={() => dispatch(handleFieldingIcon(5))} />
                    </Tooltip>
                    {Roles("fielding_request_add_pole") && (
                      <Fragment>
                        <Tooltip title="Pole">
                          <img className={fieldingIcon === 0 ? "active" : ""} src={white_blue_pole} alt="mark" onClick={() => dispatch(handleFieldingIcon(0))} />
                        </Tooltip>
                        <Tooltip title="Reference Pole">
                          <img className={fieldingIcon === 2 ? "active" : ""} src={white_red_pole} alt="mark" onClick={() => dispatch(handleFieldingIcon(2))} />
                        </Tooltip>
                        <Tooltip title="Support Pole">
                          <img className={fieldingIcon === 1 ? "active" : ""} src={white_yellow_pole} alt="mark" onClick={() => dispatch(handleFieldingIcon(1))} />
                        </Tooltip>
                        <Tooltip title="Midspan">
                          <img className={fieldingIcon === 3 ? "active" : ""} src={midspan} alt="mark" onClick={() => dispatch(handleFieldingIcon(3))} />
                        </Tooltip>
                      </Fragment>
                    )}
                  </div>
                )
            )
            : (noteIcon === 2 || noteIcon === 3
              ? (
                <div className="note-button">
                  <Button variant="contained" className="note-button-save" onClick={handleSave}>Save</Button>
                  <Button variant="contained" className="note-button-undo" onClick={handleUndo}>Undo</Button>
                  <Button variant="contained" className="note-button-clear" onClick={handleClear}>Clear</Button>
                </div>
              )
              : null
            )}
          {(fieldingIcon === 4 || fieldingIcon === 5) && (
            <div className="note-button">
              <Button variant="contained" className="note-button-save" onClick={handleSaveFielding}>Save</Button>
              <Button variant="contained" className="note-button-undo" onClick={handleUndoFielding}>Undo</Button>
              <Button variant="contained" className="note-button-clear" onClick={handleClearFielding}>Clear</Button>
            </div>
          )}
          {isLayerActive.label === "loading" && (
            <div className="mapicon">
              Add Pole &nbsp;
              {Roles("fielding_request_add_pole") && (
                <Fragment>
                  <Tooltip title="Pole">
                    <img className={fieldingIcon === 0 ? "active" : ""} src={white_blue_pole} alt="mark" onClick={() => dispatch(handleFieldingIcon(0))} />
                  </Tooltip>
                  <Tooltip title="Reference Pole">
                    <img className={fieldingIcon === 2 ? "active" : ""} src={white_red_pole} alt="mark" onClick={() => dispatch(handleFieldingIcon(2))} />
                  </Tooltip>
                  <Tooltip title="Support Pole">
                    <img className={fieldingIcon === 1 ? "active" : ""} src={white_yellow_pole} alt="mark" onClick={() => dispatch(handleFieldingIcon(1))} />
                  </Tooltip>
                  {" "}
                </Fragment>
              )}
              <Tooltip title="Eraser">
                <img className={fieldingIcon === -1 ? "active" : ""} src={erase} alt="mark" onClick={() => dispatch(handleFieldingIcon(-1))} />
              </Tooltip>
            </div>
          )}
          {isLayerActive.label === "inventory" && (
            <div className="mapicon">
              Add Pole &nbsp;
              <Tooltip title="Pole">
                <img className={fieldingIcon === 0 ? "active" : ""} src={inventory} alt="mark" onClick={() => dispatch(handleFieldingIcon(0))} />
              </Tooltip>
            </div>
          )}
          {isLayerActive.label === "loading" && (
            <div className="pole-loading-button">
              <Button onClick={handleDragSelect} className={isDragSelectActive ? "active" : ""}>
                <Crop /> Select multiple pole
              </Button>
            </div>
          )}
          <Button className="done" variant="contained" onClick={handleDone}>
            {noteIcon === null ? 'Done' : 'complete add note'}
          </Button>
        </div>
      </>
    );
  }
  return null;
}

export default LayerActive;
