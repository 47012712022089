import { Button, TextField } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import { RootState } from '../../../app/store';
import { getAllAssignedJob, getFielders, getFieldingRequestDetail, postFielders } from '../assignFielding.api';
import { handleIsChooseFielderModal } from '../assignFielding.reducer';


export default function ChooseFielderModal() {

    const dispatch = useDispatch<any>()

    const { fielderList, requestSelected, statusSelected } = useSelector((state: RootState) => state.assignFielding)

    const [value, setValue] = useState<any>([])

    useEffect(() => {
        if (requestSelected.assignedFielderIds) {
            let localValues: any = []
            let idList = requestSelected?.assignedFielderIds.split(', ')
            let nameList = requestSelected?.assignedFielders.split(', ')
            let fullNameList = requestSelected?.assignedFielderFullNames.split(', ')
            idList.map((id: String, i: number) => {
                localValues.push({
                    id: id,
                    value: nameList[i],
                    fullName: fullNameList[i]
                })
            })
            setValue(localValues)
        }
    }, [requestSelected])

    useEffect(() => {
        dispatch(getFielders(localStorage.getItem('companyIdSelected')))
    }, [])

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch(postFielders([{
            fieldingRequestId: requestSelected.fieldingRequestId,
            assignedFielderIds: value
        }]))
            .unwrap()
            .then((res: any) => {
                if (res.status >= 400) {
                    Swal.fire("error!");
                }
                else {
                    dispatch(handleIsChooseFielderModal(""))
                    dispatch(getAllAssignedJob(statusSelected))
                    dispatch(getFieldingRequestDetail(requestSelected.fieldingRequestId))
                }
            })
    }

    const handleChange = (e: any, value: any) => {
        setValue(value)
    }

    return (
        <div className='formproject'>
            <h3>
                Choose Fielder <X onClick={() => dispatch(handleIsChooseFielderModal(""))} />
            </h3>
            <form onSubmit={handleSubmit}>
                <Autocomplete
                    multiple
                    options={fielderList}
                    getOptionLabel={(option: any) => `${option.value}${option.fullName}`}
                    renderOption={(option) => (
                        <li>
                            {option.fullName}<br />
                            {option.value}
                        </li>
                    )}
                    renderTags={(options) => (
                        options.map(option => (
                            <span style={{
                                backgroundColor: "#e0e0e0",
                                padding: "5px 10px",
                                borderRadius: 20,
                                marginRight: 5,
                                display: "flex",
                                alignItems: "center"
                            }}>
                                {option.value}
                                <CloseOutlined style={{ cursor: "pointer" }} fontSize='small' onClick={() => setValue(value.filter((d: any) => d.id !== option.id))} />
                            </span>
                        ))
                    )}
                    id="disable-clearable"
                    value={value}
                    onChange={handleChange}
                    disableClearable
                    filterSelectedOptions
                    renderInput={(params) => <TextField {...params}
                        label="Choose fielder"
                        placeholder='Search fielder'
                        variant="outlined"
                    />}
                />
                <br />
                <div className="wrapbutton">
                    <Button type='submit' className='save' variant='outlined'>SAVE</Button>
                </div>
            </form>
        </div>
    )
}


