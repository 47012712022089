import { Button, Container, Grid, InputAdornment, TextField } from "@material-ui/core";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { handleModalSpanAndDirection, handleSpanMapLines } from "../poleInformation.reducer";
import SpanMap from "./spanMap/SpanMap";

function SpanAndDirectionModal(props: any) {

    const dispatch = useDispatch<any>()

    const { spanMapLines } = useSelector((state: RootState) => state.poleInformation)

    const handleDeleteSpanMapLines = (index: number) => {
        let newSpanMapLines: any = []
        spanMapLines.map((spanMapLine: any, spanMapLineIndex: number) => {
            if (spanMapLineIndex !== index) {
                newSpanMapLines.push(spanMapLineIndex)
            }
        })
        dispatch(handleSpanMapLines(newSpanMapLines))
    }

    const handleChangeLength = (e: any, index: number) => {
        let newSpanMapLines = JSON.parse(JSON.stringify(spanMapLines))
        newSpanMapLines[index].length = e.target.value
        dispatch(handleSpanMapLines(newSpanMapLines))
    }

    const handleSave = () => {
        dispatch(handleModalSpanAndDirection())
    }

    return (
        <div className='modalsketchlib image-modal'>
            <h3>
                Image
                <X onClick={() => dispatch(handleModalSpanAndDirection())} />
            </h3>
            <div className="box spananddirpage">
                <div className='wrapmap'>
                    <SpanMap />
                </div>
                <h4>Span length</h4>
                <Grid>
                    {
                        spanMapLines.map((res: any, i: number) =>
                            <TextField
                                key={i}
                                className='inp'
                                name='name'
                                variant="outlined"
                                style={{ backgroundColor: res.color }}
                                // label="Height of Attachment (Inch)" 
                                // placeholder="Height of Attachment (Inch)"
                                size='small'
                                value={res.length}
                                onChange={(e) => handleChangeLength(e, i)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button type='submit' size='small' variant='outlined' onClick={() => handleDeleteSpanMapLines(i)}>Delete</Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )
                    }
                    <div className="savebutton">
                        <Button variant='contained' onClick={handleSave}>Done</Button>
                    </div>
                </Grid>
            </div>
        </div>
    );
}

export default SpanAndDirectionModal;