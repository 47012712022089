import { Button, Card, CardContent, CardHeader, IconButton, ListItem, MenuItem, Modal, TextField, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Download, X, File, Move, ArrowLeft, ArrowRight, ChevronLeft, ChevronRight } from "react-feather";
import axios from "axios";
import { baseUrl } from "../../../../state";
import { useDispatch, useSelector } from "react-redux";
import { getPoleFieldingRequest } from "../../../poleInformation/poleInformation.api";
import { RootState } from "../../../../app/store";
import { moveAttachment } from "../../../assignFielding/assignFielding.api";
import Swal from "sweetalert2";
import { getFieldingRequestAttachmentList, getFieldingRequestAttachmentLogs } from "../../sideTab.api";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment-timezone";

function AerialFieldingImageModal(props: any) {

    const dispatch = useDispatch<any>()

    const { aerialFielding } = useSelector((state: RootState) => state.assignFielding)
    const { loadingPole } = useSelector((state: RootState) => state.poleInformation)
    const { fieldingAttachmentLogs, fieldingAttachmentList } = useSelector((state: RootState) => state.sideTab)

    const [downloadImages, setDownloadImages] = useState<any>({
        isDownloading: false,
        progressValue: 70
    })
    const [modalMove, setModalMove] = useState(false)
    const [poleList, setPoleList] = useState<any>([])
    const [poleIdSelected, setPoleIdSelected] = useState("")

    const [logPage, setLogPage] = useState(0)
    const [logTimeTaken, setLogTimeTaken] = useState("")
    const [logTimeOffset, setLogTimeOffset] = useState(0)
    const [logTimeSeq, setLogTimeSeq] = useState("")
    const [timeOffset, setTimeOffset] = useState<any>([])
    const [fieldingAttachmentListSorted, setFieldingAttachmentListSorted] = useState<any>([])
    const [attachmentIndex, setAttachmentIndex] = useState(0)

    useEffect(() => {
        setLogTimeTaken(moment().format("MM/DD/YYYY HH:mm:ss"))
        let timeZoneList = [
            {
                name: "America/New_York",
                text: "Eastern Time"
            },
            {
                name: "America/Denver",
                text: "Mountain Time"
            },
            {
                name: "America/Los_Angeles",
                text: "Pacific Time"
            },
            {
                name: "America/Chicago",
                text: "Central Time"
            },
            {
                name: "US/Hawaii",
                text: "Hawaii Time"
            },
        ]
        setTimeOffset([{ text: "Select time offset", offset: 0 }].concat(timeZoneList.map(timeZone => {
            return {
                offset: moment().tz(timeZone.name).utcOffset() * -1,
                text: moment().tz(timeZone.name).zoneAbbr() + '/' + timeZone.text
            }
        })))
    }, [])

    useEffect(() => {
        if (modalMove) {
            getPoleList()
        }
    }, [modalMove])

    useEffect(() => {
        getDataLog()
    }, [logPage])

    useEffect(() => {
        if (props.sortBy === "Taken date") {
            setFieldingAttachmentListSorted(JSON.parse(JSON.stringify(fieldingAttachmentList))
                .sort((a: any, b: any) => {
                    if ((new Date(a.detectedImageTakenDate).getTime()) < (new Date(b.detectedImageTakenDate).getTime())) {
                        return -1
                    }
                    if ((new Date(a.detectedImageTakenDate).getTime()) > (new Date(b.detectedImageTakenDate).getTime())) {
                        return 1
                    }
                    return 0
                }))
        }
        if (props.sortBy === "Name") {
            setFieldingAttachmentListSorted(JSON.parse(JSON.stringify(fieldingAttachmentList))
                .sort((a: any, b: any) => {
                    if (a.fileName < b.fileName) {
                        return -1
                    }
                    if (a.fileName > b.fileName) {
                        return 1
                    }
                    return 0
                }))
        }
        setAttachmentIndex(props.attachmentIndex)
    }, [props.attachmentSelectedIndex])

    const timeOffsetUpload = [
        {
            offset: 0,
            text: "-",
        },
        {
            offset: 240,
            text: "Eastern Daylight Time (EDT)",
        },
        {
            offset: 300,
            text: "Eastern Standard Time (EST)",
        },
        {
            offset: 480,
            text: "Pacific Standard Time (PST)",
        },
        {
            offset: 420,
            text: "Pacific Daylight Time (PDT)",
        },
        {
            offset: 300,
            text: "Central Daylight Time (CDT)",
        },
        {
            offset: 360,
            text: "Central Standard Time (CST)",
        },
        {
            offset: 360,
            text: "Mountain Daylight Time (MDT)",
        },
        {
            offset: 420,
            text: "Mountain Standard Time (MST)",
        },
        {
            offset: 600,
            text: "Hawaii Standard Time (HWT)",
        },
        {
            offset: 540,
            text: "Hawaii-Aleutian Daylight Time (HADT)",
        }
    ]

    const getDataLog = () => {
        const dataPerPage = 10
        dispatch(getFieldingRequestAttachmentLogs({
            fieldingRequestId: aerialFielding.id,
            skip: logPage * dataPerPage,
            take: dataPerPage,
            detectedImageTakenDate: (logTimeTaken && logTimeOffset) ? logTimeTaken : null,
            selectedUploadTimeOffset: (logTimeTaken && logTimeOffset) ? logTimeOffset : null,
            poleSequence: logTimeSeq || null
        }))
    }

    const getPoleList = async () => {
        await dispatch(getPoleFieldingRequest(aerialFielding.id))
            .unwrap()
            .then((response: any) => {
                setPoleList(response.data)
            })
    }

    const handleDownload = (item: any) => {
        setDownloadImages({
            isDownloading: true,
            progressValue: 0
        })
        axios({
            url: item.filePath.includes('.com') ? item.filePath : baseUrl + "/api/Global/Download?path=" + item.filePath,
            method: "GET",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0));
                setDownloadImages({
                    isDownloading: true,
                    progressValue: percentCompleted
                })
                console.log(percentCompleted)
            }
        })
            .then(response => {
                let x = item.filePath.split('.')
                let extension = x[x.length - 1]
                const downloadLink = document.createElement('a')
                downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
                downloadLink.setAttribute('download', item.fileName.includes('.') ? item.fileName : item.fileName + '.' + extension)
                document.body.appendChild(downloadLink)
                downloadLink.click()
                downloadLink.remove()
                setDownloadImages({
                    isDownloading: false,
                    progressValue: 0
                })
            })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.onClose()
        let data = {
            fieldingRequestAttachmentId: fieldingAttachmentListSorted[attachmentIndex]?.id,
            targetPoleId: poleIdSelected,
            userId: localStorage.getItem("id")
        }
        dispatch(moveAttachment(data))
            .unwrap()
            .then((res: any) => {
                if (res.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "attachment successfully moved",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                        .then(() => {
                            dispatch(getFieldingRequestAttachmentList(aerialFielding.id))
                        })
                }
                else {
                    Swal.fire({
                        icon: "error",
                        title: "error move attachment",
                        text: res.statusText,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err: any) => {
                Swal.fire({
                    icon: "error",
                    title: "error move attachment",
                    text: err,
                    showConfirmButton: true,
                });
            });
    }

    const handleSearch = (e: any) => {
        e.preventDefault()
        getDataLog()
    }

    const handleClearSearch = () => {
        setLogPage(0)
        setLogTimeTaken(moment().format("MM/DD/YYYY HH:mm:ss"))
        setLogTimeOffset(0)
        setLogTimeSeq("")
        const dataPerPage = 10
        dispatch(getFieldingRequestAttachmentLogs({
            fieldingRequestId: aerialFielding.id,
            skip: logPage * dataPerPage,
            take: dataPerPage,
            detectedImageTakenDate: null,
            selectedUploadTimeOffset: null,
            poleSequence: null
        }))
    }

    return (
        <>
            <Modal className="image-editor-modal" open={modalMove}>
                <Card>
                    <CardHeader title="Move Attachment" />
                    <CardContent>
                        <form onSubmit={handleSubmit}>
                            {loadingPole
                                ? <>Loading</>
                                : (
                                    <Autocomplete
                                        options={
                                            poleList
                                                .sort((a: any, b: any) => {
                                                    if (a.poleSequence < b.poleSequence) {
                                                        return -1;
                                                    }
                                                    if (a.poleSequence > b.poleSequence) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                })
                                                .map((pole: any) => { return { id: pole.id, poleSequence: pole.poleSequence, poleNumber: pole.poleNumber } })
                                        }
                                        getOptionLabel={(pole: any) => {
                                            return `seq:${pole.poleSequence || ""},${pole.poleNumber || ""}`
                                        }}
                                        onChange={(e, value) => setPoleIdSelected(value.id)}
                                        renderInput={(params) => <TextField {...params} margin="dense" fullWidth label="Target Pole" />}
                                    />
                                )
                            }
                            <br />
                            <br />
                            <div className="wrapbutton">
                                <Button type="button" className="cancel" variant="outlined" onClick={() => setModalMove(false)}>
                                    <X /> Cancel
                                </Button>
                                {!loadingPole && (
                                    <Button type="submit" className="save" variant="outlined">
                                        <Move /> Move
                                    </Button>
                                )}
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </Modal>
            <div className='modalsketchlib image-modal'>
                <h3>
                    Image
                    <X onClick={props.onClose} />
                </h3>
                <div className="box">
                    <div className="slider">
                        <IconButton size='small'>
                            <ChevronLeft size='30' cursor='pointer' onClick={() => attachmentIndex > 0 && setAttachmentIndex(attachmentIndex - 1)} />
                        </IconButton>
                        {fieldingAttachmentListSorted[attachmentIndex]?.fileName.split('.')[1] === 'pdf'
                            ? <span>
                                <File />
                                <br />
                                {fieldingAttachmentListSorted[attachmentIndex]?.fileName}
                            </span>
                            : <img src={fieldingAttachmentListSorted[attachmentIndex]?.filePath?.includes(".com") ? fieldingAttachmentListSorted[attachmentIndex]?.filePath + '?width=500' : (baseUrl + "/" + fieldingAttachmentListSorted[attachmentIndex]?.filePath + '?width=500')} alt="image" />
                        }
                        <IconButton size='small'>
                            <ChevronRight size='30' cursor='pointer' onClick={() => attachmentIndex < fieldingAttachmentList.length - 1 && setAttachmentIndex(attachmentIndex + 1)} />
                        </IconButton>
                    </div>
                    <br />
                    <div className="footer">
                        <div></div>
                        <div className="option">
                            <Button variant='outlined' size='small' onClick={() => setModalMove(true)}>Move to pole</Button>
                            <Button variant='outlined' size='small' startIcon={<Download />} onClick={() => handleDownload(fieldingAttachmentListSorted[attachmentIndex])} disabled={downloadImages.isDownloading}>
                                {downloadImages.isDownloading
                                    ? "Downloading " + downloadImages.progressValue + "%"
                                    : "Download"
                                }
                            </Button>
                        </div>
                    </div>
                    <br />
                    <Typography variant="h6">Image Info</Typography>
                    <Typography className="bordered-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Timezone Job Location</td>
                                    <td>{timeOffsetUpload.filter((time: any) => time.offset === (fieldingAttachmentListSorted[attachmentIndex]?.selectedUploadTimeOffset || 0))[0]?.text}</td>
                                </tr>
                                <tr>
                                    <td>Filename</td>
                                    <td>{fieldingAttachmentListSorted[attachmentIndex]?.fileName}</td>
                                </tr>
                                <tr>
                                    <td>Image Date Taken Local</td>
                                    <td>{fieldingAttachmentListSorted[attachmentIndex]?.detectedImageTakenDate}</td>
                                </tr>
                                <tr>
                                    <td>Image Date Taken Server Time</td>
                                    <td>{fieldingAttachmentListSorted[attachmentIndex]?.convertedDetectedImageTakenDate}</td>
                                </tr>
                                <tr>
                                    <td>Upload Time ( Server Time (UTC-7) )</td>
                                    <td>{fieldingAttachmentListSorted[attachmentIndex]?.uploadDateString}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        {fieldingAttachmentListSorted[attachmentIndex]?.isOfficeUpload === true
                                            ? "Uploaded With Web"
                                            : fieldingAttachmentListSorted[attachmentIndex]?.isOfficeUpload === false
                                                ? "Uploaded With Fielder App"
                                                : "-"
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Typography>
                    <br />
                    <form onSubmit={handleSearch} style={{ marginBottom: "10px" }}>
                        <Typography variant="h6">Fielding Image Timer Logs :</Typography>
                        <TextField size="small" variant="outlined" label="Taken Date" value={logTimeTaken} onChange={(e) => { setLogTimeTaken(e.target.value); setLogTimeSeq("") }} />
                        {" "}
                        <TextField size="small" variant="outlined" label="Time Offset" value={logTimeOffset} onChange={(e) => { setLogTimeOffset(parseInt(e.target.value)); setLogTimeSeq("") }} select >
                            {timeOffset.map((time: any) => (
                                <MenuItem key={time.offset} value={time.offset} >{time.text}</MenuItem>
                            ))}
                        </TextField>
                        {" "}
                        <Button variant="contained" type="submit" disabled={!logTimeTaken || !logTimeOffset}>Search</Button>
                    </form>
                    <form onSubmit={handleSearch} style={{ marginBottom: "10px" }}>
                        <TextField size="small" variant="outlined" label="Pole Sequence" value={logTimeSeq} onChange={(e) => { setLogTimeSeq(e.target.value); setLogTimeTaken(""); setLogTimeOffset(0) }} />
                        {" "}
                        <Button variant="contained" type="submit" disabled={!logTimeSeq}>Search</Button>
                    </form>
                    <Button variant="contained" onClick={handleClearSearch}>Clear Search</Button>
                    <br />
                    {fieldingAttachmentLogs.data.map((log: any, i: number) =>
                        <Typography className="list-item" key={i}>
                            <span className="number">{(10 * logPage) + (i + 1)}.</span>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Pole Sequence</td>
                                        <td>:</td>
                                        <td>{log.poleSequence}</td>
                                    </tr>
                                    <tr>
                                        <td>Pole Number</td>
                                        <td>:</td>
                                        <td>{log.poleNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Start ( Server Time (UTC-7) ) </td>
                                        <td>:</td>
                                        <td>{log.startDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Stop ( Server Time (UTC-7) ) </td>
                                        <td>:</td>
                                        <td>{log.endDate}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>By {log.createdBy}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Typography>
                    )}
                    <IconButton onClick={() => setLogPage(logPage - 1)} disabled={logPage === 0}>
                        <ArrowLeft />
                    </IconButton>
                    <IconButton onClick={() => setLogPage(logPage + 1)} disabled={((logPage + 1) * 10) > fieldingAttachmentLogs.total}>
                        <ArrowRight />
                    </IconButton>
                </div>
            </div>
        </>
    );
}

export default AerialFieldingImageModal;