import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, LinearProgress, TextField, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Trash2, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import ImagePreview from "../../../../shared/ImagePreview";
import { baseUrl } from "../../../../state";
import { setMeasureMarkerData } from "../../../dashboard/dashboard.reducer";
import { createMeasureMarker, deleteMeasureMarkerAttachment, deleteTempMeasureMarker, editMeasureMarker, generateMeasureMarkerImages, getMeasureMarker, getMeasureMarkerDetails, getMeasureMarkerImageLogs } from "../../../poleInformation/poleInformation.api";
import { setListMeasureMarker } from "../../../poleInformation/poleInformation.reducer";
import { setLoading } from "../../sideTab.reducer";

export default function MeasureMarkerModal() {

  const dispatch = useDispatch<any>()

  const { measureMarkerData } = useSelector((state: RootState) => state.dashboard)
  const { fieldingRequestIdActive } = useSelector((state: RootState) => state.sideTab)
  const { listMeasureMarker, measureMarkerImageLogs } = useSelector((state: RootState) => state.poleInformation)

  const [form, setForm] = useState<any>({
    fieldingRequestId: fieldingRequestIdActive.id,
    recipientEmails: [],
    subject: "",
    note: "",
    latitude: "",
    longitude: "",
    startMeasurementMarkerId: null,
    sequence: "",
    length: "",
    userId: localStorage.getItem("id")
  })
  const [images, setImages] = useState<any>([])
  const [localImages, setLocalImages] = useState<any>([])
  const [imageSelected, setImageSelected] = useState<any>({})
  const [downloadImages, setDownloadImages] = useState<any>({
    isDownloading: false,
    progressValue: 70
  })

  useEffect(() => {
    if (Boolean(measureMarkerData.editModalStatus)) {
      dispatch(getMeasureMarkerDetails(measureMarkerData.editData?.id))
        .unwrap()
        .then((response: any) => {
          setImages(response.data.imageList)
          setForm({
            ...response.data,
            userId: localStorage.getItem("id")
          })
        })
      dispatch(getMeasureMarkerImageLogs(measureMarkerData.editData?.id))
    }
  }, [measureMarkerData])

  const handleChangeForm = (e: any) => {
    let name = e.target.name
    let value = e.target.value
    setForm({
      ...form,
      [name]: value
    })
  }

  const handleCancel = () => {
    dispatch(setMeasureMarkerData({}))
  }

  const handleDelete = () => {
    dispatch(deleteTempMeasureMarker(measureMarkerData.editData?.id))
      .unwrap()
      .then((res: any) => {
        dispatch(getMeasureMarker(measureMarkerData.editData?.fieldingRequestId))
          .unwrap()
          .then(async (response: any) => {
            dispatch(setListMeasureMarker(listMeasureMarker?.filter((item: any) => item?.fieldingRequestId !== measureMarkerData.editData?.fieldingRequestId).concat(response.data)));
            dispatch(setMeasureMarkerData({}))
          })
      })
  }

  const handleSubmit = () => {
    if (Boolean(measureMarkerData.newModalStatus)) {
      dispatch(createMeasureMarker({
        ...form,
        latitude: String(measureMarkerData.new.latitude),
        longitude: String(measureMarkerData.new.longitude)
      }))
        .unwrap()
        .then(async (res: any) => {
          if (res.status >= 400) {
            Swal.fire("Add measure marker failed!", res?.data?.message);
          }
          else {
            await uploadImages(res?.data?.id)
            await dispatch(getMeasureMarker(fieldingRequestIdActive.id))
              .unwrap()
              .then(async (response: any) => {
                dispatch(setListMeasureMarker(listMeasureMarker?.filter((item: any) => item?.fieldingRequestId !== fieldingRequestIdActive.id).concat(response.data)));
                dispatch(setMeasureMarkerData({}))
              })
          }
        })
    }
    else if (Boolean(measureMarkerData.editModalStatus)) {
      dispatch(editMeasureMarker({
        ...form
      }))
        .unwrap()
        .then(async (res: any) => {
          if (res.status >= 400) {
            Swal.fire("Edit measure marker failed!", res?.data?.message);
          }
          else {
            await uploadImages(measureMarkerData.editData?.id)
            await dispatch(getMeasureMarker(measureMarkerData.editData?.fieldingRequestId))
              .unwrap()
              .then((response: any) => {
                dispatch(setListMeasureMarker(listMeasureMarker?.filter((item: any) => item?.fieldingRequestId !== measureMarkerData.editData?.fieldingRequestId).concat(response.data)));
                dispatch(setMeasureMarkerData({}))
              })
          }
        })
    }
  }

  const uploadImages = async (parentId: string) => {
    for (const i in localImages) {
      let data = new FormData();
      data.append("measurementMarkerId", parentId)
      data.append("userId", (localStorage.getItem('id') || ""))
      data.append("", localImages[i].file)
      await axios.post(`${baseUrl}/api/MeasurementMarker/UploadImageAttachment`, data, {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
          setLocalImages(
            localImages.map((lastImage: any) => {
              if (lastImage.name === localImages[i].name) {
                return {
                  ...lastImage,
                  uploadProgress: percentCompleted
                }
              }
              else {
                return lastImage
              }
            })
          )
        }
      })
    }
    return true
  }

  const handleMove = () => {
    dispatch(setMeasureMarkerData({ ...measureMarkerData, editModalStatus: false }))
    Swal.fire({
      icon: "info",
      text: "Click on map to move"
    })
  }

  const handleChangeImages = (e: any) => {
    var files = Array.from(e.target.files);
    let x: any = []
    files.map((file: any) => {
      x.push({
        name: file.name,
        objectUrl: URL.createObjectURL(file),
        uploadProgress: 0,
        file: file
      })
    })
    setLocalImages(localImages.concat(x))
  }

  const handleDeleteAttachment = (id: string) => {
    dispatch(deleteMeasureMarkerAttachment(id))
      .unwrap()
      .then(async (res: any) => {
        if (res.status >= 400) {
          Swal.fire("Delete measure marker attachment failed!", res?.data?.message);
        }
        else {
          dispatch(getMeasureMarkerDetails(measureMarkerData.editData?.id))
            .unwrap()
            .then((response: any) => {
              setImages(response.data.imageList)
            })
        }
      })
  }

  const handleDownloadAllImage = () => {
    dispatch(setLoading(true))
    dispatch(generateMeasureMarkerImages(measureMarkerData.editData?.id))
      .unwrap()
      .then((res: any) => {
        dispatch(setLoading(false))
        let url = res.data
        axios({
          url: url,
          method: "GET",
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0));
            setDownloadImages({
              isDownloading: true,
              progressValue: percentCompleted
            })
          }
        })
          .then(response => {
            const downloadLink = document.createElement('a')
            downloadLink.href = window.URL.createObjectURL(new Blob([response.data]))
            downloadLink.setAttribute('download', url.split('/')[url.split('/').length - 1])
            document.body.appendChild(downloadLink)
            downloadLink.click()
            downloadLink.remove()
            setDownloadImages({
              isDownloading: false,
              progressValue: 0
            })
          })
      })
  }

  return (
    <div className="formproject">
      <ImagePreview isOpen={Boolean(imageSelected.id)} onClose={() => setImageSelected({})} src={imageSelected.filePath} name={imageSelected.fileName} />
      <h3>Measure Marker {measureMarkerData.newModalStatus && "New"}{measureMarkerData.editModalStatus && "Edit"} <X onClick={handleCancel} /></h3>
      <form>
        {measureMarkerData.editModalStatus && (
          <Fragment>
            <TextField
              className="inp"
              variant="outlined"
              label="Sequence"
              size="small"
              name="sequence"
              value={form.sequence}
              onChange={handleChangeForm}
            />
            <br />
          </Fragment>
        )}
        <TextField
          className="inp"
          variant="outlined"
          label="Subject"
          size="small"
          name="subject"
          value={form.subject}
          onChange={handleChangeForm}
        />
        <br />
        <TextField
          className="inp"
          variant="outlined"
          label="Feet"
          size="small"
          name="length"
          value={form.length}
          onChange={handleChangeForm}
        />
        <br />
        <TextField
          className="inp"
          variant="outlined"
          label="Note"
          size="small"
          name="note"
          value={form.note}
          onChange={handleChangeForm}
        />
        {images.length > 0 && (
          <div>
            <Button className="add" component="span" variant="contained" size="small" onClick={handleDownloadAllImage}>
              {downloadImages.isDownloading
                ? "Downloading " + downloadImages.progressValue + "%"
                : "Download All"
              }
            </Button>
          </div>
        )}
        <br />
        <div className="image-content">
          {images.map((image: any, index: number) => (
            <div key={index} className="image-container" onClick={() => setImageSelected(image)}>
              <img src={image.filePath} alt="image" />
              <div className="bottom-right">
                <IconButton size="small" onClick={() => handleDeleteAttachment(image.id)}>
                  <Trash2 color="red" />
                </IconButton>
              </div>
            </div>
          ))}
          {localImages?.map((image: any, index: number) => (
            <div key={index} className="image-container">
              <img src={image.objectUrl} alt="image" />
              {image.uploadProgress > 0 && (
                <div className="bottom-left">
                  <LinearProgress style={{ width: "150px" }} variant="determinate" value={image.uploadProgress} />
                </div>
              )}
            </div>
          ))}
        </div>
        <label htmlFor="button-file">
          <input
            style={{ display: "none" }}
            id="addImages"
            type="file"
            accept="image/*"
            multiple
            onChange={handleChangeImages}
          />
          <label htmlFor="addImages">
            <Button className="upload-btn" component="span">
              Add Images
            </Button>
          </label>
        </label>
        <br />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Image Logs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              {measureMarkerImageLogs.map((log: any, i: number) =>
                <div style={{ display: "flex", fontSize: "small" }}>
                  <span style={{ margin: 3 }}>{i + 1}.</span>
                  <table>
                    <tbody>
                      <tr>
                        <td>Start ( Server Time (UTC-7) ) </td>
                        <td>:</td>
                        <td>{log.startDate}</td>
                      </tr>
                      <tr>
                        <td>Stop ( Server Time (UTC-7) ) </td>
                        <td>:</td>
                        <td>{log.endDate}</td>
                      </tr>
                      <tr>
                        <td>By {log.createdBy}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <br />
        {measureMarkerData.editModalStatus && (<Button variant="contained" size="small" onClick={handleMove}>Move</Button>)}
        <div className="wrapbutton">
          {measureMarkerData.editModalStatus && (
            <Button className="delete" variant="outlined" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button className="save" variant="outlined" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}
