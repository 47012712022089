import { Button, Grid, TextField, MenuItem } from '@material-ui/core';
import { useState } from 'react';
import { CheckSquare, Trash2, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { handleModalRiserVgr, handleRiserAddType, handleRiserCanvasDotes, handleRiserCanvasDotesActive, handleRiserCanvasFences } from '../poleInformation.reducer';
import RiserCanvas from './canvas/RiserCanvas';

function ModalRiserVgr(props: any) {
    const dispatch = useDispatch<any>()

    const { riserAddType, riserCanvasDotes, poleEnums, riserCanvasDotesActive } = useSelector((state: RootState) => state.poleInformation)

    const [isItemShow, setIsItemShow] = useState<any>(false)
    const [value, setValue] = useState(0)
    const [type, setType] = useState(0)

    const handleAdditem = () => {
        dispatch(handleRiserAddType(''))
        setIsItemShow(!isItemShow)
    }

    const handleSet = () => {
        let lastGeneralRVGRSequence = riserCanvasDotes.length > 0 ? riserCanvasDotes.at(-1).generalRVGRSequence : '0'
        dispatch(handleRiserCanvasDotes([...riserCanvasDotes, {
            shapeX: 0,
            shapeY: 0,
            textX: 0,
            textY: 0,
            sequence: 0,
            name: '',
            value: value,
            type: type,
            imageType: 0,
            generalRVGRSequence: parseInt(lastGeneralRVGRSequence) + 1,
        }]))
        dispatch(handleRiserAddType('riserDot'))
        setIsItemShow(false)
        dispatch(handleRiserCanvasDotesActive(riserCanvasDotes.length))
    }

    const handleDelete = () => {
        let newRiserCanvasDotes = JSON.parse(JSON.stringify(riserCanvasDotes))
        newRiserCanvasDotes.splice(riserCanvasDotesActive, 1)
        dispatch(handleRiserCanvasDotes(newRiserCanvasDotes))
    }

    const handleClearFences = () => {
        dispatch(handleRiserCanvasFences([]))
    }

    const typeInitial = ["T", "C", "O", "P"]
    const valueInitial = ["1in", "2in", "3in", "4in", "VGR"]

    return (
        <div className='modalsketchlib'>
            <h3>
                Riser and VGR
                <X onClick={() => dispatch(handleModalRiserVgr())} />
            </h3>
            <div className="box">
                <div className="figsketch">
                    <RiserCanvas />
                </div>
                <i style={{ color: 'red' }}>
                    <small>
                        {
                            riserAddType === 'riserDot'
                                ? 'Click canvas to add item !'
                                : riserAddType === 'riserFence'
                                    ? 'Touch and drag to lay line indicate Fence'
                                    : riserAddType === 'riserDotMove'
                                        ? 'Click canvas to move item !'
                                        : null
                        }
                    </small>
                </i>
                <div className="anchorbut">
                    <Button variant='contained' onClick={handleAdditem}>Add item</Button>
                    <div className='cb'>
                        <Button variant='contained' onClick={() => dispatch(handleRiserAddType('riserFence'))}>add fence</Button>
                    </div>
                </div>
                {
                    isItemShow ?
                        <div >
                            <Grid item sm={4} className="risertype">
                                <TextField id="select" className='inp' label="Riser Type" variant='outlined' size='small' select name='value' value={value} onChange={(e) => setValue(parseInt(e.target.value))}>
                                    {poleEnums?.riserVgrTypes?.map((val: any) => {
                                        return (
                                            <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                        )
                                    })}
                                </TextField>
                                <TextField id="select" className='inp' label="Owner" variant='outlined' size='small' select name='type' value={type} onChange={(e) => setType(parseInt(e.target.value))}>
                                    {poleEnums?.downGuyOwners?.map((val: any) => {
                                        return (
                                            <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                        )
                                    })}
                                </TextField>
                                <Button variant='contained' onClick={handleSet}>SET</Button>
                            </Grid>
                        </div>
                        : null
                }

                <div className="anchorclear">
                    <Button variant='contained' onClick={handleClearFences}>clear fence</Button>
                </div>
                {riserCanvasDotes?.length > 0 &&
                    <>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item sm={10}>
                                <TextField id="select" className='inp' label="Select Active Point" value={riserCanvasDotesActive} variant='outlined' size='small' select onChange={(e) => { dispatch(handleRiserAddType('riserDotMove')); dispatch(handleRiserCanvasDotesActive(parseInt(e.target.value))) }}>
                                    {riserCanvasDotes.map((val: any, index: number) => {
                                        return <MenuItem key={index} value={index}>{val.generalRVGRSequence + "." + typeInitial[val.type] + "R - " + valueInitial[val.value]}</MenuItem>
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item sm={2}>
                                <Button fullWidth variant="contained" style={{ backgroundColor: "red", color: "white" }} onClick={handleDelete}><Trash2 /> Delete</Button>
                            </Grid>
                            <Grid item sm={12}>
                                <TextField id="select" className='inp' label="Size of Riser" value={riserCanvasDotes[riserCanvasDotesActive]?.value} variant='outlined' size='small' select >
                                    {poleEnums?.riserVgrTypes?.map((val: any) => {
                                        return (
                                            <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item sm={12}>
                                <TextField id="select" className='inp' label="Type" value={riserCanvasDotes[riserCanvasDotesActive]?.type} variant='outlined' size='small' select >
                                    {poleEnums?.downGuyOwners?.map((val: any) => {
                                        return (
                                            <MenuItem key={val.value} value={val.value}>{val.display}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                        </Grid>
                        <br />
                        <Grid item sm={12}>
                            <div className="wrapbutton">
                                <Button variant='outlined' className="create" onClick={() => dispatch(handleModalRiserVgr())} ><CheckSquare />Done</Button>
                            </div>
                        </Grid>
                    </>
                }
            </div>
        </div>
    );
}

export default ModalRiserVgr;