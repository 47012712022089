import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { handleIsFielderListModal } from '../assignFielding.reducer';


export default function FielderListModal() {

    const dispatch = useDispatch<any>()

    const { aerialFielding } = useSelector((state: RootState) => state.assignFielding)

    return (
        <div className='formproject'>
            <h3>
                List Fielder <X onClick={() => dispatch(handleIsFielderListModal(""))} />
            </h3>
            <ul>
                {aerialFielding?.assignedFielderNameAndEmails.map((v: any, i: number) => (
                    <li key={i}>
                        {v}
                    </li>
                ))}
            </ul>
        </div>
    )
}


