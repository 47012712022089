import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../app/store';
import { handleIsMapModalFielding } from '../assignFielding.reducer';
import LoadingCustom from '../../../shared/LoadingCustom';
import { GoogleMap, LoadScript, Marker, Polygon, Polyline } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import midspan from "../../../assets/icons/midspan.png"; import pole_color_red from "../../../assets/icons/pole_color_red.png";
import pole_color_blue from "../../../assets/icons/pole_color_blue.png";
import pole_color_white from "../../../assets/icons/pole_color_white.png";
import pole_color_green from "../../../assets/icons/pole_color_green.png";
import pole_color_black from "../../../assets/icons/pole_color_black.png";
import pole_color_yellow from "../../../assets/icons/pole_color_yellow.png";
import pole_color_purple from "../../../assets/icons/pole_color_purple.png";
import pole_color_orange from "../../../assets/icons/pole_color_orange.png";
import pole_color_gray from "../../../assets/icons/pole_color_gray.png";
import pole_color_brown from "../../../assets/icons/pole_color_brown.png";
import pole_color_cyan from "../../../assets/icons/pole_color_cyan.png";
import pole_type_full from "../../../assets/icons/pole_type_full.png";
import pole_type_support from "../../../assets/icons/pole_type_support.png";
import pole_type_reference from "../../../assets/icons/pole_type_reference.png";
import pole_full from "../../../assets/icons/pole_full.png";
import pole_unfill from "../../../assets/icons/pole_unfill.png";
import pole_transparent from "../../../assets/icons/pole_transparent.png";
import mergeImages from 'merge-images'

function MapModalFielding() {

    const dispatch = useDispatch<any>()

    const { loading, mapPoleList } = useSelector((state: RootState) => state.assignFielding)
    const { listPoleFieldingRequest, listMidspanFieldingRequest, listMarkerFieldingRequest } = useSelector((state: RootState) => state.poleInformation);
    const { noteEnums } = useSelector((state: RootState) => state.sideTab);

    const [icons, setIcons] = useState<any>([])
    const [infoSpan, setInfoSpan] = useState<any>(null);

    useEffect(() => {
        generateIcon()
    }, [])

    const generateIcon = async () => {
        const colorList = [pole_color_white, pole_color_red, pole_color_blue, pole_color_white, pole_color_green, pole_color_black, pole_color_yellow, pole_color_purple, pole_color_orange, pole_color_gray, pole_color_brown, pole_color_cyan]
        const fullList = [pole_transparent, pole_full]
        const unFillList = [pole_transparent, pole_unfill]
        const typeList = [pole_type_full, pole_type_support, pole_type_reference]
        let result: any = []
        for (let c = 0; c < colorList.length; c++) {
            result = [...result, []]
            for (let f = 0; f < fullList.length; f++) {
                result[c] = [...result[c], []]
                for (let u = 0; u < unFillList.length; u++) {
                    result[c][f] = [...result[c][f], []]
                    for (let t = 0; t < typeList.length; t++) {
                        let r = await new Promise(resolve => {
                            mergeImages([colorList[c], fullList[f], unFillList[u], typeList[t]])
                                .then((b64: any) => {
                                    resolve(b64)
                                })
                        })
                        result[c][f][u] = [...result[c][f][u], r]
                    }
                }
            }
        }
        setIcons(result)
    }

    const onLoad = (map: any) => { };

    const handleSpanHoverIn = (e: any, length: string) => {
        setInfoSpan({
            text: length,
            position: { "lat": e.latLng.lat(), "lng": e.latLng.lng() }
        })
    }

    const handleSpanHoverOut = (e: any) => {
        setInfoSpan(null)
    }

    const noteColorId2Name = (id: number) => {
        let markerColors = noteEnums.markerColors
        let markerColor = markerColors.filter((item: any) => { return item.value === id })
        return markerColor[0]?.display
    }

    return (
        <div className='map-modal'>
            <div className="header">
                Location Fielding Request
                <X onClick={() => dispatch(handleIsMapModalFielding())} />
            </div>
            <LoadingCustom isLoading={loading} />
            <LoadScript googleMapsApiKey="AIzaSyDQ-aesOz_ut1yBv_d6FG-0N60uqDwgLZs" libraries={["places"]}>
                <GoogleMap
                    mapContainerStyle={{
                        height: "100%",
                        width: "100%",
                    }}
                    onLoad={onLoad}
                    center={listPoleFieldingRequest.length > 0 ? { lat: parseFloat(listPoleFieldingRequest[0]?.latitude), lng: parseFloat(listPoleFieldingRequest[0]?.longitude) } : { lat: 0, lng: 0 }}
                    zoom={10}
                    mapTypeId="terrain"
                    clickableIcons={false}
                >
                    {listPoleFieldingRequest.map((res: any, i: number) => {
                        if (icons.length === 0) {
                            return null
                        }
                        if (!res.isActive) {
                            return null
                        }
                        return (
                            <>
                                <Marker
                                    key={i + (listPoleFieldingRequest.length * 3)}
                                    position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                                    icon={icons[res.color >= 12 ? 0 : res.color][res.fieldingStatus === 2 ? 1 : 0][res.isMandatoryDataFilled === false && res.fieldingStatus === 2 ? 1 : 0][res.type]}
                                    title={
                                        "seq: " + res?.poleSequence + "\n" +
                                        (res?.poleNumber || "")
                                    }
                                />
                                {
                                    res.spanDirectionList.map((line: any, lineIndex: number) => {
                                        if (line.color !== 'string') {
                                            return (
                                                <Polyline
                                                    key={lineIndex}
                                                    path={JSON.parse(line.lineData)}
                                                    onMouseOver={(e: any) => handleSpanHoverIn(e, line.length)}
                                                    onMouseOut={handleSpanHoverOut}
                                                    options={{
                                                        strokeColor: line.color,
                                                        strokeOpacity: 0.8,
                                                        strokeWeight: 7,
                                                        //@ts-ignore
                                                        fillColor: '#FF0000',
                                                        fillOpacity: 0.35,
                                                        clickable: true,
                                                        draggable: false,
                                                        editable: false,
                                                        visible: true,
                                                        radius: 30000,
                                                        paths: line.data,
                                                        zIndex: 1
                                                    }}
                                                />
                                            )
                                        }
                                    })
                                }
                            </>
                        );
                    })}
                    {listMidspanFieldingRequest.map((res: any, i: number) => {
                        return (
                            <>
                                <Marker
                                    key={i}
                                    position={{ lat: parseFloat(res.latitude), lng: parseFloat(res.longitude) }}
                                    icon={midspan}
                                    title={"M" + res?.sequence}
                                />
                                {
                                    (res.span !== '' ? JSON.parse(res?.span) : []).map((line: any, lineIndex: number) => {
                                        if (line.color !== 'string') {
                                            return (
                                                <Polyline
                                                    key={lineIndex}
                                                    path={typeof (line.lineData) === "string" ? JSON.parse(line.lineData) : line.lineData}
                                                    onMouseOver={(e: any) => handleSpanHoverIn(e, line.length)}
                                                    onMouseOut={handleSpanHoverOut}
                                                    options={{
                                                        strokeColor: line.color,
                                                        strokeOpacity: 0.8,
                                                        strokeWeight: 7,
                                                        //@ts-ignore
                                                        fillColor: '#FF0000',
                                                        fillOpacity: 0.35,
                                                        clickable: true,
                                                        draggable: false,
                                                        editable: false,
                                                        visible: true,
                                                        radius: 30000,
                                                        paths: line.data,
                                                        zIndex: 1
                                                    }}
                                                />
                                            )
                                        }
                                    })
                                }
                            </>
                        );
                    })}
                    {listMarkerFieldingRequest.map((marker: any) => (
                        (marker.type === 1
                            ? (
                                <Polygon
                                    key={marker.id}
                                    path={JSON.parse(marker?.lineData)}
                                    options={{ strokeOpacity: 0.8, strokeColor: noteColorId2Name(marker.markerColor), fillColor: noteColorId2Name(marker.markerColor) }}
                                />
                            )
                            : null
                        )
                    ))}
                </GoogleMap>
            </LoadScript>
        </div>
    );
}

export default MapModalFielding;