import { Button, TextField, Tooltip, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setAerialFielding } from "../../../assignFielding/assignFielding.reducer";
import {
  handleLayerActive,
  handleNotesActive,
  handleNotesDeactive,
  setDetailMenu,
  setJobIdList,
  setFieldingIdList,
  handleNoteIcon,
  handleFieldingIcon,
  setNoteList,
  handleModalJobNumber,
  handleFormTypeJobNumber,
  setLoadingRequestIdList,
  handleJobNumberIdActive,
  setJobDetail,
  setSidetabActive
} from "../../sideTab.reducer";
import pencilBlack from "../../../../assets/icons/pencilBlack.png";
import pencilGreen from "../../../../assets/icons/pencilGreen.png";
import { getPoleFieldingRequestByJobNumber } from "../../../poleInformation/poleInformation.api";
import { setListPoleFieldingRequest, setListPoleLoadingRequest } from "../../../poleInformation/poleInformation.reducer";
import { archiveJobNumber, completeJobNumber, getFieldingRequestId, getJobNumber, getJobNumberAttachmentList, getNoteListByJobNumberId } from "../../sideTab.api";
import Swal from "sweetalert2";
import ModalTemplate from "../../../../shared/ModalTemplate";
import DeletedItems from "../jobdetailmenu/DeletedItems";
import { postLogin } from "../../../login/login.api";

export default function JobDetail() {

  const dispatch = useDispatch<any>()

  const { isLayerActive, jobNumberIdActive, jobIdList, fieldingIdList, noteList, projectIdActive, detailMenu, statusJobNumber, sideTabActive } = useSelector((state: RootState) => state.sideTab);
  const { listPoleFieldingRequest } = useSelector((state: RootState) => state.poleInformation);

  const [modalTrash, setModalTrash] = useState(false)

  useEffect(() => {
    if (jobNumberIdActive.id) {
      dispatch(setDetailMenu(jobdetailMenu[1]));
      dispatch(setSidetabActive({ ...sideTabActive, jobDetail: true, jobDetailMenu: true }))
    }
  }, [jobNumberIdActive])

  const jobdetailMenu = [
    { id: 7, label: "Pole Inventory" },
    { id: 1, label: "Fielding Request" },
    { id: 2, label: "Notes", icon: true },
    { id: 6, label: "Deleted Items" },
    { id: 3, label: "Export" },
    { id: 4, label: "Supporting Docs" },
    { id: 5, label: "Pole Loading Request" }
  ];

  const jobdetailMenuComplete = [
    { id: 7, label: "Pole Inventory" },
    { id: 1, label: "Fielding Request" },
    { id: 2, label: "Notes", icon: true },
    { id: 6, label: "Deleted Items" },
    { id: 3, label: "Export" },
    { id: 4, label: "Supporting Docs" },
    { id: 5, label: "Pole Loading" }
  ];

  const handleJobDetail = (data: any) => {
    if (data.id === 6) {
      setModalTrash(true)
      return
    }
    dispatch(handleLayerActive({}))
    dispatch(handleFieldingIcon(null));
    if (data.id === detailMenu.id) {
      dispatch(setDetailMenu({}));
      dispatch(setAerialFielding({}));
    }
    else {
      dispatch(setDetailMenu(data));
      dispatch(setSidetabActive({ ...sideTabActive, jobDetail: true, jobDetailMenu: true }))
    }
    if (data.label === "Notes") {
      dispatch(getNoteListByJobNumberId(jobNumberIdActive.id))
        .unwrap()
        .then((res: any) => {
          let noteListNew: any = noteList?.filter((item: any) => item.jobNumberId !== jobNumberIdActive.id)
          dispatch(setNoteList(noteListNew.concat(res.data)))
        })
      dispatch(handleNotesActive());
      if (!isLayerActive.status) {
        if (!jobIdList.includes(jobNumberIdActive.id)) {
          dispatch(setJobIdList([...jobIdList, jobNumberIdActive.id]))
        }
        dispatch(getPoleFieldingRequestByJobNumber(jobNumberIdActive.id))
          .unwrap()
          .then((res: any) => {
            let idList: any = []
            res.data.map((pole: any) => {
              idList.push(pole.id)
            })
            let poleFieldingRequest: any = listPoleFieldingRequest?.filter((item: any) => !idList.includes(item?.id));
            dispatch(setListPoleFieldingRequest(poleFieldingRequest.concat(res.data)));
          })
        dispatch(getFieldingRequestId(jobNumberIdActive.id))
          .unwrap()
          .then((res: any) => {
            let idList: any = []
            res.data.map((fielding: any) => {
              if (!idList.includes(fielding.id)) {
                idList.push(fielding.id)
              }
            })
            let fieldingIdListNew: any = fieldingIdList?.filter((item: any) => !idList.includes(item));
            dispatch(setFieldingIdList(fieldingIdListNew.concat(idList)))
          })
      }
    }
    else {
      dispatch(handleNotesDeactive());
    }
    if (data.id === 4) {
      dispatch(getJobNumberAttachmentList(jobNumberIdActive.id))
    }
  };

  const handleClickStar = () => {
    handleJobDetail(jobdetailMenu[1])
    dispatch(setDetailMenu(jobdetailMenu[1]));
    dispatch(handleFieldingIcon(null))
    if (!isLayerActive.status) {
      dispatch(handleNoteIcon(0))
    }
    else {
      dispatch(handleNoteIcon(null))
    }
    dispatch(handleLayerActive({ ...isLayerActive, note: true, status: !isLayerActive.status }))
  }

  const handleComplete = () => {
    Swal.fire({
      title: "Are you sure you want to complete the job?",
      text: "Confirm by entering your account's password:",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
      input: 'password',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return dispatch(postLogin({
          userName: localStorage.getItem('email'),
          password: login
        }))
          .unwrap()
          .then((res: any) => {
            if (res.status >= 400) {
              Swal.showValidationMessage(
                `${res.data || Object.values(res.data.errors)}`
              )
            }
          })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(completeJobNumber(jobNumberIdActive?.id))
          .unwrap()
          .then((res: any) => {
            if (res.status >= 400) {
              Swal.fire(
                'Set completed failed',
                'error'
              )
            } else {
              dispatch(getJobNumber({
                id: projectIdActive?.id,
                status: statusJobNumber
              }))
              Swal.fire({
                icon: 'success',
                title: 'Job Successfully Completed',
                showConfirmButton: false,
                timer: 1500
              })
                .then(() => {
                  dispatch(setFieldingIdList([]))
                  dispatch(setListPoleFieldingRequest([]))
                  dispatch(setLoadingRequestIdList([]))
                  dispatch(setListPoleLoadingRequest([]))
                  dispatch(handleJobNumberIdActive({}))
                  dispatch(setNoteList([]))
                  dispatch(setAerialFielding({}));
                  dispatch(setJobDetail(null));
                })
            }
          })
      }
    })
  }

  const handleReactive = () => {
    dispatch(handleModalJobNumber());
    dispatch(handleFormTypeJobNumber("reactive"));
  }

  const handleArchive = () => {
    Swal.fire({
      title: 'Are you sure you want to archive this job?',
      text: "You wonâ€™t be able to undo this change!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(archiveJobNumber(jobNumberIdActive?.id))
          .unwrap()
          .then((res: any) => {
            if (res.status >= 400) {
              Swal.fire(
                'Set archived failed',
                'error'
              )
            } else {
              dispatch(getJobNumber({
                id: projectIdActive?.id,
                status: statusJobNumber
              }))
              Swal.fire({
                icon: 'success',
                title: 'Job Successfully Archived',
                showConfirmButton: false,
                timer: 1500
              })
                .then(() => {
                  dispatch(setFieldingIdList([]))
                  dispatch(setListPoleFieldingRequest([]))
                  dispatch(setLoadingRequestIdList([]))
                  dispatch(setListPoleLoadingRequest([]))
                  dispatch(handleJobNumberIdActive({}))
                  dispatch(setNoteList([]))
                  dispatch(setAerialFielding({}));
                  dispatch(setJobDetail(null));
                })
            }
          })
      }
    })
  }

  return (
    <div className="wraptab">
      <ModalTemplate onOpen={modalTrash} component={() => <DeletedItems onClose={() => setModalTrash(false)} />} />
      <Typography variant="subtitle1" align="center" style={{ fontWeight: "bold" }}>Job detail for</Typography>
      <Typography variant="subtitle1" align="center">Job Number:</Typography>
      <Typography variant="subtitle1" align="center">{jobNumberIdActive.name}</Typography>
      <TextField className='inp' disabled label="Job Due Date" value={jobNumberIdActive.dueDateString} size="small" />
      <TextField className='inp' disabled value={jobNumberIdActive.totalPoles} label="Total Poles" size="small" />
      <ul>
        {(statusJobNumber > 0 ? jobdetailMenuComplete : jobdetailMenu).map((menu, i) => (
          <li key={i} className={menu.icon ? "notes" : ""} style={menu.id === detailMenu.id ? { backgroundColor: "#cbced2" } : {}}>
            <span onClick={() => handleJobDetail(menu)}>{menu.label}</span>
            {(menu.icon && statusJobNumber === 0) && (<Tooltip title="Add notes"><img src={isLayerActive.note && isLayerActive.status ? pencilGreen : pencilBlack} alt="star" onClick={handleClickStar} /></Tooltip>)}
          </li>
        ))}
      </ul>
      {statusJobNumber === 0
        ? (
          <Button className="complete-Button" onClick={handleComplete}>Complete Job</Button>
        )
        : statusJobNumber === 1
          ? (
            <Button className="complete-Button" onClick={handleReactive}>Reactivate Job</Button>
          )
          : null
      }
      {statusJobNumber === 1 && (
        <Button className="archive-Button" onClick={handleArchive}>Archive Job</Button>
      )}
    </div>
  );
};