import { Button } from '@material-ui/core';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { RootState } from '../../../../app/store';
import { baseUrl } from '../../../../state';
import { generateKmlJob, generatePictureJob } from '../../sideTab.api';
import { setLoading } from '../../sideTab.reducer';

function Export() {

    const dispatch = useDispatch<any>()

    const { jobNumberIdActive } = useSelector((state: RootState) => state.sideTab)

    const handleExportKml = () => {
        dispatch(setLoading(true))
        dispatch(generateKmlJob(jobNumberIdActive.id))
            .unwrap()
            .then((res: any) => {
                let arr = res.data.split("/")
                const downloadLink = document.createElement('a');
                downloadLink.href = baseUrl + "/api/Global/Download?path=" + res.data
                downloadLink.setAttribute('download', arr[arr.length - 1]);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                dispatch(setLoading(false))
            })
    }

    const handleExportPicture = () => {
        dispatch(setLoading(true))
        dispatch(generatePictureJob(jobNumberIdActive.id))
            .unwrap()
            .then((res: any) => {
                let arr = res
                const downloadLink = document.createElement('a');
                downloadLink.href = res
                downloadLink.setAttribute('download', arr[arr.length - 1]);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                dispatch(setLoading(false))
            })
    }

    const handleDownloadPdf = () => {
        const newWindow = window.open('/JobNumberPdf?jobNumberId=' + jobNumberIdActive.id, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const checkValue = (value: string | null) => {
        if (value) {
            if (value === '-') {
                return value
            }
            else if (value.charAt(0) === '-') {
                return value.substring(1).replaceAll(',', '.').replaceAll('\n', '. ')
            }
            else {
                return value.replaceAll(',', '.').replaceAll('\n', '. ')
            }
        }
        else {
            return '-'
        }
    }

    const handleExportCsv = () => {
        Swal.fire({
            title: "Loading",
            text: "loading CSV data",
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const jobNumberId = jobNumberIdActive.id
        axios.get(baseUrl + "/api/JobNumber/GetPDFData?targetJobNumberId=" + jobNumberId + "&userId=" + localStorage.getItem("id"))
            .then(response => {
                var data = response?.data?.data
                var dataString = ""
                let isAllMM = data?.fieldingRequests?.every((fielding: any) => fielding.name.includes("MM"))
                let isAllMre = data?.fieldingRequests?.every((fielding: any) => fielding.fieldingRequestTypeString === "MREEstimation")
                let isAllPen = data?.fieldingRequests?.every((fielding: any) => fielding.fieldingRequestTypeString === "PenAndPaper")
                if (isAllMM) {
                    dataString += [
                        "MM Request",
                        "MM Sequence",
                        "Lat",
                        "Long",
                        "Length",
                        "Notes"
                    ].join(",")
                    dataString += "\n"
                    data?.fieldingRequests?.map((fielding: any) => {
                        fielding?.measurementMarkers?.map((pole: any) => {
                            dataString += [
                                checkValue(fielding?.name),
                                checkValue(pole?.sequence),
                                pole?.latitude,
                                pole?.longitude,
                                checkValue(pole?.length),
                                checkValue(pole?.note)
                            ].join(",")
                            dataString += "\n"
                        })
                    })
                }
                else if (isAllPen) {
                    dataString += [
                        "Fielding Request",
                        "Pole Type",
                        "Pole Sequence",
                        "Lat",
                        "Long",
                        "Street Name",
                        "Notes"
                    ].join(",")
                    dataString += "\n"
                    data?.fieldingRequests?.map((fielding: any) => {
                        fielding?.poles?.map((pole: any) => {
                            dataString += [
                                checkValue(fielding?.name),
                                checkValue(pole?.typeString),
                                checkValue(pole?.poleSequence),
                                pole?.latitude,
                                pole?.longitude,
                                checkValue(pole?.street),
                                checkValue(pole?.notes)
                            ].join(",")
                            dataString += "\n"
                        })
                    })
                }
                else if (isAllMre) {
                    dataString += [
                        "Fielding Request",
                        "Pole Type",
                        "Pole Sequence",
                        "MRE Estimation",
                        "Lat",
                        "Long",
                        "Street Name",
                        "Notes"
                    ].join(",")
                    dataString += "\n"
                    data?.fieldingRequests?.map((fielding: any) => {
                        fielding?.poles?.map((pole: any) => {
                            dataString += [
                                checkValue(fielding?.name),
                                checkValue(pole?.typeString),
                                checkValue(pole?.poleSequence),
                                checkValue(pole?.mreRequirementsString),
                                pole?.latitude,
                                pole?.longitude,
                                checkValue(pole?.street),
                                checkValue(pole?.notes)
                            ].join(",")
                            dataString += "\n"
                        })
                    })
                }
                else {
                    dataString += [
                        "Fielding Request",
                        "Pole Type",
                        "Pole Sequence",
                        "Pole Number/Tag",
                        "Lat",
                        "Long",
                        "Street Name",
                        "FAP Terminal",
                        "OSMOSE Number",
                        "Other Number",
                        "Ground Line Circumference",
                        "Year",
                        "Pole Condition",
                        "MRE Estimation",
                        "Radio Antena",
                        "Pole Stamp",
                        "Length",
                        "Pole Class",
                        "Species",
                        "Pole Set Surfaces",
                        "C-Truss",
                        "C-Tree Trim",
                        "PL VIS Strips",
                        "PL VGR",
                        "INS DG",
                        "RMV ABN DW'S",
                        "Reloc DW'S Out Of Climbing Space",
                        "PL GG On DG",
                        "Notes"
                    ].join(",")
                    dataString += "\n"
                    data?.fieldingRequests?.map((fielding: any) => {
                        fielding?.poles?.map((pole: any) => {
                            dataString += [
                                checkValue(fielding?.name),
                                checkValue(pole?.typeString),
                                checkValue(pole?.poleSequence),
                                pole?.isPoleNumberUnknown ? "UNK" : checkValue(pole?.poleNumber),
                                pole?.latitude,
                                pole?.longitude,
                                checkValue(pole?.street),
                                pole?.isFAPUnknown ? "UNK" : checkValue(pole?.vapTerminal),
                                pole?.isOsmoseUnknown ? "UNK" : checkValue(pole?.osmose),
                                pole?.isOtherNumberUnknown ? "UNK" : checkValue(pole?.otherNumber),
                                pole?.isGroundLineUnknown ? "UNK" : ((checkValue(pole?.groundCircumference)) + (pole?.isGroundLineEstimated ? ". EST" : "")),
                                pole?.isYearUnknown ? "UNK" : ((checkValue(pole?.poleYear)) + (pole?.isYearEstimated ? ". EST" : "")),
                                checkValue(pole?.poleConditionString),
                                checkValue(pole?.mreRequirementsString),
                                pole?.isRadioAntenna ? 'Yes' : 'No',
                                pole?.poleStamp ? 'Yes' : 'No',
                                pole?.isPoleLengthUnknown ? "UNK" : (pole?.poleHeightString + 'ft' + (pole?.isPoleLengthEstimated ? ". EST" : "")),
                                pole?.isPoleClassUnknown ? "UNK" : (pole?.poleClassString + (pole?.isPoleClassEstimated ? ". EST" : "")),
                                pole?.isSpeciesUnknown ? "UNK" : (pole?.poleSpeciesString + (pole?.isSpeciesEstimated ? ". EST" : "")),
                                checkValue(pole?.poleSetSurfacesString),
                                pole?.cTruss === true ? "Yes" : pole?.cTruss === false ? "No" : "-",
                                pole?.treeTrim === true ? "Yes" : pole?.treeTrim === false ? "No" : "-",
                                pole?.pL_VIS_Strips === true ? "Yes" : pole?.pL_VIS_Strips === false ? "No" : "-",
                                pole?.pL_VGR === true ? "Yes" : pole?.pL_VGR === false ? "No" : "-",
                                pole?.inS_DG === true ? "Yes" : pole?.inS_DG === false ? "No" : "-",
                                pole?.rmV_ABN_DWS === true ? "Yes" : pole?.rmV_ABN_DWS === false ? "No" : "-",
                                pole?.relocDWS === true ? "Yes" : pole?.relocDWS === false ? "No" : "-",
                                pole?.pL_GG_OnDG === true ? "Yes" : pole?.pL_GG_OnDG === false ? "No" : "-",
                                checkValue(pole?.notes)
                            ].join(",")
                            dataString += "\n"
                        })
                    })
                }
                var dataBlob = new Blob([dataString], { type: 'text/csv' });
                var csvURL = window.URL.createObjectURL(dataBlob);
                let tempLink = document.createElement('a');
                tempLink.href = csvURL;
                tempLink.setAttribute('download', `${jobNumberIdActive.name}.csv`);
                tempLink.click();
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                Swal.close()
            })
    }

    return (
        <div>
            <Button className='export-btn' onClick={handleExportKml}>EXPORT KML</Button>
            <Button className='export-btn' onClick={handleDownloadPdf}>EXPORT PDF FILE</Button>
            <Button className='export-btn' onClick={handleExportPicture}>DOWNLOAD PICTURES</Button>
            <Button className='export-btn' onClick={handleExportCsv}>EXPORT SPREADSHEET</Button>
        </div>
    );
}

export default Export;