import { Button, Card, CardContent, CardHeader, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Fragment, useEffect, useState } from "react";
import { ExternalLink, Save, Trash, X } from "react-feather";
import { deleteLoadingRequest, editLoadingRequest } from "../../../sideTab/sideTab.api";
import moment from "moment";
import { completeLoadingRequest, postPoleLoadingResultLink, postPoleLoadingSupportingLink } from "../poleloading.api";
import axios from "axios";
import { baseUrl } from "../../../../state";
import { Link } from "@material-ui/icons";

export default function LoadingRequestDetail(props: any) {

  const dispatch = useDispatch<any>();

  const [values, setValues] = useState<any>({});
  const [supportingLinkList, setSupportingLinkList] = useState<any>([]);
  const [supportingFileList, setSupportingFileList] = useState<any>([]);
  const [supportingFileUploadProgress, setSupportingFileUploadProgress] = useState(100);
  const [isShowAddSupportingLink, setIsShowAddSupportingLink] = useState(false);
  const [supportingLinkForm, setSupportingLinkForm] = useState("");
  const [resultLinkList, setResultLinkList] = useState<any>([]);
  const [resultFileList, setResultFileList] = useState<any>([]);
  const [resultFileUploadProgress, setResultFileUploadProgress] = useState(100);
  const [isShowAddResultLink, setIsShowAddResultLink] = useState(false);
  const [resultLinkForm, setResultLinkForm] = useState("");

  useEffect(() => {
    setValues({
      ...props.values,
      dueDateString:
        props.values.dueDateString.length > 0
          ?
          props.values.dueDateString.split('/')[2] + '-' +
          props.values.dueDateString.split('/')[0] + '-' +
          props.values.dueDateString.split('/')[1]
          : null
    })
    setSupportingLinkList(
      props.values.supportingLinkList.map((d: any) => {
        return d.link
      })
    )
    setSupportingFileList(
      props.values.supportingDocumentList.map((d: any) => {
        return {
          fileName: d.fileName,
          filePath: d.filePath
        }
      })
    )
    setResultLinkList(
      props.values.resultLinkList.map((d: any) => {
        return d.link
      })
    )
    setResultFileList(
      props.values.resultDocumentList.map((d: any) => {
        return {
          fileName: d.fileName,
          filePath: d.filePath
        }
      })
    )
  }, [])

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleDelete = () => {
    dispatch(deleteLoadingRequest(values.id))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Delete loading request failed!");
        } else {
          Swal.fire({
            icon: "success",
            title: "Loading request successfully deleted",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            props.handleClose()
          });
        }
      })
  }

  const handleEdit = (e: any) => {
    e.preventDefault();
    dispatch(editLoadingRequest({
      id: values.poleLoadingId,
      description: values.description,
      dueDateString: moment(new Date(values.dueDateString)).format("MM/DD/yyyy"),
      calcSpecManagerIds: values.assignedCalcSpecManagerIds ? values.assignedCalcSpecManagerIds.split(', ') : [],
      calcSpecIds: values.assignedCalcSpecIds ? values.assignedCalcSpecIds.split(', ') : []
    }))
      .unwrap()
      .then((res: any) => {
        console.log(res, "saya resssssss");
        if (res.status >= 400) {
          Swal.fire("Edit Loading Request failed!");
        } else {
          Swal.fire({
            icon: "success",
            title: "Loading request successfully edited",
            showConfirmButton: false,
            timer: 1500,
          }).then((res: any) => {
            props.handleClose();
          });
        }
      });
  };

  const handleAddSupportingLink = () => {
    dispatch(postPoleLoadingSupportingLink({
      poleLoadingId: values.poleLoadingId,
      links: [supportingLinkForm],
      userId: localStorage.getItem("id")
    }))
    setSupportingLinkList([...supportingLinkList, supportingLinkForm])
    setSupportingLinkForm("")
    setIsShowAddSupportingLink(false)
  }

  const handleAddResultLink = () => {
    dispatch(postPoleLoadingResultLink({
      poleLoadingId: values.poleLoadingId,
      links: [resultLinkForm],
      userId: localStorage.getItem("id")
    }))
    setResultLinkList([...resultLinkList, resultLinkForm])
    setResultLinkForm("")
    setIsShowAddResultLink(false)
  }

  const onChangeFileSupporting = async (e: any) => {
    let data = new FormData();
    data.append("poleLoadingId", values.poleLoadingId)
    data.append("file", e.target.files[0])
    try {
      let result = await axios.post(`${baseUrl}/api/ocalc/UploadSupportingAttachments`, data, {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
          setSupportingFileUploadProgress(percentCompleted)
        }
      })
      setSupportingFileList([
        ...supportingFileList,
        {
          fileName: result.data.data[0].fileName,
          filePath: result.data.data[0].filePath
        }
      ])
    }
    catch (error: any) {
      console.log(error);
    }
  }

  const onChangeFileResult = async (e: any) => {
    let data = new FormData();
    data.append("poleLoadingId", values.poleLoadingId)
    data.append("file", e.target.files[0])
    try {
      let result = await axios.post(`${baseUrl}/api/ocalc/UploadResultAttachments`, data, {
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
          setResultFileUploadProgress(percentCompleted)
        }
      })
      setResultFileList([
        ...resultFileList,
        {
          fileName: result.data.data[0].fileName,
          filePath: result.data.data[0].filePath
        }
      ])
    }
    catch (error: any) {
      console.log(error);
    }
  }

  const handleComplete = () => {
    dispatch(completeLoadingRequest(values.poleLoadingId))
      .unwrap()
      .then((res: any) => {
        console.log(res, "saya resssssss");
        if (res.status >= 400) {
          Swal.fire("failed");
        } else {
          Swal.fire({
            icon: "success",
            title: "Loading request successfully completed",
            showConfirmButton: false,
            timer: 1500,
          }).then((res: any) => {
            props.handleClose();
          });
        }
      });
  }

  return (
    <div className="formproject">
      <h3>Detail Loading Request<X onClick={props.handleClose} /></h3>
      <form onSubmit={handleEdit}>
        <TextField
          className="inp"
          variant="outlined"
          label="Project"
          size="small"
          value={values.project}
          disabled
        />
        <TextField
          className="inp"
          variant="outlined"
          label="Project"
          size="small"
          value={values.jobNumber}
          disabled
        />
        <TextField
          className="inp"
          variant="outlined"
          type="text"
          label="Description"
          size="small"
          multiline
          name="description"
          value={values.description}
          onChange={handleChange}
        />
        <TextField
          className="inp"
          variant="outlined"
          type="date"
          label="Due Date"
          name="dueDateString"
          value={values.dueDateString}
          onChange={handleChange}
        />
        <Card>
          <CardHeader
            subheader="Supporting Files"
          />
          <CardContent>
            <List dense={true}>
              {supportingFileList.map((file: any) => (
                <ListItem>
                  <ListItemText
                    primary={file.fileName}
                  />
                  <ListItemSecondaryAction>
                    <a href={file.filePath} target="_blank">
                      <IconButton
                        edge="end">
                        <ExternalLink />
                      </IconButton>
                    </a>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              {supportingLinkList.map((link: any) => (
                <ListItem>
                  <ListItemText
                    primary={link}
                  />
                  <ListItemSecondaryAction>
                    <a href={link.includes("http") ? link : "http://" + link} target="_blank">
                      <IconButton
                        edge="end">
                        <Link />
                      </IconButton>
                    </a>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {!isShowAddSupportingLink
              ? (
                <>
                  <label htmlFor="button-file">
                    <input
                      style={{ display: "none" }}
                      id="add-supporting-file"
                      type="file"
                      onChange={onChangeFileSupporting}
                    />
                    <label htmlFor="add-supporting-file">
                      <Button size="small" color="primary" variant='outlined' component="span">
                        {supportingFileUploadProgress < 100 ? supportingFileUploadProgress + "% Uploading" : "Add File"}
                      </Button>
                    </label>
                  </label>{" "}
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsShowAddSupportingLink(true)}>
                    Add Link
                  </Button>
                  <br />
                </>
              )
              : (
                <div>
                  <TextField
                    margin="dense"
                    fullWidth
                    size="small"
                    label="link"
                    onChange={(e) => setSupportingLinkForm(e.target.value)} onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        handleAddSupportingLink()
                      }
                    }} />
                  <Button size="small" variant="outlined" color="default" onClick={() => setIsShowAddSupportingLink(false)}>Cancel</Button> {" "}
                  <Button size="small" variant="outlined" color="primary" onClick={handleAddSupportingLink}>Add</Button>
                </div>
              )
            }
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardHeader
            subheader="Data Attachment"
          />
          <CardContent>
            <List dense={true}>
              {resultFileList.map((file: any) => (
                <ListItem>
                  <ListItemText
                    primary={file.fileName}
                  />
                  <ListItemSecondaryAction>
                    <a href={file.filePath} target="_blank">
                      <IconButton
                        edge="end">
                        <ExternalLink />
                      </IconButton>
                    </a>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
              {resultLinkList.map((link: any) => (
                <ListItem>
                  <ListItemText
                    primary={link}
                  />
                  <ListItemSecondaryAction>
                    <a href={link.includes("http") ? link : "http://" + link} target="_blank">
                      <IconButton
                        edge="end">
                        <Link />
                      </IconButton>
                    </a>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {!isShowAddResultLink
              ? (
                <>
                  <label htmlFor="button-file">
                    <input
                      style={{ display: "none" }}
                      id="add-result-file"
                      type="file"
                      onChange={onChangeFileResult}
                    />
                    <label htmlFor="add-result-file">
                      <Button size="small" color="primary" variant='outlined' component="span">
                        {resultFileUploadProgress < 100 ? resultFileUploadProgress + "% Uploading" : "Add File"}
                      </Button>
                    </label>
                  </label>{" "}
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => setIsShowAddResultLink(true)}>
                    Add Link
                  </Button>
                  <br />
                </>
              )
              : (
                <div>
                  <TextField
                    margin="dense"
                    fullWidth
                    size="small"
                    label="link"
                    onChange={(e) => setResultLinkForm(e.target.value)} onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        handleAddResultLink()
                      }
                    }} />
                  <Button size="small" variant="outlined" color="default" onClick={() => setIsShowAddResultLink(false)}>Cancel</Button> {" "}
                  <Button size="small" variant="outlined" color="primary" onClick={handleAddResultLink}>Add</Button>
                </div>
              )
            }
          </CardContent>
        </Card>
        <br />
        {values.status === "Active" && (
          <Fragment>
            <Button fullWidth variant="contained" color="primary" onClick={handleComplete}>Complete Loading Request</Button>
            <br />
            <br />
          </Fragment>
        )}
        <div className="wrapbutton">
          <Button type="button" className="delete" variant="outlined" onClick={handleDelete}>
            <Trash /> Delete
          </Button>
          <Button type="submit" className="save" variant="outlined">
            <Save /> Save
          </Button>
        </div>
      </form>
    </div>
  );
}
