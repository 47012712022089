
import { useEffect, useState } from 'react';
import { Button, Container, MenuItem, TextField } from '@material-ui/core';
import ModalTemplate from '../../../../shared/ModalTemplate';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Template from '../../../../shared/Template'
import FormPoleLoding from '../component/FormPoleLoding';
import { useDispatch, useSelector } from 'react-redux';
import { getPoleLoading } from '../poleloading.api';
import { RootState } from '../../../../app/store';
import LoadingCustom from '../../../../shared/LoadingCustom';
import MUIDataTable from 'mui-datatables'
import { handleLeftButton, setCenter, setZoom } from '../../../dashboard/dashboard.reducer';
import LoadingRequestDetail from '../component/LoadingRequestDetail';
import LoadingRequestAssign from '../component/LoadingRequestAssign';
import { getPoleLoadingRequest } from '../../../poleInformation/poleInformation.api';
import { setIsShowPoleLoadingRequest, setListPoleLoadingRequest } from '../../../poleInformation/poleInformation.reducer';
import Roles from '../../../../shared/Roles';
import { setLoadingRequestIdList } from '../../../sideTab/sideTab.reducer';
import { useNavigate } from 'react-router-dom';

function PoleLoading() {

    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    const { loading, data } = useSelector((state: RootState) => state.poleloading)
    const loadingSidetab = useSelector((state: RootState) => state.sideTab).loading
    const loadingPoleInformation = useSelector((state: RootState) => state.poleInformation).loadingPole
    const { company } = useSelector((state: RootState) => state.company);

    const [modal, setModal] = useState<any>({})
    const [statusSelected, setStatusSelected] = useState('0')
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [page, setPage] = useState(0)
    const [dataValue, setDataValue] = useState<any>([])
    const [modalDetail, setModalDetail] = useState<any>({})
    const [modalAssign, setModalAssign] = useState<any>({})

    useEffect(() => {
        if (modalDetail.poleLoadingId === undefined && modalAssign.poleLoadingId === undefined) {
            dispatch(getPoleLoading({
                type: 0,
                skip: page * rowsPerPage,
                take: rowsPerPage,
                companyId: localStorage.getItem("companyIdSelected"),
                poleLoadingStatus: statusSelected,
                userId: localStorage.getItem("id")
            }))
        }
    }, [rowsPerPage, page, company, statusSelected, modalDetail, modalAssign])

    useEffect(() => {
        let dataModified: any = []
        for (let i = 0; i < (page * rowsPerPage); i++) {
            dataModified.push({});
        }
        setDataValue(dataModified.concat(data?.data))
    }, [data])

    const handleChangeStatus = (e: any) => {
        let value = e.target.value
        setStatusSelected(value)
    }

    const ecdValue = (id: any, i: number) => {
        let data = dataValue[i]
        let nowTime = new Date().getTime()
        let ecdTime = new Date(data?.ecd).getTime()
        let isHighlight = ecdTime < nowTime && data?.jobNumberStatus === 0
        isHighlight = isHighlight && (statusSelected === '0')
        if (isHighlight) {
            return (
                <span><mark>{data?.ecd}</mark></span>
            )
        }
        else {
            return (
                <span>{data?.ecd}</span>
            )
        }
    }

    const colunmn = [
        { name: "project", label: "Project" },
        { name: "jobNumber", label: "Job Number" },
        { name: "loadingRequest", label: "Loading Request" },
        { name: "poleCount", label: "Total Poles" },
        { name: "fieldingType", label: "Fielding Request Type" },
        { name: "fieldingDate", label: "Fielding Due Date" },
        {
            name: "ecd",
            label: "ECD",
            options: {
                customBodyRenderLite: ecdValue
            }
        },
        { name: "status", label: "Status" },
        {
            name: "poleLoadingId",
            label: "Action",
            options: {
                customBodyRender: (value: any) => (
                    <>
                        <Button className="button-done" size='small' variant='contained' color='primary' onClick={() => handleAssign(value)}>Assign</Button> {" "}
                        <Button className="button-done" size='small' variant='contained' color='inherit' onClick={() => handleDetail(value)}>Details</Button> {" "}
                        <Button className="button-done" size='small' variant='contained' color='inherit' onClick={() => handleToMap(value)}>Map</Button>
                    </>
                ),
                filter: false,
                download: false
            }
        }
    ]

    const handleDetail = (id: any) => {
        let data = dataValue.filter((item: any) => item.poleLoadingId === id)[0]
        setModalDetail(data)
    }

    const handleAssign = (id: any) => {
        let data = dataValue.filter((item: any) => item.poleLoadingId === id)[0]
        setModalAssign(data)
    }

    const handleToMap = (id: any) => {
        let data = dataValue.filter((item: any) => item.poleLoadingId === id)[0]
        dispatch(setIsShowPoleLoadingRequest(true))
        dispatch(setLoadingRequestIdList([id]))
        dispatch(getPoleLoadingRequest(data.poleLoadingId))
            .unwrap()
            .then((response: any) => {
                dispatch(setListPoleLoadingRequest(response.data));
                let latitude = response.data[0].latitude
                let longitude = response.data[0].longitude
                dispatch(setCenter({ latitude: latitude, longitude: longitude }))
                dispatch(setZoom(16))
                navigate("/home")
            })
    }

    let component = () => (
        <>
            <LoadingCustom isLoading={loading || loadingSidetab || loadingPoleInformation} />
            <Container maxWidth={false} className="conten">
                <ModalTemplate component={() => <LoadingRequestDetail values={modalDetail} handleClose={() => setModalDetail({})} />} onOpen={modalDetail.poleLoadingId ? true : false} onClose={() => setModalDetail({})} />
                <ModalTemplate component={() => <LoadingRequestAssign values={modalAssign} handleClose={() => setModalAssign({})} />} onOpen={modalAssign.poleLoadingId ? true : false} onClose={() => setModalAssign({})} />
                <ModalTemplate
                    component={() => <FormPoleLoding type={modal.type} data={modal.data} onClose={() => setModal({ status: false })} />}
                    onOpen={modal.status}
                />
                <div className="account">
                    <MUIDataTable
                        title={(
                            <div className="MUIDataTableToolbar-left-23">
                                <div className="MUIDataTableToolbar-titleRoot-27">
                                    <span className="MuiTypography-root MUIDataTableToolbar-titleText-28 MuiTypography-h6" style={{ marginRight: 10 }}>Pole Loading Request</span>
                                    <br />
                                    <div className="option-table">
                                        <TextField
                                            className="inp"
                                            select
                                            variant="outlined"
                                            size="small"
                                            label="Status"
                                            defaultValue={0}
                                            onChange={handleChangeStatus}
                                        >
                                            <MenuItem value="0">Active</MenuItem>
                                            <MenuItem value="1">Completed</MenuItem>
                                            <MenuItem value="2">Archived</MenuItem>
                                        </TextField>
                                    </div>
                                </div>
                            </div>
                        )}
                        data={dataValue}
                        columns={String(Roles("home")) === '/poleloading' ? colunmn.filter(item => item.name !== 'status') : colunmn}
                        options={{
                            selectableRows: "none",
                            rowsPerPageOptions: [50, 100, 200],
                            rowsPerPage: 50,
                            onChangePage: (currentPage: number) => { setPage(currentPage) },
                            onChangeRowsPerPage: (numberOfRows: number) => { setRowsPerPage(numberOfRows) },
                            count: data?.total,
                            downloadOptions: {
                                filename: "utility fielding - pole loading request.csv"
                            },
                            print: false
                        }}
                    />
                </div>
            </Container>
        </>
    )
    return <Template component={component} />
}

export default PoleLoading;