import { Button, Checkbox, Divider, InputAdornment, MenuItem, Modal, TextField, Tooltip, Typography } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { Info, Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import pencilBlack from "../../../../assets/icons/pencilBlack.png";
import pencilGreen from "../../../../assets/icons/pencilGreen.png";
import { handleFieldingRequestIdActive, handleLayerActive, handleModalAerialFielding, setFieldingIdList, handleFieldingIcon, setJobIdList, handleNoteIcon, setLoading, setStatusFieldingRequest } from "../../sideTab.reducer";
import { RootState } from "../../../../app/store";
import { completeFieldingRequest, generateKmlFielding, getFieldingRequest, getFieldingRequestAttachmentList, reactiveFieldingRequest } from "../../sideTab.api";
import { getMarkerFielding, getMarkerFieldingRequestByJobNumber, getMeasureMarker, getMidSpanFieldingRequest, getMidspanFieldingRequestByJobNumber, getPoleDetails, getPoleFieldingRequest, getPoleFieldingRequestByJobNumber } from "../../../poleInformation/poleInformation.api";
import { setIsShowPoleFieldingRequest, setIsShowPoleLoadingRequest, setListMidspanFieldingRequest, setListMarkerFieldingRequest, setListPoleFieldingRequest, clearPoleDetails, setPoleType, setEditPoleInfo, setMidspanDetail, setPoleFieldingRequest, setListMeasureMarker } from "../../../poleInformation/poleInformation.reducer";
import { setAerialFielding, setActiveAerial, handleIsChooseFielderModal, setStatusSelected, handleIsFielderListModal } from "../../../assignFielding/assignFielding.reducer";
import ModalTemplate from "../../../../shared/ModalTemplate";
import FormFielding from "./FormFielding";
import { handleRightButton, setCenter, setMeasureMarkerData, setZoom } from "../../../dashboard/dashboard.reducer";
import Roles from "../../../../shared/Roles";
import Swal from "sweetalert2";
import { baseUrl } from "../../../../state";
import AerialFIeldingFiles from "./AerialFIeldingFiles";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import { getAllAssignedJob, getFieldingRequestDetail } from "../../../assignFielding/assignFielding.api";
import ChooseFielderModal from "../../../assignFielding/component/ChooseFielderModal";
import FielderListModal from "../../../assignFielding/component/FielderListModal";
import AerialFieldingDownloadLogsModal from "./AerialFieldingDownloadLogsModal";
import { useSearchParams } from "react-router-dom";

function AerialFielding() {
  const dispatch = useDispatch<any>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLayerActive, jobNumberIdActive, listFieldingRequest, fieldingIdList, modalAerialFielding, jobIdList, statusJobNumber, fieldingAttachmentList, projectIdActive, statusFieldingRequest, sideTabActive } = useSelector((state: RootState) => state.sideTab);
  const { aerialFielding, isChooseFielderModal, isFielderListModal } = useSelector((state: RootState) => state.assignFielding);
  const { listPoleFieldingRequest, listMidspanFieldingRequest, listMarkerFieldingRequest, listMeasureMarker } = useSelector((state: RootState) => state.poleInformation);
  const { zoom, rightButton } = useSelector((state: RootState) => state.dashboard);

  let [hidden, setHidden] = useState<any>(false);
  let [modalFiles, setModalFiles] = useState(false)
  let [modalDownloadLogs, setModalDownloadLogs] = useState(false)

  useEffect(() => {
    const fieldingStatus = searchParams.get('fieldingStatus');
    if (fieldingStatus !== null) {
      dispatch(setStatusFieldingRequest(fieldingStatus))
    }
    else if (jobNumberIdActive.qtyActive) {
      dispatch(setStatusFieldingRequest("0"))
    }
    else if (jobNumberIdActive.qtyCompleted) {
      dispatch(setStatusFieldingRequest("1"))
    }
    else if (jobNumberIdActive.qtyArchived) {
      dispatch(setStatusFieldingRequest("2"))
    }
    else {
      dispatch(setStatusFieldingRequest("0"))
    }
  }, [])

  useEffect(() => {
    const fieldingId = searchParams.get('fieldingId');
    if (fieldingId) {
      let listFieldingRequestFromParams = listFieldingRequest.filter((fielding: any) => fielding.id === fieldingId)
      if (listFieldingRequestFromParams.length > 0) {
        handleChangeCheckbox({ target: { checked: true } }, listFieldingRequestFromParams[0])
      }
    }
  }, [listFieldingRequest])

  useEffect(() => {
    dispatch(getFieldingRequest({
      jobnumberId: jobNumberIdActive.id,
      status: statusFieldingRequest
    }))
    dispatch(setIsShowPoleFieldingRequest(true));
    dispatch(setIsShowPoleLoadingRequest(false));
  }, [jobNumberIdActive, statusFieldingRequest]);

  useEffect(() => {
    setHidden(aerialFielding?.id ? false : true)
  }, [aerialFielding])

  const handleStarActive = (res: any) => {
    dispatch(handleNoteIcon(null))
    if (isLayerActive.layerFieldingId) {
      dispatch(handleLayerActive({}))
      dispatch(handleFieldingIcon(null));
      dispatch(handleFieldingRequestIdActive(res));
    }
    else {
      dispatch(
        handleLayerActive({
          layerJobNumberId: "",
          layerFieldingId: res.id,
          note: false,
          status: true,
          label: "aerial"
        })
      )
      dispatch(handleFieldingIcon(Roles("fielding_request_add_pole") ? (res.fieldingCategory !== 1 ? 0 : 4) : 4));
      dispatch(handleFieldingRequestIdActive(res));
      if (!fieldingIdList.includes(res.id)) {
        dispatch(setFieldingIdList([...fieldingIdList, res.id]));
        dispatch(getPoleFieldingRequest(res.id))
          .unwrap()
          .then((response: any) => {
            dispatch(setListPoleFieldingRequest(listPoleFieldingRequest.concat(response.data)));
          })
        dispatch(getMeasureMarker(res.id))
          .unwrap()
          .then((response: any) => {
            dispatch(setListMeasureMarker(listMeasureMarker.concat(response.data)));
            dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
          })
      }
    }
  };

  let handleChangeCheckbox = async (e: any, fielding: any) => {
    if (e.target.checked) {
      handleFieldReq(fielding)
      let array: any = [...fieldingIdList];
      array.push(fielding.id);
      dispatch(setFieldingIdList(array));
      let isFullCheck = true
      listFieldingRequest.map((fieldingRequest: any) => {
        if (!array.includes(fieldingRequest.id)) {
          isFullCheck = false
        }
      })
      if (isFullCheck) {
        let jobIdListNew: any = [...jobIdList];
        jobIdListNew.push(jobNumberIdActive.id);
        dispatch(setJobIdList(jobIdListNew));
      }
      dispatch(getMidSpanFieldingRequest(fielding.id))
        .unwrap()
        .then((response: any) => {
          dispatch(setListMidspanFieldingRequest(listMidspanFieldingRequest.concat(response.data)));
          if (response.data.length > 0) dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
          if (zoom < 16) {
            dispatch(setZoom(16))
          }
        })
      dispatch(getPoleFieldingRequest(fielding.id))
        .unwrap()
        .then((response: any) => {
          dispatch(setListPoleFieldingRequest(listPoleFieldingRequest.concat(response.data)));
          if (response.data.length > 0) dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
          if (zoom < 16) {
            dispatch(setZoom(16))
            console.log("lol")
          }
        })
      dispatch(getMarkerFielding(fielding.id))
        .unwrap()
        .then((response: any) => {
          dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequest.concat(response.data)));
          if (response.data.length > 0) dispatch(setCenter({ latitude: JSON.parse(response?.data[0]?.lineData || [])[0].lat, longitude: JSON.parse(response?.data[0]?.lineData || [])[0].lng }))
        })
      dispatch(getMeasureMarker(fielding.id))
        .unwrap()
        .then((response: any) => {
          dispatch(setListMeasureMarker(listMeasureMarker.concat(response.data)));
          if (response.data.length > 0) dispatch(setCenter({ latitude: response?.data[0]?.latitude, longitude: response?.data[0]?.longitude }))
        })
      setSearchParams((params) => {
        params.set('fieldingId', fielding.id);
        return params;
      });
    }
    else {
      let array: any = [...fieldingIdList];
      let removeItem = array.indexOf(fielding.id);
      if (removeItem !== -1) {
        array.splice(removeItem, 1);
        dispatch(setFieldingIdList(array));
      }
      let poleFieldingRequest: any = listPoleFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== fielding.id);
      dispatch(setListPoleFieldingRequest(poleFieldingRequest));
      dispatch(setListMidspanFieldingRequest(listMidspanFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== fielding.id)));
      dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequest?.filter((item: any) => item?.fieldingRequestId !== fielding.id)));
      dispatch(setListMeasureMarker(listMeasureMarker?.filter((item: any) => item?.fieldingRequestId !== fielding.id)));
      dispatch(setCenter({ latitude: poleFieldingRequest[0]?.latitude, longitude: poleFieldingRequest[0]?.longitude }))
      let jobIdListNew: any = jobIdList?.filter((item: any) => item !== fielding.jobNumberId)
      dispatch(setJobIdList(jobIdListNew))
      setSearchParams((params) => {
        params.delete('fieldingId');
        return params;
      });
    }
  };

  let handleFieldReq = (data: any) => {
    if (aerialFielding.id === data.id) {
      dispatch(setAerialFielding({}));
      dispatch(setActiveAerial({ payload: null }));
    }
    else {
      dispatch(setActiveAerial({ payload: data.id }));
      dispatch(getFieldingRequestDetail(data.id));
      dispatch(getFieldingRequestAttachmentList(data.id))
    }
  };

  const handleModalAerial = () => {
    dispatch(handleModalAerialFielding({ isOpen: true, formType: "" }));
  };

  const handleSearch = (value: any) => {
    dispatch(clearPoleDetails())
    dispatch(setPoleType("POLE"))
    dispatch(setEditPoleInfo(false))
    dispatch(setMidspanDetail({}))
    dispatch(getPoleDetails(value.id))
    dispatch(setCenter({ latitude: value.latitude, longitude: value.longitude }))
    dispatch(setPoleFieldingRequest({ payload: value }));
    if (!rightButton) {
      dispatch(handleRightButton());
    }
    dispatch(setCenter({ latitude: value.latitude, longitude: value.longitude }))
  }

  const handleSearchMM = (value: any) => {
    if (value) {
      dispatch(setMeasureMarkerData({
        editModalStatus: true,
        editData: value
      }))
      dispatch(setCenter({ latitude: value.latitude, longitude: value.longitude }))
    }
  }

  const handleComplete = () => {
    dispatch(completeFieldingRequest(aerialFielding?.id))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire(
            'Set completed failed',
            'error'
          )
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Fielding Request Successfully Completed',
            showConfirmButton: false,
            timer: 1500
          })
            .then(() => {
              dispatch(getFieldingRequest({
                jobnumberId: jobNumberIdActive.id,
                status: statusFieldingRequest
              }))
              dispatch(setAerialFielding({}));
            })
        }
      })
  }

  const handleReactive = () => {
    dispatch(reactiveFieldingRequest(aerialFielding?.id))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire(
            'Set reactive failed',
            'error'
          )
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Fielding Request Successfully Reactivated',
            showConfirmButton: false,
            timer: 1500
          })
            .then(() => {
              dispatch(getFieldingRequest({
                jobnumberId: jobNumberIdActive.id,
                status: statusFieldingRequest
              }))
              dispatch(setAerialFielding({}));
            })
        }
      })
  }

  const handleExportKml = () => {
    dispatch(setLoading(true))
    dispatch(generateKmlFielding(aerialFielding.id))
      .unwrap()
      .then((res: any) => {
        let arr = res.data.split("/")
        const downloadLink = document.createElement('a');
        downloadLink.href = baseUrl + "/api/Global/Download?path=" + res.data
        downloadLink.setAttribute('download', arr[arr.length - 1]);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
        dispatch(setLoading(false))
      })
  }

  const checkValue = (value: string | null) => {
    if (value) {
      if (value === '-') {
        return value
      }
      else if (value.charAt(0) === '-') {
        return value.substring(1).replaceAll(',', '.').replaceAll('\n', '. ')
      }
      else {
        return value.replaceAll(',', '.').replaceAll('\n', '. ')
      }
    }
    else {
      return '-'
    }
  }

  const handleExportCsv = () => {
    Swal.fire({
      title: "Loading",
      text: "loading CSV data",
      didOpen: () => {
        Swal.showLoading()
      }
    })
    const jobNumberId = jobNumberIdActive.id
    axios.get(baseUrl + "/api/JobNumber/GetPDFData?targetJobNumberId=" + jobNumberId + "&userId=" + localStorage.getItem("id"))
      .then(response => {
        var data = response?.data?.data
        var dataString = ""
        if (aerialFielding.name.includes("MM")) {
          dataString += [
            "MM Request",
            "MM Sequence",
            "Lat",
            "Long",
            "Length",
            "Notes"
          ].join(",")
          dataString += "\n"
          data?.fieldingRequests?.map((fielding: any) => {
            if ((fielding.name) === (aerialFielding.name)) {
              fielding?.measurementMarkers?.map((pole: any) => {
                dataString += [
                  checkValue(fielding?.name),
                  checkValue(pole?.sequence),
                  pole?.latitude,
                  pole?.longitude,
                  checkValue(pole?.length),
                  checkValue(pole?.note)
                ].join(",")
                dataString += "\n"
              })
              dataString += [
                '',
                '',
                '',
                'total',
                fielding?.measurementMarkers?.reduce((total: number, pole: any) => total + (parseInt(pole.length || '0') || 0), 0),
                ''
              ].join(",")
              dataString += "\n"
            }
          })
        }
        else if (aerialFielding.typeString === "PenAndPaper") {
          dataString += [
            "Fielding Request",
            "Pole Type",
            "Pole Sequence",
            "Lat",
            "Long",
            "Street Name",
            "Notes"
          ].join(",")
          dataString += "\n"
          data?.fieldingRequests?.map((fielding: any) => {
            if ((fielding.name) === (aerialFielding.name)) {
              fielding?.poles?.map((pole: any) => {
                dataString += [
                  checkValue(fielding?.name),
                  checkValue(pole?.typeString),
                  checkValue(pole?.poleSequence),
                  pole?.latitude,
                  pole?.longitude,
                  checkValue(pole?.street),
                  checkValue(pole?.notes)
                ].join(",")
                dataString += "\n"
              })
            }
          })
        }
        else if (aerialFielding.typeString === "MREEstimation") {
          dataString += [
            "Fielding Request",
            "Pole Type",
            "Pole Sequence",
            "MRE Estimation",
            "Lat",
            "Long",
            "Street Name",
            "Notes"
          ].join(",")
          dataString += "\n"
          data?.fieldingRequests?.map((fielding: any) => {
            if ((fielding.name) === (aerialFielding.name)) {
              fielding?.poles?.map((pole: any) => {
                dataString += [
                  checkValue(fielding?.name),
                  checkValue(pole?.typeString),
                  checkValue(pole?.poleSequence),
                  checkValue(pole?.mreRequirementsString),
                  pole?.latitude,
                  pole?.longitude,
                  checkValue(pole?.street),
                  checkValue(pole?.notes)
                ].join(",")
                dataString += "\n"
              })
            }
          })
        }
        else {
          dataString += [
            "Fielding Request",
            "Pole Type",
            "Pole Sequence",
            "Pole Number/Tag",
            "Lat",
            "Long",
            "Street Name",
            "FAP Terminal",
            "OSMOSE Number",
            "Other Number",
            "Ground Line Circumference",
            "Year",
            "Pole Condition",
            "MRE Estimation",
            "Radio Antena",
            "Pole Stamp",
            "Length",
            "Pole Class",
            "Species",
            "Pole Set Surfaces",
            "C-Truss",
            "C-Tree Trim",
            "PL VIS Strips",
            "PL VGR",
            "INS DG",
            "RMV ABN DW'S",
            "Reloc DW'S Out Of Climbing Space",
            "PL GG On DG",
            "Notes"
          ].join(",")
          dataString += "\n"
          data?.fieldingRequests?.map((fielding: any) => {
            if (fielding.name === aerialFielding.name) {
              fielding?.poles?.map((pole: any) => {
                dataString += [
                  checkValue(fielding?.name),
                  checkValue(pole?.typeString),
                  checkValue(pole?.poleSequence),
                  pole?.isPoleNumberUnknown ? "UNK" : checkValue(pole?.poleNumber),
                  pole?.latitude,
                  pole?.longitude,
                  checkValue(pole?.street),
                  pole?.isFAPUnknown ? "UNK" : checkValue(pole?.vapTerminal),
                  pole?.isOsmoseUnknown ? "UNK" : checkValue(pole?.osmose),
                  pole?.isOtherNumberUnknown ? "UNK" : checkValue(pole?.otherNumber),
                  pole?.isGroundLineUnknown ? "UNK" : ((checkValue(pole?.groundCircumference)) + (pole?.isGroundLineEstimated ? ". EST" : "")),
                  pole?.isYearUnknown ? "UNK" : ((checkValue(pole?.poleYear)) + (pole?.isYearEstimated ? ". EST" : "")),
                  checkValue(pole?.poleConditionString),
                  checkValue(pole?.mreRequirementsString),
                  pole?.isRadioAntenna ? 'Yes' : 'No',
                  pole?.poleStamp ? 'Yes' : 'No',
                  pole?.isPoleLengthUnknown ? "UNK" : (pole?.poleHeightString + 'ft' + (pole?.isPoleLengthEstimated ? ". EST" : "")),
                  pole?.isPoleClassUnknown ? "UNK" : (pole?.poleClassString + (pole?.isPoleClassEstimated ? ". EST" : "")),
                  pole?.isSpeciesUnknown ? "UNK" : (pole?.poleSpeciesString + (pole?.isSpeciesEstimated ? ". EST" : "")),
                  checkValue(pole?.poleSetSurfacesString),
                  pole?.cTruss === true ? "Yes" : pole?.cTruss === false ? "No" : "-",
                  pole?.treeTrim === true ? "Yes" : pole?.treeTrim === false ? "No" : "-",
                  pole?.pL_VIS_Strips === true ? "Yes" : pole?.pL_VIS_Strips === false ? "No" : "-",
                  pole?.pL_VGR === true ? "Yes" : pole?.pL_VGR === false ? "No" : "-",
                  pole?.inS_DG === true ? "Yes" : pole?.inS_DG === false ? "No" : "-",
                  pole?.rmV_ABN_DWS === true ? "Yes" : pole?.rmV_ABN_DWS === false ? "No" : "-",
                  pole?.relocDWS === true ? "Yes" : pole?.relocDWS === false ? "No" : "-",
                  pole?.pL_GG_OnDG === true ? "Yes" : pole?.pL_GG_OnDG === false ? "No" : "-",
                  checkValue(pole?.notes)
                ].join(",")
                dataString += "\n"
              })
            }
          })
        }
        var dataBlob = new Blob([dataString], { type: 'text/csv' });
        var csvURL = window.URL.createObjectURL(dataBlob);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `${jobNumberIdActive.name} - ${aerialFielding.name}.csv`);
        tempLink.click();
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        Swal.close()
      })
  }

  const handleAssignFielding = () => {
    dispatch(setStatusSelected(statusJobNumber))
    dispatch(getAllAssignedJob(statusJobNumber))
      .unwrap()
      .then((response: any) => {
        dispatch(handleIsChooseFielderModal(response.data.filter((item: any) => item.fieldingRequestId === aerialFielding.id)[0]))
      })
  }

  const handleListAssign = () => {
    dispatch(setStatusSelected(statusJobNumber))
    dispatch(getAllAssignedJob(statusJobNumber))
      .unwrap()
      .then((response: any) => {
        dispatch(handleIsFielderListModal(response.data.filter((item: any) => item.fieldingRequestId === aerialFielding.id)[0]))
      })
  }

  const handleCheckAll = async (e: any) => {
    if (e.target.checked) {
      let fieldingIdListNew: any = fieldingIdList?.concat(listFieldingRequest.map((item: any) => item.id))
      dispatch(setFieldingIdList(fieldingIdListNew))
      await dispatch(getPoleFieldingRequestByJobNumber(jobNumberIdActive.id))
        .unwrap()
        .then((res: any) => {
          dispatch(setCenter({ latitude: res?.data[0]?.latitude, longitude: res?.data[0]?.longitude }))
          if (zoom < 16) {
            dispatch(setZoom(16))
          }
          dispatch(setListPoleFieldingRequest(listPoleFieldingRequest.concat(res.data)));
        })
      await dispatch(getMidspanFieldingRequestByJobNumber(jobNumberIdActive.id))
        .unwrap()
        .then((res: any) => {
          dispatch(setCenter({ latitude: res?.data[0]?.latitude, longitude: res?.data[0]?.longitude }))
          if (zoom < 16) {
            dispatch(setZoom(16))
          }
          dispatch(setListMidspanFieldingRequest(listMidspanFieldingRequest.concat(res.data)));
        })
      await dispatch(getMarkerFieldingRequestByJobNumber(jobNumberIdActive.id))
        .unwrap()
        .then((res: any) => {
          dispatch(setCenter({ latitude: res?.data[0]?.latitude, longitude: res?.data[0]?.longitude }))
          if (zoom < 16) {
            dispatch(setZoom(16))
          }
          dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequest.concat(res.data)));
        })
    }
    else {
      let fieldingIdListNew: any = fieldingIdList?.filter((item: any) => !listFieldingRequest.map((item: any) => item.id).includes(item));
      dispatch(setFieldingIdList(fieldingIdListNew))
      dispatch(setListPoleFieldingRequest(listPoleFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId))));
      dispatch(setListMidspanFieldingRequest(listMidspanFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId))));
      dispatch(setListMarkerFieldingRequest(listMarkerFieldingRequest?.filter((item: any) => fieldingIdListNew.includes(item.fieldingRequestId))));
    }
  }

  const handleChangeStatus = (value: string) => {
    dispatch(setStatusFieldingRequest(value))
    setSearchParams((params) => {
      params.set('fieldingStatus', value);
      return params;
    });
  }

  return (
    <>
      <ModalTemplate component={() => <FormFielding />} onOpen={modalAerialFielding.isOpen} />
      <ModalTemplate component={() => <ChooseFielderModal />} onOpen={isChooseFielderModal} />
      <ModalTemplate component={() => <FielderListModal />} onOpen={isFielderListModal} />
      <ModalTemplate component={() => <AerialFieldingDownloadLogsModal onClose={() => setModalDownloadLogs(false)} data={aerialFielding} />} onOpen={modalDownloadLogs} />
      {(Roles("fielding_request_add")) && (
        statusJobNumber > 0
          ? (
            <Tooltip className="need-reactive" title="reactivate job to create new fielding request">
              <Button>
                <Plus /> Fielding request
              </Button>
            </Tooltip>
          )
          : (
            <Button onClick={handleModalAerial}>
              <Plus /> Fielding request
            </Button>
          )
      )}
      <div style={{ display: "flex", alignItems: "center", marginLeft: 2, fontSize: 15, color: "#515152" }}>
        <Checkbox checked={listFieldingRequest.every((fieldingRequest: any) => fieldingIdList.includes(fieldingRequest.id))} color="primary" onChange={handleCheckAll} />
        <span>{listFieldingRequest.every((fieldingRequest: any) => fieldingIdList.includes(fieldingRequest.id)) ? "Uncheck All" : "Check All"}</span>
      </div>
      <TextField className="inp" variant="outlined" size="small" select value={statusFieldingRequest} onChange={(e) => handleChangeStatus(e.target.value)}>
        <MenuItem value="0">Active</MenuItem>
        <MenuItem value="1">Completed</MenuItem>
        <MenuItem value="2">Archived</MenuItem>
      </TextField>
      <Divider />
      <ul>
        {listFieldingRequest.map((res: any, i: any) => (
          <li key={i} className="projnumberlist" style={{ backgroundColor: (res.id === aerialFielding.id ? "#cbced2" : "") }}>
            <div>
              <Tooltip title="view pole">
                <Checkbox className="customcheck" color="primary" checked={fieldingIdList.includes(res.id)} onChange={(e: any) => handleChangeCheckbox(e, res)} />
              </Tooltip>
            </div>
            <Tooltip title="show details">
              <span onClick={() => handleFieldReq(res)}>{res.name}</span>
            </Tooltip>
            {(Roles("fielding_request_edit") && statusJobNumber === 0) && (
              <Tooltip title="edit/activate"><img src={isLayerActive.layerFieldingId === res.id && isLayerActive.status ? pencilGreen : pencilBlack} alt="star" onClick={() => handleStarActive(res)} /></Tooltip>
            )}
          </li>
        ))}
      </ul>
      {aerialFielding.id ? (
        <div className="status-aerial wraptab" style={{ marginLeft: hidden ? "500px" : 0, left: 322 * (Object.values(sideTabActive).reduce((total: number, d: any) => total + (d ? 1 : 0), 0)) }}>
          <Typography variant="subtitle1" align="center">Fielding Request:</Typography>
          <Typography variant="subtitle1" align="center">{aerialFielding.name}</Typography>
          {aerialFielding.fieldingCategory !== 1
            ? (
              <Autocomplete
                options={JSON.parse(JSON.stringify(listPoleFieldingRequest))
                  .filter((d: any) => d.isActive)
                  .sort((a: any, b: any) => {
                    if (a.poleSequence.toLowerCase() < b.poleSequence.toLowerCase()) {
                      return -1;
                    }
                    if (a.poleSequence.toLowerCase() > b.poleSequence.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  })}
                getOptionLabel={(pole: any) => {
                  return `seq:${pole.poleSequence || ""},${pole.poleNumber || ""}`
                }}
                onChange={(e, value) => handleSearch(value)}
                renderInput={(params) => <TextField {...params} margin="dense" variant="outlined" fullWidth label="Search" />}
              />
            )
            : (
              <Autocomplete
                options={JSON.parse(JSON.stringify(listMeasureMarker))
                  .sort((a: any, b: any) => {
                    if (a.sequence < b.sequence) {
                      return -1;
                    }
                    if (a.sequence > b.sequence) {
                      return 1;
                    }
                    return 0;
                  })}
                getOptionLabel={(pole: any) => {
                  return `seq:${pole.sequence || ""}`
                }}
                onChange={(e, value) => handleSearchMM(value)}
                renderInput={(params) => <TextField {...params} margin="dense" variant="outlined" fullWidth label="Search" />}
              />
            )
          }
          {(Roles("assign_fielding") && projectIdActive.assignedManagerIds?.includes(localStorage.getItem("id"))) && (
            <Button className="complete-Button" onClick={handleAssignFielding}>Assign Fielding</Button>
          )}
          <TextField className='inp' disabled value={aerialFielding.createdDateString} label="Fielding Created Date" size="small" />
          <TextField className='inp' disabled value={aerialFielding.dueDateString} label="Fielding Due Date" size="small" />
          <TextField
            className='inp'
            disabled
            value={aerialFielding.requestedDateString}
            label="Fielding Assign"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Info style={{ marginBottom: 25, cursor: "pointer" }} onClick={handleListAssign} />
                </InputAdornment>
              )
            }}
          />
          <TextField className='inp' disabled value={aerialFielding.fieldingStartDateString} label="Fielding Started" size="small" />
          <TextField
            className='inp'
            disabled
            value={aerialFielding.fieldingCategory !== 1 ? aerialFielding.completedPolePercentage : aerialFielding.totalMeasurementMarkers > 0 ? `100%` : `0%`}
            label="Status"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Info style={{ marginBottom: 25, cursor: "pointer" }} onClick={() => setModalDownloadLogs(true)} />
                </InputAdornment>
              )
            }} />
          <TextField className='inp' disabled value={aerialFielding.completeDateString} label="Fielding Complete" size="small" />
          {aerialFielding.fieldingCategory !== 1
            ? (
              <Fragment>
                <TextField className='inp' disabled value={`poles: ${aerialFielding.totalDefaultPoles}\ncompleted poles: ${aerialFielding.totalCompletedDefaultPoles}`} label="Default Poles" size="small" multiline rows={2} />
                <TextField className='inp' disabled value={`poles: ${aerialFielding.totalReferencePoles}\ncompleted poles: ${aerialFielding.totalCompletedReferencePoles}`} label="Reference pole" size="small" multiline rows={2} />
                <TextField className='inp' disabled value={`poles: ${aerialFielding.totalSupportingPoles}\ncompleted poles: ${aerialFielding.totalCompletedSupportingPoles}`} label="Supporting pole" size="small" multiline rows={2} />
              </Fragment>
            )
            : (
              <TextField className='inp' disabled value={`Marker: ${aerialFielding.totalMeasurementMarkers}`} label="Total Measure Marker" size="small" />
            )
          }
          <br />
          <Button className='export-btn' onClick={handleExportKml}>EXPORT KML</Button>
          <Button className='export-btn' onClick={handleExportCsv}>EXPORT SPREADSHEET</Button>
          {(statusFieldingRequest === '0' && Roles("fielding_request_complete")) && (
            <Button className="complete-Button" onClick={handleComplete}>Complete Fielding</Button>
          )}
          {(statusFieldingRequest === '1' && Roles("fielding_request_complete")) && (
            <Button className="complete-Button" onClick={handleReactive}>Reactive Fielding Request</Button>
          )}
          {fieldingAttachmentList.length > 0 && (
            <Button className="complete-Button" onClick={() => setModalFiles(true)}>Unsynchronized Picture</Button>
          )}
          <Modal className="image-editor-modal" open={modalFiles}>
            <AerialFIeldingFiles onClose={() => setModalFiles(false)} />
          </Modal>
        </div>
      ) : null}
    </>
  );
}

export default AerialFielding;
