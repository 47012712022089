import { Button, Divider, InputAdornment, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Eye, EyeOff, Save, X } from "react-feather";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { accountResetPassword, getAccount } from "../account.api";

export default function FormResetPassword(props: any) {

    const dispatch = useDispatch<any>()

    const [values, setValues] = useState({
        token: "",
        oldPassword: "",
        newPassword: "",
        passwordConfirm: ""
    })
    let [showPass, setShowPass] = useState<any>(false)
    let [showRePass, setShowRePass] = useState<any>(false)
    let [showOldPass, setShowOldPass] = useState<any>(false)

    useEffect(() => {
        setValues({
            token: localStorage.getItem("token") || "",
            oldPassword: "",
            newPassword: "",
            passwordConfirm: ""
        })
    }, [props.data])

    const handleChange = (e: any) => {
        let { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        dispatch(accountResetPassword(values))
            .unwrap()
            .then((res: any) => {
                if (res.status >= 400) {
                    Swal.fire(
                        'Reset Password Failed!',
                        `${res.data.message || Object.values(res.data.errors)}`,
                        'error'
                    )
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                        .then(() => {
                            dispatch(getAccount())
                            props.onClose()
                        })
                }
            })
    }

    return (
        <div className="formaccount">
            <h3>Reset Password <X onClick={props.onClose} /></h3>
            <form onSubmit={handleSubmit}>
                <TextField
                    className='inp'
                    name='newPassword'
                    onChange={handleChange}
                    value={values.newPassword}
                    type={showPass ? 'text' : 'password'}
                    variant="outlined"
                    label="Password"
                    size='small'
                    required
                    inputProps={{
                        minLength: 8,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {showPass ?
                                    <Eye onClick={() => setShowPass(!showPass)} />
                                    :
                                    <EyeOff onClick={() => setShowPass(!showPass)} />
                                }
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    className='inp'
                    name='passwordConfirm'
                    helperText={(<span className='helper-text'>Password must be at least 8 characters long, includes upper and lower case letter, at least 1 number, and not matching to the email used</span>)}
                    onChange={handleChange}
                    value={values.passwordConfirm}
                    type={showRePass ? 'text' : 'password'}
                    variant="outlined"
                    label="Password Confirmation"
                    size='small'
                    required
                    inputProps={{
                        minLength: 8,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {showRePass ?
                                    <Eye onClick={() => setShowRePass(!showRePass)} />
                                    :
                                    <EyeOff onClick={() => setShowRePass(!showRePass)} />
                                }
                            </InputAdornment>
                        )
                    }}
                />
                <Divider />
                <TextField
                    className='inp'
                    name='oldPassword'
                    onChange={handleChange}
                    value={values.oldPassword}
                    type={showPass ? 'text' : 'password'}
                    variant="outlined"
                    label="Your Password"
                    size='small'
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {showOldPass ?
                                    <Eye onClick={() => setShowOldPass(!showOldPass)} />
                                    :
                                    <EyeOff onClick={() => setShowOldPass(!showOldPass)} />
                                }
                            </InputAdornment>
                        )
                    }}
                />
                <div className="wrapbutton">
                    <Button type='submit' className="save" variant='outlined'><Save /> Save</Button>
                </div>
            </form>
        </div>
    )
}