export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        let serializedStateJson = JSON.parse(serializedState)
        let newSerializedStateJson = {
            ...serializedStateJson,
            sideTab: {
                ...serializedStateJson.sideTab,
                sideTabActive: {
                    project: true,
                    jobNumber: true,
                    jobDetail: true,
                    jobDetailMenu: true
                }
            }
        }
        return newSerializedStateJson;
    } catch (err) {
        return undefined;
    }
}

export const saveState = (state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};