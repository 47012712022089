import { useEffect, useState } from 'react';
import { Button, Container, Grid, MenuItem, TextField } from '@material-ui/core';
import ModalTemplate from '../../../../shared/ModalTemplate';

import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

import Template from '../../../../shared/Template'
import FormFieldingRequest from '../component/FormFieldingRequest';
import { useDispatch, useSelector } from 'react-redux';
import { getTrackerFieldingRequest } from '../fieldingRequest.api';
import { RootState } from '../../../../app/store';
import LoadingCustom from '../../../../shared/LoadingCustom';
import MUIDataTable from 'mui-datatables'
import { setCenter, setZoom, showLeftTab } from '../../../dashboard/dashboard.reducer';
import { handleProjectIdActive, setDetailMenu, setFieldingIdList, setJobDetail, setStatusJobNumber } from '../../../sideTab/sideTab.reducer';
import { getMeasureMarker, getPoleFieldingRequest } from '../../../poleInformation/poleInformation.api';
import { setListMeasureMarker, setListPoleFieldingRequest } from '../../../poleInformation/poleInformation.reducer';
import { getJobNumber, getJobNumberDetails } from '../../../sideTab/sideTab.api';
import { useNavigate } from 'react-router-dom';

function FieldingRequest() {

    const dispatch = useDispatch<any>()
    const navigate = useNavigate()

    const { loading, data } = useSelector((state: RootState) => state.fieldingRequest)
    const { company } = useSelector((state: RootState) => state.company);
    const { loadingPole } = useSelector((state: RootState) => state.poleInformation);

    const [modal, setModal] = useState<any>({})
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [page, setPage] = useState(0)
    const [dataValue, setDataValue] = useState<any>([])
    const [status, setStatus] = useState(0)
    const [search, setSearch] = useState("")
    const [searchFinal, setSearchFinal] = useState("")

    useEffect(() => {
        dispatch(getTrackerFieldingRequest({
            userId: localStorage.getItem("id"),
            skip: page * rowsPerPage,
            take: rowsPerPage,
            companyId: localStorage.getItem("companyIdSelected"),
            fieldingRequestStatus: status,
            filter: searchFinal
        }))
    }, [rowsPerPage, page, company, status, searchFinal])

    useEffect(() => {
        let dataModified: any = []
        for (let i = 0; i < (page * rowsPerPage); i++) {
            dataModified.push({});
        }
        setDataValue(dataModified.concat(data?.data))
    }, [data])

    const handleCreate = () => {
        navigate("/home")
    }

    const handleDetail = (id: any) => {
        let data = dataValue.filter((item: any) => item.fieldingRequestId === id)[0]
        dispatch(showLeftTab())
        dispatch(setStatusJobNumber(data.jobNumberStatus))
        dispatch(handleProjectIdActive({ id: data.projectId, projectNumber: data.project }));
        dispatch(getJobNumber({
            id: data.projectId,
            status: data.jobNumberStatus
        }))
            .unwrap()
            .then((response: any) => {
                console.log(response)
                dispatch(getJobNumberDetails(data.jobNumberId))
            })
        dispatch(setJobDetail({ id: data.jobNumberId }));
        dispatch(setDetailMenu({ id: 1, label: "Aerial Fielding" }));
        dispatch(setFieldingIdList([data.fieldingRequestId]));
        dispatch(getPoleFieldingRequest(data.fieldingRequestId))
            .unwrap()
            .then((response: any) => {
                if (response.data.length > 0) {
                    dispatch(setListPoleFieldingRequest(response.data));
                    let latitude = response.data[0]?.latitude
                    let longitude = response.data[0]?.longitude
                    dispatch(setCenter({ latitude: latitude, longitude: longitude }))
                    dispatch(setZoom(16))
                    navigate("/home")
                }
                else {
                    dispatch(getMeasureMarker(data.fieldingRequestId))
                        .unwrap()
                        .then((response: any) => {
                            dispatch(setListMeasureMarker(response.data));
                            let latitude = response.data[0]?.latitude
                            let longitude = response.data[0]?.longitude
                            dispatch(setCenter({ latitude: latitude, longitude: longitude }))
                            dispatch(setZoom(16))
                            navigate("/home")
                        })
                }
            })
    }

    const ecdValue = (id: any, i: number) => {
        let data = dataValue[i]
        let nowTime = new Date().getTime()
        let ecdTime = new Date(data?.ecd).getTime()
        let isHighlight = ecdTime < nowTime && data?.jobNumberStatus === 0
        if (isHighlight) {
            return (
                <span><mark>{data?.ecd}</mark></span>
            )
        }
        else {
            return (
                <span>{data?.ecd}</span>
            )
        }
    }

    let component = () => (
        <>
            <LoadingCustom isLoading={loading || loadingPole} />
            <Container maxWidth={false} className="conten">
                <ModalTemplate
                    component={() => <FormFieldingRequest type={modal.type} data={modal.data} onClose={() => setModal({ status: false })} />}
                    onOpen={modal.status}
                />
                <div className="account">
                    <MUIDataTable
                        title={(
                            <div className="MUIDataTableToolbar-left-23">
                                <div className="MUIDataTableToolbar-titleRoot-27">
                                    <span className="MuiTypography-root MUIDataTableToolbar-titleText-28 MuiTypography-h6" style={{ marginRight: 10 }}>Project Tracker</span>
                                    <br />
                                    <div className="option-table">
                                        <TextField
                                            className="inp"
                                            select
                                            variant="outlined"
                                            size="small"
                                            label="Status"
                                            value={status}
                                            onChange={(e: any) => setStatus(e.target.value)}
                                        >
                                            <MenuItem value="0">Active</MenuItem>
                                            <MenuItem value="1">Completed</MenuItem>
                                            <MenuItem value="2">Archived</MenuItem>
                                        </TextField>
                                        <form onSubmit={(e) => { e.preventDefault(); setSearchFinal(search) }}>
                                            <Grid container>
                                                <Grid item md={10}>
                                                    <TextField
                                                        fullWidth
                                                        label="Search"
                                                        size='small'
                                                        variant='outlined'
                                                        placeholder='type and enter to search'
                                                        onChange={(e) => setSearch(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={2}>
                                                    <Button style={{ marginLeft: 10 }} type='submit' variant='contained' fullWidth>Search</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                        data={dataValue}
                        columns={[
                            { name: "project", label: "Project" },
                            { name: "jobNumber", label: "Job Number" },
                            {
                                name: "fieldingRequest",
                                label: "Fielding Request",
                                options: {
                                    customBodyRender: (value: any) => (
                                        <>
                                            {value?.replace("Fielding Request ", "")}
                                        </>
                                    )
                                }
                            },
                            { name: "poleCount", label: "Total Poles" },
                            { name: "fieldingType", label: "Fielding Request Type" },
                            { name: "fieldingDate", label: "Fielding Request Date" },
                            {
                                name: "ecd",
                                label: "Fielding Due Date",
                                options: {
                                    customBodyRenderLite: ecdValue
                                }
                            },
                            { name: "status", label: "Status" },
                            {
                                name: "fieldingRequestId",
                                label: "Action",
                                options: {
                                    customBodyRender: (value: any) => (
                                        <>
                                            <Button size='small' variant='contained' onClick={() => handleDetail(value)}>Details</Button>
                                        </>
                                    ),
                                    filter: false,
                                    download: false
                                }
                            }
                        ]}
                        options={{
                            customToolbar: (data: any) => {
                                return (
                                    <Button className="button-add" variant='contained' onClick={handleCreate}>Create Project</Button>
                                )
                            },
                            search: false,
                            selectableRows: "none",
                            rowsPerPageOptions: [50, 100, 200],
                            rowsPerPage: 50,
                            onChangePage: (currentPage: number) => { setPage(currentPage) },
                            onChangeRowsPerPage: (numberOfRows: number) => { setRowsPerPage(numberOfRows) },
                            count: data?.total,
                            downloadOptions: {
                                filename: "utility fielding - project.csv"
                            },
                            print: false
                        }}
                    />
                </div>
            </Container>
        </>
    )
    return <Template component={component} />
}

export default FieldingRequest;