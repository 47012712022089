import { Button, TextField } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { Save, Trash, X } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import Roles from "../../../../shared/Roles";
import { setAerialFielding } from "../../../assignFielding/assignFielding.reducer";
import { setCenter, setZoom } from "../../../dashboard/dashboard.reducer";
import { postLogin } from "../../../login/login.api";
import { setListMidspanFieldingRequest, setListPoleFieldingRequest, setListPoleLoadingRequest } from "../../../poleInformation/poleInformation.reducer";
import { deleteJobNumber, editJobNumber, getJobNumber, getJobNumberDetails, postJobNumber, reactiveJobNumber } from "../../sideTab.api";
import { handleJobNumberIdActive, handleLayerActive, handleFieldingIcon, setJobDetail, setDetailMenu, setFieldingIdList, setNoteList, setLoadingRequestIdList, setJobIdList } from "../../sideTab.reducer";

export default function ModalForm(props: any) {
  const { formTypeJobNumber, projectIdActive, jobNumberIdActive, statusJobNumber } = useSelector((state: RootState) => state.sideTab);

  const dispatch = useDispatch<any>();
  const [values, setValues] = useState<any>({
    name: "",
    projectId: projectIdActive.id,
    dueDateString: "",
    layerStatus: 0,
    description: "",
    city: ""
  });

  useEffect(() => {
    if (formTypeJobNumber === "new") {
      setValues({
        name: "",
        projectId: projectIdActive.id,
        dueDateString: "",
        layerStatus: 0,
        description: "",
        city: ""
      });
    }
    else if (formTypeJobNumber === "reactive") {
      setValues({
        ...values,
        id: jobNumberIdActive.id,
        name: jobNumberIdActive.name,
        projectId: projectIdActive.id,
        layerStatus: jobNumberIdActive.layerStatus,
        description: jobNumberIdActive.description,
        city: jobNumberIdActive.city
      });
    }
    else {
      setValues({
        ...values,
        id: jobNumberIdActive.id,
        name: jobNumberIdActive.name,
        projectId: projectIdActive.id,
        dueDateString: jobNumberIdActive.dueDate?.split('T')[0],
        layerStatus: jobNumberIdActive.layerStatus,
        description: jobNumberIdActive.description,
        city: jobNumberIdActive.city
      });
    }
  }, [])

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const searchCity = async () => {
    const geocoder = new google.maps.Geocoder()
    let response = await geocoder.geocode({ address: values.city })
    console.log(response)
    let lat = response.results[0].geometry.location.lat();
    let lng = response.results[0].geometry.location.lng();
    dispatch(setCenter({ latitude: lat, longitude: lng }))
    dispatch(setZoom(11))
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    searchCity()
    if (formTypeJobNumber === "new") {
      dispatch(postJobNumber({
        ...values,
        dueDateString: moment(new Date(values.dueDateString)).format("MM/DD/yyyy")
      }))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire("Create Job Number Failed!", `${res.data.message || Object.values(res.data.errors)}`, "error");
          } else {
            Swal.fire({
              icon: "success",
              title: "New Job Number Successfully Created",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              dispatch(getJobNumber({
                id: projectIdActive?.id,
                status: statusJobNumber
              }))
              dispatch(getJobNumberDetails(res.data.id));
              dispatch(setJobIdList([res.data.id]))
              dispatch(setJobDetail(res.data));
              dispatch(setDetailMenu({}))
              dispatch(setAerialFielding({}))
              dispatch(setListPoleFieldingRequest([]))
              dispatch(setListMidspanFieldingRequest([]))
              dispatch(setFieldingIdList([]))
              props.onClose();
            });
          }
        });
    }
    else {
      dispatch(editJobNumber({
        ...values,
        dueDateString: moment(new Date(values.dueDateString)).format("MM/DD/yyyy")
      }))
        .unwrap()
        .then((res: any) => {
          if (res.status >= 400) {
            Swal.fire("Edit Job Number Failed!", `${res.data.message || Object.values(res.data.errors)}`, "error");
          } else {
            if (formTypeJobNumber === "reactive") {
              dispatch(reactiveJobNumber(jobNumberIdActive?.id))
                .unwrap()
                .then((res: any) => {
                  if (res.status >= 400) {
                    Swal.fire(
                      'Set Active Failed',
                      'error'
                    )
                  } else {
                    dispatch(getJobNumber({
                      id: projectIdActive?.id,
                      status: statusJobNumber
                    }))
                    dispatch(handleJobNumberIdActive({}))
                    dispatch(handleLayerActive({}))
                    dispatch(setJobDetail(null))
                    props.onClose();
                    Swal.fire({
                      icon: 'success',
                      title: 'Job Number Successfully Reactivate',
                      showConfirmButton: false,
                      timer: 1500
                    })
                      .then(() => {
                        dispatch(setFieldingIdList([]))
                        dispatch(setListPoleFieldingRequest([]))
                        dispatch(setLoadingRequestIdList([]))
                        dispatch(setListPoleLoadingRequest([]))
                        dispatch(handleJobNumberIdActive({}))
                        dispatch(setNoteList([]))
                        dispatch(setAerialFielding({}));
                        dispatch(setJobDetail(null));
                      })
                  }
                })
            }
            else {
              Swal.fire({
                icon: "success",
                title: "Job Number Successfully Edited",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                props.onClose();
                dispatch(getJobNumber({
                  id: projectIdActive?.id,
                  status: statusJobNumber
                }))
                dispatch(handleJobNumberIdActive({}))
                dispatch(handleLayerActive({}))
              });
            }
          }
        });
    }

  };

  const handleDelete = () => {
    Swal.fire({
      title: 'are you sure you want to delete this job number?',
      text: 'Type your password to continue',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
      input: 'password',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return dispatch(postLogin({
          userName: localStorage.getItem('email'),
          password: login
        }))
          .unwrap()
          .then((res: any) => {
            if (res.status >= 400) {
              Swal.showValidationMessage(
                `${res.data || Object.values(res.data.errors)}`
              )
            }
          })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteJobNumber(jobNumberIdActive.id))
          .unwrap()
          .then((res: any) => {
            if (res.status >= 400) {
              Swal.fire("Delete Job Number Failed!", `${res.data.message || Object.values(res.data.errors)}`, "error");
            } else {
              Swal.fire({
                icon: "success",
                title: "Job Number Successfully Deleted",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                dispatch(getJobNumber({
                  id: projectIdActive?.id,
                  status: statusJobNumber
                }))
                dispatch(handleJobNumberIdActive({}));
                dispatch(handleLayerActive({}));
                dispatch(handleFieldingIcon(null));
                props.onClose();
              });
            }
          })
      }
    })
  }

  return (
    <div className="formproject">
      {formTypeJobNumber === "edit"
        ? (
          <h3>
            Edit Job Number and Date <X onClick={props.onClose} />
          </h3>
        )
        : (
          formTypeJobNumber === "reactive"
            ? (
              <h3>
                Reactivate Job Number <X onClick={props.onClose} />
              </h3>
            )
            : (
              <h3>
                Create Job Number <X onClick={props.onClose} />
              </h3>
            )
        )
      }
      <form onSubmit={handleSubmit}>
        <TextField
          className="inp"
          onChange={handleChange}
          name="name"
          value={values.name}
          variant="outlined"
          type="text"
          label="Job Number"
          size="small"
          required
        />
        <TextField
          className="inp"
          name="city"
          variant="outlined"
          type="text"
          label="City & State"
          placeholder="City & State"
          size="small"
          value={values.city}
          onChange={handleChange}
        />
        <TextField
          multiline
          onChange={handleChange}
          rows={5}
          className="inp"
          name="description"
          value={values.description}
          variant="outlined"
          // required
          type="text"
          label="Note"
          size="small"
        />
        <TextField
          className="inp"
          name="dueDateString"
          onChange={handleChange}
          value={values.dueDateString}
          required
          type="date"
          label="Due Date Job"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          size="small"
        />

        <div className="wrapbutton">
          {formTypeJobNumber === "reactive" || formTypeJobNumber !== "edit"
            ? (
              <Button className="cancel" variant="outlined" onClick={props.onClose}>
                <X /> Cancel
              </Button>
            )
            : (Roles("jobnumber_delete") && (
              <Button type="button" className="delete" variant="outlined" onClick={handleDelete}>
                <Trash /> Delete
              </Button>
            ))}
          {formTypeJobNumber === "reactive"
            ? (
              <Button type="submit" className="save" variant="outlined">
                <Save /> Reactivate
              </Button>
            )
            : (
              <Button type="submit" className="save" variant="outlined">
                <Save /> Save
              </Button>
            )}
        </div>
      </form>
    </div>
  );
}