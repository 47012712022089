import { Button, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useState } from "react";
import { Edit2, Save, Trash, X } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import Roles from "../../../../shared/Roles";
import { getFieldingManagers } from "../../../tracker/account/account.api";
import { setAerialFielding } from "../../../assignFielding/assignFielding.reducer";
import { deleteProject, editProject, getProject, postProject } from "../../sideTab.api";
import { handleFormType, handleJobNumberIdActive, handleProjectIdActive, setDetailMenu, setFieldingIdList, setJobDetail, setJobIdList, setListJobNumber, setNoteList } from "../../sideTab.reducer";
import { setListPoleFieldingRequest } from "../../../poleInformation/poleInformation.reducer";
import { postLogin } from "../../../login/login.api";

export default function ModalForm(props: any) {
  const dispatch = useDispatch<any>();

  const { listFieldingManagers } = useSelector((state: RootState) => state.account);
  const { listCompany } = useSelector((state: RootState) => state.company);
  const { listAccount } = useSelector((state: RootState) => state.account);
  const { formType, projectIdActive } = useSelector((state: RootState) => state.sideTab);

  const companyIdSelected = localStorage.getItem("companyIdSelected");
  const [values, setValues] = useState<any>({
    projectNumber: "",
    companyId: companyIdSelected,
    description: "",
    fieldingManagers: [],
    companyShares: [],
    emailShares: [],
    projectManagers: [],
    projectTeams: [],
    pid: ""
  });
  const [emailInputText, setEmailInputText] = useState("")

  useEffect(() => {
    dispatch(getFieldingManagers(companyIdSelected));
  }, []);

  useEffect(() => {
    if (formType === "info") {
      let fieldingManagersNew: any = []
      let assignedManagerIds = projectIdActive?.assignedManagerIds?.split(', ')
      let assignedManagerNames = projectIdActive?.assignedManagerNames?.split(', ')
      let companiesNew: any = []
      let sharedCompanyIds = projectIdActive?.sharedCompanyIds?.length > 0 ? projectIdActive?.sharedCompanyIds?.split(', ') : []
      let sharedCompanyNames = projectIdActive?.sharedCompanyNames?.length > 0 ? projectIdActive?.sharedCompanyNames?.split(', ') : []
      let emailsNew = projectIdActive?.sharedEmails?.length > 0 ? projectIdActive?.sharedEmails?.split(', ') : []
      let assignedProjectManagerIdsNew = projectIdActive?.assignedProjectManagerIds?.length > 0 ? projectIdActive?.assignedProjectManagerIds?.split(', ') : []
      let assignedProjectTeamIdsNew = projectIdActive?.assignedProjectTeamIds?.length > 0 ? projectIdActive?.assignedProjectTeamIds?.split(', ') : []
      assignedManagerIds?.map((value: string, index: number) => {
        let fieldingManagerNew = {
          id: value,
          value: assignedManagerNames[index]
        }
        fieldingManagersNew.push(fieldingManagerNew)
      })
      sharedCompanyIds?.map((value: string, index: number) => {
        let companyNew = {
          id: value,
          value: sharedCompanyNames[index]
        }
        companiesNew.push(companyNew)
      })
      setValues({
        ...values,
        projectNumber: projectIdActive.projectNumber,
        description: projectIdActive.description,
        pid: projectIdActive.pid,
        fieldingManagers: fieldingManagersNew,
        companyShares: companiesNew ? companiesNew : [],
        emailShares: emailsNew ? emailsNew : [],
        projectManagers: assignedProjectManagerIdsNew ? (listAccount.filter((d: any) => { return assignedProjectManagerIdsNew.includes(d.id) })) : [],
        projectTeams: assignedProjectTeamIdsNew ? (listAccount.filter((d: any) => { return assignedProjectTeamIdsNew.includes(d.id) })) : []
      });
    }
  }, [projectIdActive])

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeFieldingManagers = (e: any, value: any) => {
    setValues({
      ...values,
      fieldingManagers: value,
    });
  };

  const handleChangeProjctManager = (e: any, value: any) => {
    setValues({
      ...values,
      projectManagers: value,
    });
  };

  const handleChangeProjctTeam = (e: any, value: any) => {
    setValues({
      ...values,
      projectTeams: value,
    });
  };

  const handleChangeCompanies = (e: any, value: any) => {
    setValues({
      ...values,
      companyShares: value,
    });
  };

  const handleChangeEmails = (e: any, value: any) => {
    setValues({
      ...values,
      emailShares: value,
    });
  };

  const handleCreate = () => {
    dispatch(postProject(values))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Create Project Failed!", `${res.data.message || Object.values(res.data.errors)}`, "error");
        } else {
          Swal.fire({
            icon: "success",
            title: "New Project Successfully Created",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            dispatch(handleProjectIdActive({}))
            dispatch(setAerialFielding({}));
            dispatch(setJobDetail(null))
            dispatch(handleJobNumberIdActive({}));
            dispatch(getProject(localStorage.getItem("companyIdSelected")));
            props.onClose();
          });
        }
      });
  };

  const handleSave = () => {
    let valuesWithId = {
      ...values,
      id: projectIdActive.id
    }
    dispatch(editProject(valuesWithId))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Edit Project Failed!");
        } else {
          Swal.fire({
            icon: "success",
            title: "Project Successfully Edited",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            dispatch(handleProjectIdActive({}))
            dispatch(getProject(localStorage.getItem("companyIdSelected")));
            props.onClose();
          });
        }
      })
  };

  const handleDelete = () => {
    Swal.fire({
      title: 'are you sure you want to delete this project?',
      text: 'Type your password to continue',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
      input: 'password',
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return dispatch(postLogin({
          userName: localStorage.getItem('email'),
          password: login
        }))
          .unwrap()
          .then((res: any) => {
            if (res.status >= 400) {
              Swal.showValidationMessage(
                `${res.data || Object.values(res.data.errors)}`
              )
            }
          })
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProject(projectIdActive.id))
          .unwrap()
          .then(async (res: any) => {
            if (res.status >= 400) {
              Swal.fire("Delete Project Failed!", `${res.data.message || Object.values(res.data.errors)}`, "error");
            } else {
              Swal.fire({
                icon: "success",
                title: "Project Successfully Deleted",
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                dispatch(getProject(localStorage.getItem("companyIdSelected")));
                dispatch(handleProjectIdActive({ id: "" }))
                dispatch(setJobDetail(null));
                dispatch(setDetailMenu({}))
                dispatch(setAerialFielding({}))
                dispatch(setListPoleFieldingRequest([]))
                dispatch(setJobIdList([]))
                dispatch(setFieldingIdList([]))
                dispatch(setNoteList([]))
                dispatch(setListJobNumber([]))
                props.onClose();
              });
            }
          })
      }
    })
  }

  return (
    <div className="formproject">
      <h3>
        {formType === "info" ? "Info" : formType === "edit" ? "Edit" : "Create"} Project
        <X onClick={props.onClose} />
      </h3>
      <form>
        <TextField
          onChange={handleChange}
          value={values.projectNumber}
          className="inp"
          name="projectNumber"
          variant={formType === "info" ? "filled" : "outlined"}
          type="text"
          label="Project Name"
          size="small"
          required
          inputProps={{
            readOnly: formType === "info",
          }}
        />
        <TextField
          onChange={handleChange}
          value={values.pid}
          className="inp"
          name="pid"
          variant={formType === "info" ? "filled" : "outlined"}
          type="text"
          label="PID"
          size="small"
          required
          inputProps={{
            readOnly: formType === "info",
          }}
        />
        <TextField
          onChange={handleChange}
          value={values.description}
          multiline
          rows={5}
          className="inp"
          name="description"
          variant={formType === "info" ? "filled" : "outlined"}
          type="text"
          label="Note"
          size="small"
          inputProps={{
            readOnly: formType === "info",
          }}
        />
        {Roles("project_form_asign_manager") && (
          <Autocomplete
            multiple
            options={listFieldingManagers}
            getOptionLabel={(option) => option.value}
            id="disable-clearable"
            value={values.fieldingManagers}
            onChange={handleChangeFieldingManagers}
            className="inp"
            disableClearable
            disabled={formType === "info"}
            renderInput={(params) => <TextField {...params} required={values.fieldingManagers.length === 0} label="Fielding Manager" size="small" placeholder="Search Fielding Manager" variant={formType === "info" ? "filled" : "outlined"} />}
          />
        )}
        {(!projectIdActive.isSharedProject && (formType === "edit" || formType === "info"))
          ? (
            <>
              {Roles("project_form_share_email") && (
                <Autocomplete
                  multiple
                  options={listCompany.map((company: any) => { return { id: company?.id, value: company?.name } })}
                  getOptionLabel={(option) => option.value}
                  id="disable-clearable"
                  value={values.companyShares}
                  onChange={handleChangeCompanies}
                  className="inp"
                  disableClearable
                  disabled={formType === "info"}
                  renderInput={(params) => <TextField {...params} required={values.fieldingManagers.length === 0} label="Share with Sub-Contractor" size="small" placeholder="Search Company" variant={formType === "info" ? "filled" : "outlined"} />}
                />
              )}
              <Autocomplete
                multiple
                options={emailInputText.length >= 4 ? [emailInputText] : [""]}
                id="disable-clearable"
                value={values.emailShares}
                onChange={handleChangeEmails}
                className="inp"
                disableClearable
                disabled={formType === "info"}
                onInputChange={(e: any) => setEmailInputText(e.target?.value)}
                renderInput={(params) => <TextField {...params} required={values.fieldingManagers.length === 0} label="Shared Email" size="small" placeholder="Search Email" variant={formType === "info" ? "filled" : "outlined"} />}
              />
            </>
          )
          : null
        }
        <Autocomplete
          multiple
          options={listAccount.filter((d: any) => { return d.roles.includes("ProjectManager") && d.companyId === localStorage.getItem("companyIdSelected") })}
          getOptionLabel={(option) => option.email}
          id="disable-clearable"
          value={values.projectManagers}
          onChange={handleChangeProjctManager}
          className="inp"
          disableClearable
          disabled={formType === "info"}
          renderInput={(params) => <TextField {...params} required={values.fieldingManagers.length === 0} label="Project Manager" size="small" placeholder="Search Project Manager" variant={formType === "info" ? "filled" : "outlined"} />}
        />
        <Autocomplete
          multiple
          options={listAccount.filter((d: any) => { return d.roles.includes("ProjectTeam") && d.companyId === localStorage.getItem("companyIdSelected") })}
          getOptionLabel={(option) => option.email}
          id="disable-clearable"
          value={values.projectTeams}
          onChange={handleChangeProjctTeam}
          className="inp"
          disableClearable
          disabled={formType === "info"}
          renderInput={(params) => <TextField {...params} required={values.fieldingManagers.length === 0} label="Project Team" size="small" placeholder="Search Project Team" variant={formType === "info" ? "filled" : "outlined"} />}
        />
        {formType === "info" ? (
          <div className="wrapbutton">
            {Roles("project_delete") && (
              <Button type="button" className="delete" variant="outlined" onClick={handleDelete}>
                <Trash /> Delete
              </Button>
            )}
            <Button type="button" className="edit" variant="outlined" onClick={() => dispatch(handleFormType("edit"))}>
              <Edit2 /> Edit
            </Button>
          </div>
        ) : formType === "edit" ? (
          <div className="wrapbutton">
            <Button type="button" className="cancel" variant="outlined" onClick={() => dispatch(handleFormType("info"))}>
              <X /> Cancel
            </Button>
            <Button variant="outlined" className="save" onClick={handleSave}>
              <Save /> Save
            </Button>
          </div>
        ) : (
          <div className="wrapbutton">
            <Button type="button" className="cancel" variant="outlined" onClick={props.onClose}>
              <X /> Cancel
            </Button>
            <Button variant="outlined" className="save" onClick={handleCreate}>
              <Save /> Save
            </Button>
          </div>
        )}
      </form>
    </div>
  );
}
