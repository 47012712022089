import { Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { RootState } from "../../../../app/store";
import { deleteInventory, editInventory, getInventorytByJobNumberId, postInventory } from "../../sideTab.api";
import { handleInventoryActive, handleInventoryFormModal, handleLayerActive } from "../../sideTab.reducer";
import { Save, Trash, X } from "react-feather";
import { setListPoleInventory } from "../../../poleInformation/poleInformation.reducer";

export default function InventoryForm(props: any) {
  const dispatch = useDispatch<any>();

  const { projectIdActive, jobNumberIdActive, inventoryFormModal, inventoryActive } = useSelector((state: RootState) => state.sideTab);
  const { listPoleInventory } = useSelector((state: RootState) => state.poleInformation);

  const [values, setValues] = useState<any>({
    jobNumberId: "",
    name: "",
    description: "",
    userId: ""
  });

  useEffect(() => {
    if (inventoryFormModal.formType === "") {
      setValues({
        jobNumberId: jobNumberIdActive.id,
        name: "",
        description: "",
        userId: localStorage.getItem("id")
      });
    }
    else {
      setValues({
        ...inventoryActive,
        userId: localStorage.getItem("id")
      });
    }
  }, [inventoryActive, jobNumberIdActive])

  const handleClose = () => {
    dispatch(handleInventoryFormModal({ isOpen: false, formType: "" }));
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(postInventory(values))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Create Pole Inventory Failed!");
        }
        else {
          dispatch(getInventorytByJobNumberId(jobNumberIdActive.id));
          handleClose()
          Swal.fire({
            icon: "success",
            title: "New Pole Inventory Successfully Created"
          })
        }
      });
  };

  const handleEdit = (e: any) => {
    e.preventDefault();
    dispatch(editInventory(values))
      .unwrap()
      .then((res: any) => {
        console.log(res, "saya resssssss");
        if (res.status >= 400) {
          Swal.fire("Edit Pole Inventory Failed!");
        }
        else {
          Swal.fire({
            icon: "success",
            title: "Pole Inventory Successfully Edited",
            showConfirmButton: false,
            timer: 1500,
          }).then((res: any) => {
            handleClose();
            dispatch(getInventorytByJobNumberId(jobNumberIdActive.id));
            dispatch(handleInventoryActive({}))
            dispatch(handleLayerActive({}))
          });
        }
      });
  };

  const handleDelete = () => {
    dispatch(deleteInventory(inventoryActive.id))
      .unwrap()
      .then((res: any) => {
        if (res.status >= 400) {
          Swal.fire("Delete Pole Inventory failed!");
        } else {
          Swal.fire({
            icon: "success",
            title: "Pole Inventory Successfully Deleted",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            dispatch(getInventorytByJobNumberId(jobNumberIdActive.id));
            dispatch(handleLayerActive({}));
            handleClose()
            clearPoleOnId(inventoryActive.id)
          });
        }
      })
  }

  const clearPoleOnId = (id: any) => {
    dispatch(setListPoleInventory(listPoleInventory?.filter((item: any) => item?.inventoryId !== id)));
  }

  return (
    <div className="formproject">
      {inventoryFormModal.formType === "edit" ? (
        <>
          <h3>Edit Pole Inventory <X onClick={handleClose} /></h3>
          <form onSubmit={handleEdit}>
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Project Name"
              placeholder="Project Name"
              size="small"
              value={projectIdActive.projectNumber}
              disabled
            />
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Job Number"
              value={jobNumberIdActive.name}
              size="small"
              disabled
            />
            <TextField
              className="inp"
              variant="outlined"
              label="Name"
              placeholder="Name"
              size="small"
              name="name"
              value={values.name}
              onChange={handleChange}
            />
            <TextField
              multiline
              rows={5}
              className="inp"
              variant="outlined"
              type="text"
              label="Note"
              placeholder="Note"
              size="small"
              name="description"
              value={values.description}
              onChange={handleChange}
            />
            <div className="wrapbutton">
              <Button type="button" className="delete" variant="outlined" onClick={handleDelete}>
                <Trash /> Delete
              </Button>
              <Button type="submit" className="save" variant="outlined">
                <Save /> Save
              </Button>
            </div>
          </form>
        </>
      ) : (
        <>
          <h3>Create New Pole Inventory</h3>
          <form onSubmit={handleSubmit}>
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Project Name"
              placeholder="Project Name"
              size="small"
              value={projectIdActive.projectNumber}
              disabled
            />
            <TextField
              className="inp"
              name="name"
              variant="outlined"
              type="text"
              label="Job Number"
              value={jobNumberIdActive.name}
              size="small"
              disabled
            />
            <TextField
              multiline
              rows={5}
              className="inp"
              variant="outlined"
              type="text"
              label="Note"
              placeholder="Note"
              size="small"
              name="description"
              value={values.description}
              onChange={handleChange}
            />
            <div className="wrapbutton">
              <Button type="button" className="cancel" variant="outlined" onClick={handleClose}>
                <X /> Cancel
              </Button>
              <Button type="submit" className="save" variant="outlined">
                <Save /> Save
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}
